import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const ServiceActionText = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    if (defaultData === null /*JSON.stringify(props.value) !== JSON.stringify(defaultData)*/) {
      setDefaultData(props.value);
      setData(props.value);

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded) return;

    if (JSON.stringify(data) !== JSON.stringify(props.value)) {
      props.onChange({
        target: {
          type: 'component',
          data: data,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <TextField inputProps={{id: '', autoComplete: 'new-password'}} // JUST FOR TYPE === 'Text'
      disabled={props.disabled}
      required={props.isRequired}
      error={props.isRequired && data.value === ''}
      label={props.title}
      placeholder={props.placeholder}
      value={data.value}
      onChange={evt => setData(prevState => ({
        ...prevState,
        value: evt.target.value,
      }))}
    />
  );
};

export default ServiceActionText;