import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import DoctorContract from './DoctorContract';
// import DisableDoctorContract from './DisableDoctorContract';
// import EnableDoctorContract from './EnableDoctorContract';
import DoctorContractPrograms from './DoctorContractPrograms';

const defaultFilter = {
  idDoctor: '',
  idBranch: '',
  text: '',
};
const defaultDeps = {
  doctors: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const DoctorContracts = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getDoctorContracts', {
      idDoctor: params.idDoctor,
    })
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter());
          setData(response.result.data.doctorContracts);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = () => ({
    ...defaultFilter,
    idDoctor: params.idDoctor,
  });
  const getDoctors = () => deps.doctors.sort(firstBy('doctor'));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.doctorContracts);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.doctorContracts.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField select
          disabled={ac.isLoading}
          label='Medic'
          placeholder='Medic'
          name='idDoctor'
          value={filter.idDoctor}
        >
          {getDoctors().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/DoctorContracts/${item.id}`} className='link' value={item.id}>
              <Typography variant='body1'>{item.doctor}</Typography>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'           , path: '/'             , icon: 'Home'              },
          {text: 'Administrare'    , path: '/Admin'        , icon: 'Settings'          },
          {text: 'Medici'          , path: '/Admin/Doctors', icon: 'MedicalInformation'},
          {text: 'Contracte medici', path: null            , icon: 'Approval'          },
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['contract']}
        groupBy='branch'
        renderEntity={{
          avatar: null,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' color={entity.isEnabled ? 'textPrimary' : 'rgba(0, 0, 0, 0.26)'} sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.contract}</Highlighted>
          ),
          textSecondary: [
            entity => <Highlighted highlight={filter.text}>{entity.branch} - {entity.specialty}</Highlighted>,
            entity => <Highlighted highlight={filter.text}>Valid in perioada {new Date(entity.validFrom).format()} - {new Date(entity.validTo).format()}</Highlighted>,
          ],
          textIcons: [
            // entity => ({text: 'Acest punct de lucru este activ'              , icon: 'CheckCircle'  , color: 'primary' , when: entity.isEnabled }),
            // entity => ({text: 'Acest punct de lucru nu este activ'           , icon: 'Cancel'       , color: 'error'   , when: !entity.isEnabled}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'        , icon: null, action: 'editEntity'                                                                             , when: true                       }),
          entity => ({text: 'NIY - Dezactiveaza'    , icon: null, action: 'disableEntity'                                                                          , when: selection.entity.isEnabled }),
          entity => ({text: 'NIY - Activeaza'       , icon: null, action: 'enableEntity'                                                                           , when: !selection.entity.isEnabled}),
          entity => ({text: 'Program de lucru', icon: null, action: 'doctorContractPrograms'                                                                 , when: true                       }),
          entity => ({text: 'Preturi servicii', icon: null, action: `/Admin/ServicePrices/DoctorContract/${selection.entity.idDoctor}/${selection.entity.id}`, when: true                       }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: {
            ...defs.doctorContract,
            idDoctor: params.idDoctor,
          },
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <DoctorContract
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as disabled
       */}
      {/* {Boolean(selection.entity) && selection.op === 'disableEntity' &&
        <DisableDoctorContract
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      } */}

      {/**
       * Set entity as enabled
       */}
      {/* {Boolean(selection.entity) && selection.op === 'enableEntity' &&
        <EnableDoctorContract
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      } */}

      {/**
       * Manage doctor contract program
       */}
      {Boolean(selection.entity) && selection.op === 'doctorContractPrograms' &&
        <DoctorContractPrograms
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default DoctorContracts;