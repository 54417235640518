import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ro';

import App from './App';

import reportWebVitals from './reportWebVitals';

/**
 * https://mui.com/material-ui/customization/default-theme/#explore
 */
let theme = createTheme({});
theme = createTheme(theme, {
  components: {
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          '&:hover a, &:focus a': {
            color: theme.palette.primary.light,
          },
          '&:active a': {
            color: theme.palette.secondary.light,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        color: 'primary',
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: '8px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          flexShrink: 0,
          flexGrow: 0,
          marginTop: '8px',
          marginBottom: '8px',
          paddingTop: '0px',
          paddingBottom: '0px',
          overflow: 'hidden',
        },
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        component: 'div',
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '32px',
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        secondaryTypographyProps: {
          component: 'div',
        },
      },
    },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       '& .link': {
    //         color: 'LinkText',
    //       },
    //     },
    //   },
    // },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        color: 'secondary',
        fullWidth: true,
        size: 'small',
        variant: 'filled',
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2',
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
        enterTouchDelay: 0,
        // followCursor: true,
      },
      styleOverrides: {
        tooltip: {
          whiteSpace: 'pre-wrap',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ro'>
        <App
          localStorageKey='roIsapiMedical'
          url={{
            api: 'https://api.medical.isapi.ro/ajax.php',
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();