import React, { useState, useContext, useEffect, useCallback } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const ServicePrice = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    setDefaultData(props.value);
    setData(props.value);

    ac.ajax('getServices', {
      idBeneficiaryContract: props.value.idBeneficiaryContract,
      idDoctorContract: props.value.idDoctorContract,
    })
      .then(response => {
        if (response.status.ok) {
          setDeps(response.result.dependencies);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.idSpecialty === '' ? 'Nu ati selectat specialitatea' : message;
    message = message !== null ? message : data.idService === '' ? 'Nu ati selectat serviciul' : message;
    message = message !== null ? message : data.idBranch === '' ? 'Nu ati selectat punctul de lucru' : message;
    message = message !== null ? message : data.price === '' ? 'Nu ati completat pretul' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getSpecialties = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);
  const getServices = useCallback(() => {
    return deps.servicesWithoutPrice.filter(service => service.idSpecialty === data.idSpecialty).sort(firstBy('service'));
  }, [data, deps]);
  const getBranches = useCallback(() => {
    return deps.branches.sort(firstBy('branch'));
  }, [deps]);

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      default:
        value = evt.target.value;
    }

    setData(prevState => ({
      ...prevState,
      [evt.target.name]: value,
      ...mods,
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveServicePrice', {
      ...data,
      mode: props.value.mode,
    })
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='sm' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Pret serviciu: Adaugare</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                value={data.id}
              />
            </Grid>
            <Grid item xs={5}>
              {props.value.mode === 'BeneficiaryContract' &&
                <TextField
                  label='Beneficiar'
                  value={deps.beneficiaryContract.beneficiary}
                />
              }
              {props.value.mode === 'DoctorContract' &&
                <TextField
                  label='Medic'
                  value={deps.doctorContract.doctor}
                />
              }
            </Grid>
            <Grid item xs={5}>
              <TextField
                label='Contract'
                value={deps.beneficiaryContract?.contract || deps.doctorContract?.contract}
              />
            </Grid>
            {props.value.mode === 'BeneficiaryContract' &&
              <Grid item xs={6}>
                <TextField select
                  disabled={ac.isLoading}
                  error={!Boolean(data.idSpecialty)}
                  label='Specialitate'
                  placeholder='Specialitate'
                  name='idSpecialty'
                  value={data.idSpecialty}
                  onChange={handleDataChange()}
                >
                  {getSpecialties().map(item => (
                    <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.specialty}</Typography></MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                error={!Boolean(data.idService)}
                label='Serviciu'
                placeholder='Serviciu'
                name='idService'
                value={data.idService}
                onChange={handleDataChange()}
              >
                {getServices().map(item => (
                  <MenuItem key={item.id} value={item.id} disabled={!item.isEnabled}><Typography variant='body1'>{item.service}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            {props.value.mode === 'BeneficiaryContract' &&
              <Grid item xs={6}>
                <TextField select
                  disabled={ac.isLoading}
                  error={!Boolean(data.idBranch)}
                  label='Punct de lucru'
                  placeholder='Punct de lucru'
                  name='idBranch'
                  value={data.idBranch}
                  onChange={handleDataChange()}
                >
                  {getBranches().map(item => (
                    <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.branch}</Typography></MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item xs={12} sm={3}>
              {/* <TextField required
                inputProps={{
                  style: {textAlign: 'right'},
                }}
                // InputProps={{
                //   endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                // }}
                disabled={ac.isLoading}
                label='Cost (RON)'
                placeholder='Cost (RON)'
                name='charge'
                value={data.charge}
                onChange={handleDataChange()}
              /> */}
              <TextField select
                disabled={ac.isLoading}
                label='Durata (min)'
                placeholder='Durata (min)'
                name='duration'
                value={data.duration}
                onChange={handleDataChange()}
              >
                {['0', '5', '10', '15', '20', '25', '30', '40', '50', '60'].map(duration => (
                  <MenuItem key={duration} value={duration}>{duration} min</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField /*variant='outlined'*/ required
                inputProps={{
                  style: {textAlign: 'right'},
                }}
                // InputProps={{
                //   endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                // }}
                disabled={ac.isLoading}
                error={!Boolean(data.price)}
                label='Pret (RON)'
                placeholder='Pret (RON)'
                name='price'
                value={data.price}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServicePrice;