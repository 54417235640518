import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Country from './Country';
import County from './County';
import City from './City';

const defaultData = {
  idCountry: '',
  idCounty: '',
  idCity: '',
};
const defaultDeps = {
  countries: [],
  counties: [],
  cities: [],
};
const defaultOperation = {
  op: null,
  data: null,
};

const CountryCountyCity = props => {
  /**
   * Constants
   */
  const filter = createFilterOptions();

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(defaultData);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [isLoadingCounties, setIsLoadingCounties] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [operation, setOperation] = useState(defaultOperation);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCountriesCountiesCities', props.value)
      .then(response => {
        if (response.status.ok) {
          if (JSON.stringify(props.value) === JSON.stringify(defaultData)) {
            setData({
              idCountry: response.result.dependencies.countries.find(item => item.isDefault)?.id ?? '',
              idCounty: response.result.dependencies.counties.find(item => item.isDefault)?.id ?? '',
              idCity: response.result.dependencies.cities.find(item => item.isDefault)?.id ?? '',
            });
          } else {
            setData(props.value);
          }
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    if (JSON.stringify(data) !== JSON.stringify(props.value)) {
      props.onChange({
        target: {
          type: 'component',
          data: data,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Getters
   */
  const getCountries = () => deps.countries.sort(firstBy('country'));
  const getCounties = () => deps.counties.sort(firstBy('county'));
  const getCities = () => deps.cities.sort(firstBy('city'));

  /**
   * Handlers
   */
  const handleDataChange = (newValue, name, op = null) => {
    console.group(`CountryCountyCity.handleDataChange(${typeof newValue} ${name}) with value`, newValue);
    // console.log(`${name} changed:`, typeof newValue, newValue);

    let mods = {};
    switch (name) {
      case 'idCountry':
        // every time the idCountry changes, we clear data.idCounty and data.idCity
        mods.idCounty = '';
        mods.idCity = '';
        // every time the idCountry changes, we empty deps.counties and deps.cities
        setDeps(prevState => ({
          ...prevState,
          counties: [],
          cities: [],
        }));
        // handle the event
        if (newValue === null) { /** Clicked on X (clear value) */
          console.log(`idCountry was cleared, so we clear data.idCountry`);
          mods.idCountry = '';
        } else if (newValue.id === '0') { /** Deschide fereastra de adaugare a tarii cu ID-ul `` si numele `newValue.country` */
          console.log(`idCountry is '0', so we open a dialog to define a new country named '${newValue.country}'`);
          setOperation(prevState => ({
            op: 'editCountry',
            data: {
              ...defs.country,
              country: newValue.country,
            },
          }));
        } else { /** S-a ales din lista o tara existenta */
          console.log(`idCountry is '${newValue.id}' and its name is '${newValue.country}', so we have to get it's counties from server`);
          setIsLoadingCounties(prevState => true);
          ac.ajax('getCountiesSilent', {
            idCountry: newValue.id,
          })
            .then(response => {
              setData(prevState => ({
                ...prevState,
                idCounty: response.result.dependencies.counties.find(item => item.isDefault)?.id ?? '',
                idCity: response.result.dependencies.cities.find(item => item.isDefault)?.id ?? '',
              }));

              setDeps(prevState => ({
                ...prevState,
                ...response.result.dependencies,
              }));

              setIsLoadingCounties(prevState => false);
            });
        }
        break;
      case 'idCounty':
        // every time the idCounty changes, we clear data.idCity
        mods.idCity = '';
        // every time the idCounty changes, we empty deps.cities
        setDeps(prevState => ({
          ...prevState,
          cities: [],
        }));
        // handle the event
        if (newValue === null) { /** Clicked on X (clear value) */
          console.log(`idCounty was cleared, so we clear data.idCounty`);
          mods.idCounty = '';
        } else if (newValue.id === '0') { /** Deschide fereastra de adaugare a judetului cu ID-ul `` si numele `newValue.county` */
          console.log(`idCounty is '0', so we open a dialog to define a new county named '${newValue.county}'`);
          setOperation(prevState => ({
            op: 'editCounty',
            data: {
              ...defs.county,
              idCountry: data.idCountry,
              county: newValue.county,
            },
          }));
        } else { /** S-a ales din lista un judet existent */
          console.log(`idCounty is '${newValue.id}' and its name is '${newValue.county}', so we have to get it's cities from server`);
          setIsLoadingCities(prevState => true);
          ac.ajax('getCitiesSilent', {
            idCounty: newValue.id,
          })
            .then(response => {
              setData(prevState => ({
                ...prevState,
                idCity: response.result.dependencies.cities.find(item => item.isDefault)?.id ?? '',
              }));

              setDeps(prevState => ({
                ...prevState,
                ...response.result.dependencies,
              }));

              setIsLoadingCities(prevState => false);
            });
        }
        break;
      case 'idCity':
        // handle the event
        if (newValue === null) { /** Clicked on X (clear value) */
          console.log(`idCity was cleared, so we clear data.idCity`);
          mods.idCity = '';
        } else if (newValue.id === '0') { /** Deschide fereastra de adaugare a localitatii cu ID-ul `` si numele `newValue.city` */
          console.log(`idCity is '0', so we open a dialog to define a new city named '${newValue.city}'`);
          setOperation(prevState => ({
            op: 'editCity',
            data: {
              ...defs.city,
              idCounty: data.idCounty,
              city: newValue.city,
            },
          }));
        } else { /** S-a ales din lista o localitate existenta */
          console.log(`idCity is '${newValue.id}' and its name is '${newValue.city}', so we have a complete idCountry/idCounty/idCity combination`);
        }
        break;
      default:
    }
    console.groupEnd();

    setData(prevState => ({
      ...prevState,
      [name]: newValue?.id || data[name],
      ...mods,
    }));
  };

  const handleDialogCancel = () => () => {
    setOperation(defaultOperation);
  };
  const handleDialogConfirm = () => response => { /** response.params.op [saveCountry, saveCounty, saveCity] */
    console.log(`${response.params.op} - ${response.result.lastId} - ${data.idCity}`);
    setData(prevState => ({
      ...prevState,
      idCountry: response.params.op === 'saveCountry' ? response.result.lastId : prevState.idCountry,
      idCounty: response.params.op === 'saveCounty' ? response.result.lastId : prevState.idCounty,
      idCity: response.params.op === 'saveCity' ? response.result.lastId : prevState.idCity,
    }));
    setDeps(prevState => ({
      ...prevState,
      ...response.result.dependencies,
    }));
    // setDefs({
    //   ...defs,
    //   ...response.result.defaultValues,
    // });
    setOperation(defaultOperation);
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={props.layout.country.xs} sm={props.layout.country.sm}>
          <Autocomplete selectOnFocus clearOnBlur handleHomeEndKeys
            renderInput={(params) => {
              return (
                <TextField {...params} inputProps={{...params.inputProps, id: '', autoComplete: 'new-password'}}
                  label={props.labels.country}
                  placeholder={props.labels.country}
                />
              );
            }}
            renderOption={(props, option) => <MenuItem key={option.id} {...props}>{option.id === '0' ? `Adauga "${option.country}"` : option.country}</MenuItem>}
            getOptionLabel={(option) => {
              // console.log(`option:`, option);
              if (typeof option === 'string') { // e.g. value selected with enter, right from the input
                return option;
              }
              // if (option.inputValue) { return option.inputValue; }
              return option.id === '0' ? `Adauga "${option.country}"` : option.country;
            }}
            options={getCountries()}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (props.allowAdd.country && params.inputValue !== '' && !~getCountries().findIndex(item => item.country === params.inputValue)) {
                filtered.push({
                  id: '0',
                  country: params.inputValue,
                });
              }

              return filtered;
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(`isOptionEqualToValue:`, option, `"${value}"`);
              return value === '' || option.country.toLowerCase() === value.toLowerCase();
            }}
            disabled={ac.isLoading || props.disabled.country}
            name={props.names.country}
            value={getCountries().find(item => item.id === data.idCountry)?.country || ''}
            onChange={(evt, newValue) => handleDataChange(newValue, 'idCountry', 'addCountry')}
          />
        </Grid>
        <Grid item xs={props.layout.county.xs} sm={props.layout.county.sm}>
          <Autocomplete selectOnFocus clearOnBlur handleHomeEndKeys
            renderInput={(params) => {
              return (
                <TextField {...params} inputProps={{...params.inputProps, id: '', autoComplete: 'new-password'}}
                  label={props.labels.county}
                  placeholder={props.labels.county}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingCounties ? <CircularProgress color='inherit' size={20} sx={{mt: -2}} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            renderOption={(props, option) => <MenuItem key={option.id} {...props}>{option.id === '0' ? `Adauga "${option.county}"` : option.county}</MenuItem>}
            getOptionLabel={(option) => {
              // console.log(`option:`, option);
              if (typeof option === 'string') { // e.g. value selected with enter, right from the input
                return option;
              }
              // if (option.inputValue) { return option.inputValue; }
              return option.id === '0' ? `Adauga "${option.county}"` : option.county;
            }}
            options={getCounties()}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (props.allowAdd.county && params.inputValue !== '' && !~getCounties().findIndex(item => item.county === params.inputValue)) {
                filtered.push({
                  id: '0',
                  county: params.inputValue,
                });
              }

              return filtered;
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(`isOptionEqualToValue:`, option, `"${value}"`);
              return value === '' || option.county.toLowerCase() === value.toLowerCase();
            }}
            disabled={props.disabled.county || isLoadingCounties || data.idCountry === ''}
            name={props.names.county}
            value={getCounties().find(item => item.id === data.idCounty)?.county || ''}
            onChange={(evt, newValue) => handleDataChange(newValue, 'idCounty', 'addCounty')}
          />
        </Grid>
        <Grid item xs={props.layout.city.xs} sm={props.layout.city.sm}>
          <Autocomplete selectOnFocus clearOnBlur handleHomeEndKeys
            renderInput={(params) => {
              return (
                <TextField {...params} inputProps={{...params.inputProps, id: '', autoComplete: 'new-password'}}
                  label={props.labels.city}
                  placeholder={props.labels.city}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingCities ? <CircularProgress color='inherit' size={20} sx={{mt: -2}} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              );
            }}
            renderOption={(props, option) => <MenuItem key={option.id} {...props}>{option.id === '0' ? `Adauga "${option.city}"` : option.city}</MenuItem>}
            getOptionLabel={(option) => {
              // console.log(`option:`, option);
              if (typeof option === 'string') { // e.g. value selected with enter, right from the input
                return option;
              }
              // if (option.inputValue) { return option.inputValue; }
              return option.id === '0' ? `Adauga "${option.city}"` : option.city;
            }}
            options={getCities()}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (props.allowAdd.city && params.inputValue !== '' && !~getCities().findIndex(item => item.city === params.inputValue)) {
                filtered.push({
                  id: '0',
                  city: params.inputValue,
                });
              }

              return filtered;
            }}
            isOptionEqualToValue={(option, value) => {
              // console.log(`isOptionEqualToValue:`, option, `"${value}"`);
              return value === '' || option.city.toLowerCase() === value.toLowerCase();
            }}
            disabled={props.disabled.city || isLoadingCities || data.idCounty === ''}
            name={props.names.city}
            value={getCities().find(item => item.id === data.idCity)?.city || ''}
            onChange={(evt, newValue) => handleDataChange(newValue, 'idCity', 'addCity')}
          />
        </Grid>
      </Grid>

      {operation.op === 'editCountry' &&
        <Country
          value={operation.data}
          onCancel={handleDialogCancel()}
          onConfirm={handleDialogConfirm()}
        />
      }

      {operation.op === 'editCounty' &&
        <County
          value={operation.data}
          onCancel={handleDialogCancel()}
          onConfirm={handleDialogConfirm()}
        />
      }

      {operation.op === 'editCity' &&
        <City
          value={operation.data}
          onCancel={handleDialogCancel()}
          onConfirm={handleDialogConfirm()}
        />
      }
    </>
  );
};

export default CountryCountyCity;