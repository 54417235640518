import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function getMinutesFromTime(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

function getTimePercentage(time, programStart, programStop) {
  const startMinutes = getMinutesFromTime(programStart);
  const stopMinutes = getMinutesFromTime(programStop);
  const currentMinutes = getMinutesFromTime(time);
  return ((currentMinutes - startMinutes) / (stopMinutes - startMinutes)) * 100;
}

function getEndTime(scheduleTime, scheduleDuration) {
  const [hours, minutes] = scheduleTime.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes + parseInt(scheduleDuration, 10);
  const endHours = Math.floor(totalMinutes / 60);
  const endMinutes = totalMinutes % 60;
  return `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
}

const getFixedHours = (timeStart, timeStop) => {
  const [startHour, startMinute] = timeStart.split(':').map(Number);
  const [stopHour, stopMinute] = timeStop.split(':').map(Number);

  const hours = [];
  if (startMinute > 0) {
    hours.push(timeStart); // Include start time if not on the hour
  }

  let hour = startHour + (startMinute > 0 ? 1 : 0);
  while (hour < stopHour || (hour === stopHour && stopMinute > 0)) {
    hours.push(`${String(hour).padStart(2, '0')}:00`);
    hour++;
  }

  if (stopMinute > 0 || stopHour === hour) {
    hours.push(timeStop); // Include stop time if not on the hour, or if exactly at a fixed hour
  }

  return hours;
};

const ProgramDaySchedules = props => {
  const fixedHours = getFixedHours(props.program.timeStart, props.program.timeStop);

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        mx: '12px',
        my: '0px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme => theme.palette.success.dark,
        background: theme => theme.palette.success.light,
      }}
    >
      {/* Etichetele pentru orele fixe */}
      <Box
        sx={{
          position: 'absolute',
          top: '-16px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {fixedHours.map((hour, index) => (
          <Typography
            key={index}
            variant='caption'
            sx={{
              position: 'absolute',
              left: `${getTimePercentage(hour, props.program.timeStart, props.program.timeStop)}%`,
              transform: 'translateX(-50%)',
              fontWeight: 'bold',
            }}
          >
            {hour}
          </Typography>
        ))}
      </Box>

      {/* Programările pe timeline */}
      {props.schedules.map((schedule, i) => {
        return (
          <Tooltip
            key={i}
            title={
              <Box>
                <Typography>{`${schedule.scheduleTime} - ${getEndTime(schedule.scheduleTime, schedule.scheduleDuration)}`}</Typography>
                <Typography>{schedule.name}</Typography>
              </Box>
            }
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1px',
                left: `calc(${getTimePercentage(schedule.scheduleTime, props.program.timeStart, props.program.timeStop)}% + 1px)`,
                bottom: '1px',
                width: `calc(${(schedule.scheduleDuration / (getMinutesFromTime(props.program.timeStop) - getMinutesFromTime(props.program.timeStart))) * 100}% - 2px)`,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: theme => theme.palette.success.dark,
                background: theme => `linear-gradient(90deg, rgba(255,255,255,1) 0%, ${theme.palette.info.light} 30%, ${theme.palette.info.light} 70%, rgba(255,255,255,1) 100%)`,
              }}
            />
          </Tooltip>
        );
      })}

      {/* Programarea noua */}
      {props.newSchedule &&
        <Box
          sx={{
            position: 'absolute',
            top: '1px',
            left: `calc(${getTimePercentage(props.newSchedule.scheduleTime, props.program.timeStart, props.program.timeStop)}% + 1px)`,
            bottom: '1px',
            width: `calc(${(props.newSchedule.scheduleDuration / (getMinutesFromTime(props.program.timeStop) - getMinutesFromTime(props.program.timeStart))) * 100}% - 2px)`,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme => theme.palette.success.dark,
            background: theme => `linear-gradient(90deg, rgba(255,255,255,1) 0%, ${theme.palette.secondary.light} 30%, ${theme.palette.secondary.light} 70%, rgba(255,255,255,1) 100%)`,
          }}
        />
      }

      {/* Liniile verticale dashed */}
      {fixedHours.slice(1, -1).map((hour, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: 0,
            left: `${getTimePercentage(hour, props.program.timeStart, props.program.timeStop)}%`,
            bottom: 0,
            width: '1px',
            borderLeft: '1px dashed gray',
            transform: 'translateX(-50%)',
          }}
        />
      ))}
    </Box>
  );
};

ProgramDaySchedules.propTypes = {
  program: PropTypes.shape({
    timeStart: PropTypes.string.isRequired,
    timeStop: PropTypes.string.isRequired,
  }).isRequired,
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scheduleTime: PropTypes.string.isRequired,
      scheduleDuration: PropTypes.string.isRequired,
    })
  ).isRequired,
  newSchedule: PropTypes.shape({
    scheduleDate: PropTypes.string.isRequired,
    scheduleTime: PropTypes.string.isRequired,
    scheduleDuration: PropTypes.string.isRequired,
  }),
};

export default ProgramDaySchedules;