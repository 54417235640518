import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/**
 * Static core components
 */
import AdminTile from './Core/AdminTile';
import Navigation from './Core/Navigation';

const Admin = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Handlers
   */

  /**
   * Effects
   */
  useEffect(() => {
    // ac.ajax('getAdmin')
    //   .then(response => {
    //     console.log(response);
    //     // setDeps(response.result.dependencies);
    //   });
    setIsLoaded(true);
  }, []);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (isLoaded === false) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      {/**
       * Navigation
       */}
      {<Navigation
        paths={[
          {text: 'Acasa'       , path: '/' , icon: 'Home'    },
          {text: 'Administrare', path: null, icon: 'Settings'},
        ]}
      />}

      <Typography variant='subtitle2'>{JSON.stringify(ac.user)}</Typography>

      <Grid container spacing={1} mt={1}>
        <AdminTile path='/Admin/Lists'         icon='Toc'                title='Nomenclatoare'    description='Nomenclatoare adrese, tipuri de fisiere, acte de identitate, tipuri si drivere imprimante, etc.' />
        <AdminTile path='/Admin/Beneficiaries' icon='Store'              title='Beneficiari'      description='Beneficiari, puncte de lucru, program de lucru, contracte, imprimante' />
        <AdminTile path='/Admin/Doctors'       icon='MedicalInformation' title='Medici'           description='Medici, contracte, preturi servicii, program de lucru' />
        <AdminTile path='/Admin/Specialties'   icon='MonitorHeart'       title='Specialitati'     description='Specialitati, servicii si pachete de servicii, documente obligatorii de incarcat' />
        <AdminTile path='/Admin/ServicePrices' icon='AttachMoney'        title='Preturi servicii' description='Preturi servicii si pachete de servicii la liber' />
        <AdminTile path='/Admin/RegisterTypes' icon='AppRegistration'    title='Registre'         description='Registre folosite la numerotarea documentelor' />
        <AdminTile path='/Admin/Forms'         icon='DynamicForm'        title='Formulare'        description='Definire si asociere a formularelor necesare anumitor servicii' />
        <AdminTile path='/Admin/Users'         icon='AccountCircle'      title='Utilizatori'      description='Conturi utilizatori, permisiuni si sesiuni de lucru active' />
        <AdminTile path='/Admin/Logs'          icon='LogoDev'            title='Log-uri'          description='Vedere detaliata a istoricului entitatilor' />
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Countries'         className='link'><Typography variant='body2'>Tari</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Beneficiaries'     className='link'><Typography variant='body2'>Beneficiari</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Branches/1'        className='link'><Typography variant='body2'>Puncte de lucru</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Printers'          className='link'><Typography variant='body2'>Imprimante</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/FileTypes'         className='link'><Typography variant='body2'>Tipuri de fisiere</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Specialties'       className='link'><Typography variant='body2'>Specialitati</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Doctors'           className='link'><Typography variant='body2'>Medici</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Registers'         className='link'><Typography variant='body2'>Registre</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/ServicePacks'      className='link'><Typography variant='body2'>Pachete de servicii</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/ServicePrices'     className='link'><Typography variant='body2'>Preturi servicii</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/IdentityCardTypes' className='link'><Typography variant='body2'>Tipuri acte de identitate</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/Users'             className='link'><Typography variant='body2'>Utilizatori</Typography></Link></Grid> */}
        {/* <Grid item xs={12} sm={6} component={Paper} elevation={5}><Link to='/Admin/UserSessions'      className='link'><Typography variant='body2'>Sesiuni utilizatori</Typography></Link></Grid> */}
      </Grid>
    </Container>
  );
};

export default Admin;