import React, { useState, useContext, useEffect, useCallback } from 'react';
import { firstBy } from 'thenby';
import useDebounce from '../Hooks/useDebounce';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Draggable } from 'react-smooth-dnd';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

/**
 * Static core components
 */
import Description from './Core/Description';
import Entities from '../Views/Entities';
import ServiceAction from './ServiceAction';

const defaultAjaxSearch = {
  text: '',
  idTimeout: null,
  entities: [],
};

const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Service = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  const [ajaxSearch, setAjaxSearch] = useState(defaultAjaxSearch);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const debouncedAjaxSearchServiceText = useDebounce(ajaxSearch.text, 300);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getService', {
      idService: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.service.id)) {
            setDefaultData(response.result.data.service);
            setData(response.result.data.service);
          } else {
            setDefaultData(response.result.defaultValues.service);
            setData({
              ...response.result.data.service,
              idBeneficiary: props.value.idBeneficiary,
              beneficiary: props.value.beneficiary,
              idSpecialty: props.value.idSpecialty,
            });
          }

          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.idSpecialty === '' ? 'Nu ati selectat specialitatea' : message;
    message = message !== null ? message : data.idServiceType === '' ? 'Nu ati selectat tipul serviciului' : message;
    message = message !== null ? message : getServiceCategories().length > 0 && data.idServiceCategory === '' ? 'Nu ati selectat categoria de servicii' : message;
    message = message !== null ? message : data.service === '' ? 'Nu ati completat numele serviciului' : message;
    message = message !== null ? message : data.requirePrintLabels && data.labelsCount === '0' ? 'Nu ati completat numarul de etichete care trebuiesc tiparite' : message;
    message = message !== null ? message : data.isMultiPrice && data.idDatasetMultiPrice === '' ? 'Nu ati selectat setul de date care influenteaza pretul' : message;
    message = message !== null ? message : data.useResultFromDataset && data.idDatasetResult === '' ? 'Nu ati selectat setul de date din care face parte rezultatul' : message;
    message = message !== null ? message : data.isServicePack && data.childServices.length === 0 ? 'Nu ati selectat serviciile componente ale pachetului' : message;
    // message = message !== null ? message : data.isServicePack && data.childServices.length === 1 ? 'Ati selectat doar un serviciu in componenta pachetului' : message;
    message = message !== null ? message : data.childServices.filter(item => item.isServicePack).length > 0 ? 'Nu puteti selecta alte pachete de servicii in componenta unui pachet de servicii' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  useEffect(() => {
    if (!debouncedAjaxSearchServiceText || debouncedAjaxSearchServiceText.length < 3) return;

    ac.ajax('getServicesSilent', {
      service: debouncedAjaxSearchServiceText,
    }).then(response => {
      if (response.status.ok) {
        setAjaxSearch({
          ...ajaxSearch,
          idTimeout: defaultAjaxSearch.idTimeout,
          entities: response.result.data.services,
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAjaxSearchServiceText]);

  /**
   * Getters
   */
  const getSpecialties = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);
  const getServiceTypes = useCallback(() => {
    return deps.serviceTypes.sort(firstBy('specialty'));
  }, [deps]);
  const getServiceCategories = useCallback(() => {
    return deps.serviceCategories.filter(serviceCategory => serviceCategory.idSpecialty === data.idSpecialty).sort(firstBy('serviceCategory')) || [];
  }, [deps]);
  const getRegisterTypes = useCallback(() => {
    return deps.registerTypes.filter(registerType => registerType.idSpecialty === data.idSpecialty).sort(firstBy('registerType'));
  }, [deps]);
  const getDatasetsTree = (datasets, idParent = '') => {
    return datasets
      .filter(dataset => dataset.idParent === idParent)
      .map(parent => ({
        ...parent,
        children: getDatasetsTree(datasets, parent.id),
      }));
  };
  const getChildServices = useCallback(() => {
    return data.childServices.sort(firstBy('position').thenBy('specialty').thenBy('service'));
  }, [data]);
  const getAjaxSearchEntities = () => ajaxSearch.entities.filter(entity => !data.childServices.find(item => item.idService === entity.id)).sort(firstBy('specialty').thenBy('service'));

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'isPurchasable':
        mods.isBookable = false;
        mods.isSchedulable = false;
        mods.isMultiPrice = false;
        mods.idDatasetMultiPrice = '';
        mods.isAutoPrice = false;
        break;
      case 'isMultiPrice':
        mods.idDatasetMultiPrice = '';
        break;
      case 'requirePrintLabels':
        mods.labelsCount = '0';
        break;
      case 'isServicePack':
        mods.isAutoPrice = false;
        mods.childServices = defaultData.childServices;
        break;
      case 'useResultFromDataset':
        mods.idDatasetResult = '';
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };

  const handleGetServicePackServices = idService => {
    ac.ajax('getServicePackServices', {
      idServicePack: idService,
    }).then(response => {
      if (response.status.ok) {
        setData(prevState => ({
          ...prevState,
          childServices: prevState.childServices.filter(item => item.idService !== response.params.idServicePack).concat(
            response.result.data.servicePackServices.reduce((acc, item, index) => {
              if (prevState.childServices.find(item2 => item2.idService === item.idService)) {
                return acc;
              } else {
                return acc.concat({
                  ...item,
                  id: prevState.childServices.min('id') - index - 1,
                  idServicePack: prevState.id,
                  position: prevState.childServices.max('position') + index + 1,
                });
              }
            }, [])
          ),
        }));
      }
    });
  };
  const handleServicePackServiceSelect = () => (event, service, reason) => {
    if (reason === 'selectOption') {
      setData(prevState => ({
        ...prevState,
        childServices: prevState.childServices.concat({
          id: prevState.childServices.min('id') - 1,
          idServicePack: prevState.id,
          idService: service.id,
          isPostponable: false,
          isRemovable: false,
          isAddable: false,
          position: prevState.childServices.max('position') + 1,
          specialty: service.specialty,
          service: service.service,
          isServicePack: service.isServicePack,
          childServicesText: service.childServicesText,
        }),
      }));
      setAjaxSearch(defaultAjaxSearch);

      if (service.isServicePack) {
        handleGetServicePackServices(service.id);
      }
    }
  };
  const handleServicePackServiceDrop = dropResult => {
    if (!dropResult.removedIndex && dropResult.addedIndex === undefined) {
      return;
    }

    const childServices = [...data.childServices];
    const movedItem = childServices.splice(dropResult.removedIndex, 1)[0];
    childServices.splice(dropResult.addedIndex, 0, movedItem);

    childServices.forEach((item, index) => {
      item.position = index + 1;
    });

    setData(prevState => ({
      ...prevState,
      childServices: childServices,
    }));
  };
  const handleServicePackServiceDelete = index => () => {
    setData(prevState => ({
      ...prevState,
      childServices: prevState.childServices.filter((item, i) => i !== index),
    }));
  };

  const handleServiceActionDrop = dropResult => {
    if (!dropResult.removedIndex && dropResult.addedIndex === undefined) {
      return;
    }

    const serviceActions = [...data.serviceActions].sort(firstBy('position'));
    const movedItem = serviceActions.splice(dropResult.removedIndex, 1)[0];
    serviceActions.splice(dropResult.addedIndex, 0, movedItem);

    serviceActions.forEach((item, index) => {
      item.position = index + 1;
    });

    setData(prevState => ({
      ...prevState,
      serviceActions: serviceActions,
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveService', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renders
   */
  const renderDatasets = (nodes, level = 0) => {
    return nodes.map(node => (
      <MenuItem key={node.id} value={node.id}>
        <Typography variant='body1' sx={{paddingLeft: `${24 * level}px`}}>{node.dataset}</Typography>
      </MenuItem>
    )).concat(
      nodes.flatMap(node => renderDatasets(node.children, level + 1))
    );
  };
  const renderServicePackServiceOption = () => (props, option) => {
    if (option) {
      return (
        <ListItem {...props}>
          <ListItemIcon>
            {option.isServicePack &&
              <Tooltip title='Acesta este un pachet de servicii. Daca vreti sa adaugati in componenta pachetului pe care il definiti un pachet de servicii, acesta din urma se va transforma automat in colectia de servicii din care este compus.'>
                <MedicalInformationIcon color='warning' />
              </Tooltip>
            }
          </ListItemIcon>
          <ListItemText
            primary={`[${option.specialty}] ${option.service}`}
            secondary={<Description text={option.description} />}
          />
        </ListItem>
      );
    } else {
      return '';
    }
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xl' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Serviciu: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} sx={{alignItems: 'flex-start'}}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <TextField disabled
                    label='ID'
                    placeholder='ID'
                    name='id'
                    value={data.id}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    label='Beneficiar'
                    value={data.beneficiary}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField select
                    disabled={ac.isLoading}
                    label='Specialitate'
                    placeholder='Specialitate'
                    name='idSpecialty'
                    value={data.idSpecialty}
                    onChange={handleDataChange()}
                  >
                    {getSpecialties().map(item => (
                      <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.specialty}</Typography></MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField select
                    disabled={ac.isLoading || getServiceCategories().length === 0}
                    label='Categorie'
                    placeholder='Categorie'
                    name='idServiceCategory'
                    value={data.idServiceCategory}
                    onChange={handleDataChange()}
                  >
                    {getServiceCategories().map(item => (
                      <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.serviceCategory}</Typography></MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField required autoFocus
                    disabled={ac.isLoading}
                    label='Serviciu'
                    placeholder='Serviciu'
                    name='service'
                    value={data.service}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField select
                    disabled={ac.isLoading}
                    label='Tip serviciu'
                    placeholder='Tip serviciu'
                    name='idServiceType'
                    value={data.idServiceType}
                    onChange={handleDataChange()}
                  >
                    {getServiceTypes().map(item => (
                      <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.serviceType}</Typography></MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField select
                    disabled={ac.isLoading}
                    label='Tip de registru'
                    placeholder='Tip de registru'
                    name='idRegisterType'
                    value={data.idRegisterType}
                    onChange={handleDataChange()}
                  >
                    <MenuItem value=''><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Fara tip de registru</Typography></MenuItem>
                    {getRegisterTypes().map(item => (
                      <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.registerType}</Typography></MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Box component='fieldset'>
                    <legend>
                      <FormControlLabel
                        label='Acest serviciu poate fi vandut/cumparat individual'
                        control={
                          <Checkbox size='small'
                            disabled={ac.isLoading}
                            name='isPurchasable'
                            checked={data.isPurchasable}
                            onChange={handleDataChange()}
                          />
                        }
                      />
                    </legend>
                    <FormControlLabel
                      label='Acest serviciu poate fi adaugat pe lista de asteptare'
                      control={
                        <Checkbox size='small'
                          disabled={ac.isLoading || !data.isPurchasable}
                          name='isBookable'
                          checked={data.isBookable}
                          onChange={handleDataChange()}
                        />
                      }
                    />
                    <FormControlLabel
                      label='Acest serviciu poate fi programat'
                      control={
                        <Checkbox size='small'
                          disabled={ac.isLoading || !data.isPurchasable}
                          name='isSchedulable'
                          checked={data.isSchedulable}
                          onChange={handleDataChange()}
                        />
                      }
                    />
                    <FormControlLabel
                      // label='Acest serviciu este exclusiv pe receptie'
                      label='Se deschide receptie separata pentru acest serviciu (exclusiv)'
                      control={
                        <Checkbox size='small'
                          disabled={ac.isLoading || !data.isPurchasable}
                          name='isExclusive'
                          checked={data.isExclusive}
                          onChange={handleDataChange()}
                        />
                      }
                    />
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          label='Preturi diferite in functie de'
                          control={
                            <Checkbox size='small'
                              disabled={ac.isLoading || !data.isPurchasable}
                              name='isMultiPrice'
                              checked={data.isMultiPrice}
                              onChange={handleDataChange()}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField select
                          disabled={ac.isLoading || !data.isMultiPrice}
                          label='Set de date'
                          placeholder='Set de date'
                          name='idDatasetMultiPrice'
                          value={data.idDatasetMultiPrice}
                          onChange={handleDataChange()}
                        >
                          {renderDatasets(getDatasetsTree(deps.datasets.filter(dataset => dataset.isDetail)))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <FormControlLabel
                    label='Se tiparesc etichete cu codul de bare'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='requirePrintLabels'
                        checked={data.requirePrintLabels}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField required type='number'
                    disabled={ac.isLoading || !data.requirePrintLabels}
                    label='Cate?'
                    placeholder='Cate?'
                    name='labelsCount'
                    value={data.labelsCount}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label='Acest serviciu necesita un medic'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='isDoctorRequired'
                        checked={data.isDoctorRequired}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline rows={3}
                    disabled={ac.isLoading}
                    label='Observatii'
                    placeholder='Observatii'
                    name='description'
                    value={data.description}
                    onChange={handleDataChange()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{alignItems: 'flex-start'}}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControlLabel
                    label='Rezultatele fac parte dintr-un set de date'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='useResultFromDataset'
                        checked={data.useResultFromDataset}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField select
                    disabled={ac.isLoading || !data.useResultFromDataset}
                    label='Set de date'
                    placeholder='Set de date'
                    name='idDatasetResult'
                    value={data.idDatasetResult}
                    onChange={handleDataChange()}
                  >
                    {renderDatasets(getDatasetsTree(deps.datasets).filter(dataset => dataset.isResult))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label='Acesta este un pachet de servicii'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='isServicePack'
                        checked={data.isServicePack}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label='Pretul acestui pachet se calculeaza in mod automat in functie de serviciile selectate din componenta sa'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading || !data.isPurchasable || !data.isServicePack}
                        name='isAutoPrice'
                        checked={data.isAutoPrice}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete freeSolo
                    disabled={ac.isLoading || !data.isServicePack}
                    renderInput={params => (<TextField {...params} label='Caută serviciu...' />)}
                    getOptionLabel={option => option ? `[${option.specialty}] ${option.service}` : ''}
                    renderOption={renderServicePackServiceOption()}
                    noOptionsText='Nu s-a gasit nici un serviciu'
                    options={getAjaxSearchEntities()}
                    filterOptions={x => x}
                    groupBy={option => option.specialty}
                    inputValue={ajaxSearch.text}
                    onInputChange={(event, value) => setAjaxSearch({...ajaxSearch, text: value})}
                    onChange={handleServicePackServiceSelect()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List dense sx={{width: '100%'}}>
                    <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={handleServicePackServiceDrop}>
                      {getChildServices().map((entity, index) => (
                        <Draggable key={entity.id}>
                          <ListItem disableGutters disablePadding divider>
                            <ListItemButton disableGutters disableTouchRipple sx={{cursor: 'default'}}>
                              <ListItemIcon>
                                {entity.isServicePack
                                  ? <Tooltip key={index} title='Acesta este un pachet de servicii. Nu puteti sa creati pachete de servicii care sa contina la randul lor alte pachete de servicii'>
                                      <MedicalInformationIcon fontSize='small' color='warning' />
                                    </Tooltip>
                                  : <ListItemIcon className='drag-handle'>
                                      <SwapVertIcon />
                                    </ListItemIcon>
                                }
                              </ListItemIcon>
                              <ListItemText disableTypography sx={{my: 0, mr: 6}}
                                primary={<Typography sx={{fontWeight: 'bold'}}>{entity.service}</Typography>}
                                secondary={<Description text={entity.description} />}
                              />
                              <ListItemSecondaryAction sx={{display: 'flex', alignItems: 'center'}}>
                                <Tooltip title={entity.isPostponable ? 'Acest serviciu se poate amana' : 'Acest serviciu nu se poate amana'}>
                                  <Box>
                                    <IconButton size='small' color={entity.isPostponable ? 'info' : 'disabled'}
                                      disabled={ac.isLoading || entity.isRemovable || entity.isAddable}
                                      onClick={() => handleDataChange('childServices', index)({target: {type: 'toggle', name: 'isPostponable', value: !entity.isPostponable}})}
                                    >
                                      <PlaylistPlayIcon />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                <Tooltip title={entity.isRemovable ? 'Acest serviciu se poate sterge din componenta pachetului' : 'Acest serviciu nu se poate sterge din componenta pachetului'}>
                                  <Box>
                                    <IconButton size='small' color={entity.isRemovable ? 'info' : 'disabled'}
                                      disabled={ac.isLoading || entity.isPostponable || entity.isAddable}
                                      onClick={() => handleDataChange('childServices', index)({target: {type: 'toggle', name: 'isRemovable', value: !entity.isRemovable}})}
                                    >
                                      <PlaylistRemoveIcon />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                <Tooltip title={entity.isAddable ? 'Acest serviciu se poate adauga in componenta pachetului' : 'Acest serviciu nu se poate adauga in componenta pachetului'}>
                                  <Box>
                                    <IconButton size='small' color={entity.isAddable ? 'warning' : 'disabled'}
                                      disabled={ac.isLoading || entity.isPostponable || entity.isRemovable}
                                      onClick={() => handleDataChange('childServices', index)({target: {type: 'toggle', name: 'isAddable', value: !entity.isAddable}})}
                                    >
                                      <PlaylistAddIcon />
                                    </IconButton>
                                  </Box>
                                </Tooltip>
                                <IconButton size='small' color='error'
                                  onClick={handleServicePackServiceDelete(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItemButton>
                          </ListItem>
                        </Draggable>
                      ))}
                    </Container>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{alignItems: 'stretch'}}>
              <Grid container spacing={1} sx={{alignContent: 'stretch', flexDirection: 'column'}}>
                <Grid item sx={{flexDirection: 'column', alignItems: 'stretch'/*, flex: '50% 1 1'*/, width: '100%'}}>
                  <List dense sx={{flexGrow: 1}}>
                    <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>Actiuni permise pe serviciu (atribute)</Typography>
                      <Box sx={{display: 'flex', gap: 1}}>
                        <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                          disabled={ac.isLoading || data.id === defs.service.id}
                          onClick={handleSelectionChange({entity: {...defs.serviceAction, idService: data.id}, op: 'editServiceAction'})}
                        >
                          Adauga
                        </Button>
                      </Box>
                    </ListSubheader>

                    <Entities data={data.serviceActions} deps={deps} selection={selection}
                      sortBy={['position']}
                      sortable={true}
                      messageNoData='Nici un atribut definit'
                      renderEntity={{
                        icon: entity => <SwapVertIcon className='drag-handle' />,
                        textPrimary: entity => (
                          <Typography variant='body2' color={entity.isEnabled ? 'textPrimary' : 'rgba(0, 0, 0, 0.26)'} sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${entity.allowMultiple ? 'Multiple ' : ''}${entity.serviceActionType} (${entity.width}/12)`}</Typography>
                        ),
                        textSecondary: [
                          entity => <Typography variant='body2'>{entity.serviceAction}</Typography>,
                        ],
                        textIcons: [
                          entity => ({text: 'Acest atribut este activ'                                                 , icon: 'CheckCircle'   , color: 'primary' , when: entity.isEnabled                                                                                        }),
                          entity => ({text: 'Acest atribut nu este activ'                                              , icon: 'Cancel'        , color: 'error'   , when: !entity.isEnabled                                                                                       }),
                          entity => ({text: 'Inaccesibil inaintea adaugarii serviciului lista de asteptare'            , icon: 'Notifications' , color: 'disabled', when: !entity.allowedStates.includes('beforeBooking')                                                         }),
                          entity => ({text: 'Accesibil inaintea adaugarii serviciului lista de asteptare (obligatoriu)', icon: 'Notifications' , color: 'warning' , when: entity.allowedStates.includes('beforeBooking') && entity.requiredStates.includes('beforeBooking')       }),
                          entity => ({text: 'Accesibil inaintea adaugarii serviciului lista de asteptare (optional)'   , icon: 'Notifications' , color: 'info'    , when: entity.allowedStates.includes('beforeBooking') && !entity.requiredStates.includes('beforeBooking')      }),
                          entity => ({text: 'Inaccesibil inaintea programarii serviciului'                             , icon: 'Schedule'      , color: 'disabled', when: !entity.allowedStates.includes('beforeSchedule')                                                        }),
                          entity => ({text: 'Accesibil inaintea programarii serviciului (obligatoriu)'                 , icon: 'Schedule'      , color: 'warning' , when: entity.allowedStates.includes('beforeSchedule') && entity.requiredStates.includes('beforeSchedule')     }),
                          entity => ({text: 'Accesibil inaintea programarii serviciului (optional)'                    , icon: 'Schedule'      , color: 'info'    , when: entity.allowedStates.includes('beforeSchedule') && !entity.requiredStates.includes('beforeSchedule')    }),
                          entity => ({text: 'Inaccesibil inaintea deschiderii serviciului'                             , icon: 'Create'        , color: 'disabled', when: !entity.allowedStates.includes('beforeOpen')                                                            }),
                          entity => ({text: 'Accesibil inaintea deschiderii serviciului (obligatoriu)'                 , icon: 'Create'        , color: 'warning' , when: entity.allowedStates.includes('beforeOpen') && entity.requiredStates.includes('beforeOpen')             }),
                          entity => ({text: 'Accesibil inaintea deschiderii serviciului (optional)'                    , icon: 'Create'        , color: 'info'    , when: entity.allowedStates.includes('beforeOpen') && !entity.requiredStates.includes('beforeOpen')            }),
                          entity => ({text: 'Inaccesibil inaintea trecerea serviciului in strea AMANAT'                , icon: 'HourglassEmpty', color: 'disabled', when: !entity.allowedStates.includes('beforePostpone')                                                        }),
                          entity => ({text: 'Accesibil inaintea trecerea serviciului in strea AMANAT (obligatoriu)'    , icon: 'HourglassEmpty', color: 'warning' , when: entity.allowedStates.includes('beforePostpone') && entity.requiredStates.includes('beforePostpone')     }),
                          entity => ({text: 'Accesibil inaintea trecerea serviciului in strea AMANAT (optional)'       , icon: 'HourglassEmpty', color: 'info'    , when: entity.allowedStates.includes('beforePostpone') && !entity.requiredStates.includes('beforePostpone')    }),
                          entity => ({text: 'Inaccesibil inaintea inchiderii serviciului'                              , icon: 'Done'          , color: 'disabled', when: !entity.allowedStates.includes('beforeClose')                                                           }),
                          entity => ({text: 'Accesibil inaintea inchiderii serviciului (obligatoriu)'                  , icon: 'Done'          , color: 'warning' , when: entity.allowedStates.includes('beforeClose') && entity.requiredStates.includes('beforeClose')           }),
                          entity => ({text: 'Accesibil inaintea inchiderii serviciului (optional)'                     , icon: 'Done'          , color: 'info'    , when: entity.allowedStates.includes('beforeClose') && !entity.requiredStates.includes('beforeClose')          }),
                          entity => ({text: 'Inaccesibil inaintea validarii serviciului'                               , icon: 'DoneAll'       , color: 'disabled', when: !entity.allowedStates.includes('beforeValidation')                                                      }),
                          entity => ({text: 'Accesibil inaintea validarii serviciului (obligatoriu)'                   , icon: 'DoneAll'       , color: 'warning' , when: entity.allowedStates.includes('beforeValidation') && entity.requiredStates.includes('beforeValidation') }),
                          entity => ({text: 'Accesibil inaintea validarii serviciului (optional)'                      , icon: 'DoneAll'       , color: 'info'    , when: entity.allowedStates.includes('beforeValidation') && !entity.requiredStates.includes('beforeValidation')}),
                        ],
                      }}
                      contextualMenu={[
                        entity => ({text: 'Modifica', icon: null, action: 'editServiceAction'  , when: true}),
                        entity => ({text: 'Sterge'  , icon: null, action: 'deleteServiceAction', when: true}),
                      ]}
                      onChangeSelection={setSelection}
                      onSort={handleServiceActionDrop}
                    />
                  </List>
                </Grid>
                <Grid item sx={{flexDirection: 'column', alignItems: 'stretch'/*, flex: '30% 1 1'*/}}>
                  <Typography variant='subtitle2'>Formulare pe medic</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>

      {/**
       * Add/edit serviceAction
       */}
      {Boolean(selection.entity) && selection.op === 'editServiceAction' &&
        <ServiceAction
          value={selection.entity}
          service={data.service}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData(prevState => ({
              ...prevState,
              serviceActions: response.result.data.service.serviceActions,
            }));
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }
    </Dialog>
  );
};

export default Service;