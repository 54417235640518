import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';

const availableStates = [
  {state: 'beforeBooking'   , title: 'booking'   },
  {state: 'beforeSchedule'  , title: 'programare'},
  {state: 'beforeOpen'      , title: 'deschidere'},
  {state: 'beforePostpone'  , title: 'amanare'   },
  {state: 'beforeClose'     , title: 'inchidere' },
  {state: 'beforeValidation', title: 'validare'  },
];

const ServiceAction = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getServiceAction', {
      idServiceAction: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.serviceAction.id)) {
            setDefaultData(response.result.data.serviceAction);
            setData(response.result.data.serviceAction);
          } else {
            setDefaultData(response.result.defaultValues.serviceAction);
            setData({
              ...response.result.data.serviceAction,
              idService: props.value.idService,
            });
          }

          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.serviceAction === '' ? 'Nu ati completat denumirea atributului' : message;
    message = message !== null ? message : data.title === '' ? 'Nu ati completat titlul campului' : message;
    message = message !== null ? message : data.placeholder === '' ? 'Nu ati completat placeholder-ul atributului' : message;
    message = message !== null ? message : ['Checkbox', 'Option'].includes(data.serviceActionType) && data.idDataset === '' ? 'Nu ati selectat setul de date cu valorile atributului' : message;
    message = message !== null ? message : data.serviceActionType === 'File' && data.idFileType === '' ? 'Nu ati selectat tipul de fisier al atributului' : message;
    message = message !== null ? message : data.allowedStates.length === 0 ? 'Nu ati selectat nici o stare in care se poate completa atributul' : message;
    message = message !== null ? message : data.requiredStates.length === 0 ? 'Nu ati selectat nici o stare in care completarea atributului este obligatorie' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getDatasetsTree = (datasets, idParent = '') => {
    return datasets
      .filter(dataset => dataset.idParent === idParent)
      .map(parent => ({
        ...parent,
        children: getDatasetsTree(datasets, parent.id),
      }));
  };

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'serviceActionType':
        mods = {
          idDataset: ['Checkbox', 'Option'].includes(data.serviceActionType) && ['Checkbox', 'Option'].includes(evt.target.value) ? data.idDataset : defs.serviceAction.idDataset,
          idFileType: defs.serviceAction.idFileType,
          allowMultiple: ['Checkbox', 'Option'].includes(data.serviceActionType) && ['Checkbox', 'Option'].includes(evt.target.value) ? data.allowMultiple :defs.serviceAction.allowMultiple,
          disablePast: defs.serviceAction.disablePast,
          disableToday: defs.serviceAction.disableToday,
          disableFuture: defs.serviceAction.disableFuture,
        };
        break;
      default: break;
    }

    switch (evt.target.type) {
      case 'checkbox':
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.checked,
          ...mods,
        }));
        break;
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveServiceAction', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renders
   */
  const renderDatasets = (nodes, level = 0) => {
    return nodes.map(node => (
      <MenuItem key={node.id} value={node.id}>
        <Typography variant='body1' sx={{paddingLeft: `${24 * level}px`}}>{node.dataset}</Typography>
      </MenuItem>
    )).concat(
      nodes.flatMap(node => renderDatasets(node.children, level + 1))
    );
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='md' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Atribut serviciu: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField inputProps={{readOnly: true}}
                label='Serviciu'
                placeholder='Serviciu'
                value={props.service}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Atribut serviciu'
                placeholder='Atribut serviciu'
                name='serviceAction'
                value={data.serviceAction}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField select
                disabled={ac.isLoading}
                label='Tip atribut'
                placeholder='Tip atribut'
                name='serviceActionType'
                value={data.serviceActionType}
                onChange={handleDataChange()}
              >
                <MenuItem value='Text'>Camp text</MenuItem>
                <MenuItem value='Date'>Calendar</MenuItem>
                <MenuItem value='Download'>Descarcare fisier</MenuItem>
                <MenuItem value='Checkbox'>Lista de bife</MenuItem>
                <MenuItem value='Option'>Lista de optiuni</MenuItem>
                <MenuItem value='File'>Selectare fisier</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField select
                disabled={ac.isLoading}
                label='Latime (x din 12)'
                placeholder='Latime (x din 12)'
                name='width'
                value={data.width}
                onChange={handleDataChange()}
              >
                {Array.from({length: 12}, (_, i) => i + 1).map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField required
                disabled={ac.isLoading}
                label='Titlu'
                placeholder='Titlu'
                name='title'
                value={data.title}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField required
                disabled={ac.isLoading}
                label='Placeholder'
                placeholder='Placeholder'
                name='placeholder'
                value={data.placeholder}
                onChange={handleDataChange()}
              />
            </Grid>
            {(data.serviceActionType === 'Checkbox' || data.serviceActionType === 'Option') &&
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                label='Optiuni disponibile'
                placeholder='Optiuni disponibile'
                name='idDataset'
                value={data.idDataset}
                onChange={handleDataChange()}
              >
                {renderDatasets(getDatasetsTree(deps.datasets).filter(dataset => dataset.isDetail))}
              </TextField>
            </Grid>
            }
            {(data.serviceActionType === 'Checkbox' || data.serviceActionType === 'Option') &&
            <Grid item xs={6} sx={{justifyContent: 'space-between'}}>
              <FormControlLabel
                label='Permite selectarea de optiuni multiple'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading}
                    name='allowMultiple'
                    checked={data.allowMultiple}
                    onChange={handleDataChange()}
                  />
                }
              />
            </Grid>
            }
            {data.serviceActionType === 'File' &&
              <Grid item xs={12}>
                <TextField select
                  disabled={ac.isLoading}
                  label='Tip fisier'
                  placeholder='Tip fisier'
                  name='idFileType'
                  value={data.idFileType}
                  onChange={handleDataChange()}
                >
                  {deps.fileTypes.map(fileType => (
                    <MenuItem key={fileType.id} value={fileType.id}>{fileType.fileType}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            {data.serviceActionType === 'Date' &&
              <Grid item xs={4} sx={{justifyContent: 'space-between'}}>
                <FormControlLabel
                  label='Dezactiveaza datele din trecut'
                  control={
                    <Checkbox size='small'
                      disabled={ac.isLoading}
                      name='disablePast'
                      checked={data.disablePast}
                      onChange={handleDataChange()}
                    />
                  }
                />
              </Grid>
            }
            {data.serviceActionType === 'Date' &&
              <Grid item xs={4} sx={{justifyContent: 'space-between'}}>
                <FormControlLabel
                  label='Dezactiveaza data de azi'
                  control={
                    <Checkbox size='small'
                      disabled={ac.isLoading}
                      name='disableToday'
                      checked={data.disableToday}
                      onChange={handleDataChange()}
                    />
                  }
                />
              </Grid>
            }
            {data.serviceActionType === 'Date' &&
              <Grid item xs={4} sx={{justifyContent: 'space-between'}}>
                <FormControlLabel
                  label='Dezactiveaza datele din viitor'
                  control={
                    <Checkbox size='small'
                      disabled={ac.isLoading}
                      name='disableFuture'
                      checked={data.disableFuture}
                      onChange={handleDataChange()}
                    />
                  }
                />
              </Grid>
            }
            <Grid item xs={12}>
              {/* <Typography variant='subtitle2' color='secondary'>Selectati de mai jos in ce stari ale serviciului se poate completa acest atribut si in ce stare este obligatorie completarea acestuia</Typography> */}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} component='fieldset' sx={{ml: 0, pl: .5}}>
                <legend>
                  <Typography variant='subtitle2' color='secondary'>Selectati de mai jos in ce stari ale serviciului se poate completa acest atribut si in ce stare este obligatorie completarea acestuia</Typography>
                </legend>
                {availableStates.map((availableState) => (
                  <Grid key={availableState.state} item xs={2} sx={{alignItems: 'stretch'}}>
                    <Paper elevation={3} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mx: .5, p: 1, width: '100%'}}>
                      <Typography variant='subtitle2' sx={{textAlign: 'center'}}>Inainte de</Typography>
                      <ToggleButton size='small' color='secondary' sx={{width: '100%'}}
                        value={availableState.state}
                        selected={data.allowedStates.includes(availableState.state)}
                        onChange={() => setData({
                          ...data,
                          allowedStates: data.allowedStates.includes(availableState.state)
                            ? data.allowedStates.filter(item => item !== availableState.state)
                            : [...data.allowedStates, availableState.state],
                          requiredStates: data.requiredStates.includes(availableState.state)
                            ? data.requiredStates.filter(item => item !== availableState.state)
                            : data.requiredStates,
                        })}
                      >{availableState.title}</ToggleButton>
                      <Checkbox size='medium' color='secondary'
                        checked={data.requiredStates.includes(availableState.state)}
                        onChange={() => setData({
                          ...data,
                          allowedStates: data.allowedStates.includes(availableState.state)
                            ? data.allowedStates
                            : [...data.allowedStates, availableState.state],
                          requiredStates: data.requiredStates.includes(availableState.state)
                            ? data.requiredStates.filter(item => item !== availableState.state)
                            : [...data.requiredStates, availableState.state],
                        })}
                      />
                    </Paper>
                  </Grid>
                  // <Box key={availableState.state} sx={{flex: '12.5% 0 0', display: 'flex', flexDirection: 'column'}}>
                  //   <ToggleButton size='small' color='secondary'
                  //     value={availableState.state}
                  //     selected={data.allowedStates.includes(availableState.state)}
                  //     onChange={() => setData({
                  //       ...data,
                  //       allowedStates: data.allowedStates.includes(availableState.state)
                  //         ? data.allowedStates.filter(item => item !== availableState.state)
                  //         : [...data.allowedStates, availableState.state],
                  //       requiredStates: data.requiredStates.includes(availableState.state)
                  //         ? data.requiredStates.filter(item => item !== availableState.state)
                  //         : data.requiredStates,
                  //      })}
                  //   >
                  //     <Typography sx={{fontWeight: '500', whiteSpace: 'nowrap', textTransform: 'none'}}>{availableState.title}</Typography>
                  //   </ToggleButton>
                  //   <Checkbox size='medium' color='secondary'
                  //     checked={data.requiredStates.includes(availableState.state)}
                  //     onChange={() => setData({
                  //       ...data,
                  //       allowedStates: data.allowedStates.includes(availableState.state)
                  //         ? data.allowedStates
                  //         : [...data.allowedStates, availableState.state],
                  //       requiredStates: data.requiredStates.includes(availableState.state)
                  //         ? data.requiredStates.filter(item => item !== availableState.state)
                  //         : [...data.requiredStates, availableState.state],
                  //      })}
                  //   />
                  // </Box>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceAction;