import { useState } from "react";

function useUndoRedo(initialState) {
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState(initialState);
  const [future, setFuture] = useState([]);
  const [isUndoable, setIsUndoable] = useState(false);
  const [isRedoable, setIsRedoable] = useState(false);

  const update = newState => {
    if (JSON.stringify(present) === JSON.stringify(newState)) return;

    // console.log('useUndoRedo state changed from', present, 'to', typeof newState, newState);

    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
    setIsUndoable(true);
    setIsRedoable(false);
  };

  const reset = newState => {
    setPast([]);
    setPresent(newState);
    setFuture([]);
    setIsUndoable(false);
    setIsRedoable(false);
  };

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    setIsUndoable(newPast.length > 0);
    setIsRedoable(true);
  };

  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    setIsUndoable(true);
    setIsRedoable(newFuture.length > 0);
  };

  return {present, update, reset, isUndoable, undo, isRedoable, redo};
}

export default useUndoRedo;