import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ServiceActionCheckbox = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    if (defaultData === null /*JSON.stringify(props.value) !== JSON.stringify(defaultData)*/) {
      setDefaultData(props.value);
      setData(props.value);

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded) return;

    if (JSON.stringify(data) !== JSON.stringify(props.value)) {
      props.onChange({
        target: {
          type: 'component',
          data: data,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Stack direction='column'>
      <Typography sx={{marginBottom: '-7px', fontSize: '0.75rem', color: props.isRequired && data.value === '' ? theme => theme.palette.error.main : 'inherit'}}>
        {props.title}
        {props.isRequired &&
          <span aria-hidden='true' className='MuiFormLabel-asterisk MuiInputLabel-asterisk'> *</span>
        }
      </Typography>
      <FormGroup row sx={{pl: 1}}>
        {props.options.map(item => (
          <FormControlLabel key={item.id}
            label={<Typography sx={{color: props.isRequired && data.value === '' ? 'error.main' : 'default'}}>{item.dataset}</Typography>}
            control={
              <Checkbox size='small'
                sx={{color: props.isRequired && data.value === '' ? 'error.main' : 'default'}}
                disabled={props.disabled}
                checked={!!~data.value.split(',').indexOf(item.id)}
                onChange={() => {
                  setData(prevState => ({
                    ...prevState,
                    value: props.allowMultiple
                      ? prevState.value.split(',').includes(item.id)
                        ? prevState.value.split(',').filter(val => val !== item.id).filter(val => Boolean(val)).join(',')
                        : prevState.value.split(',').concat(item.id).sort().filter(val => Boolean(val)).join(',')
                      : prevState.value === item.id
                        ? ''
                        : item.id,
                  }));
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </Stack>
  );
};

export default ServiceActionCheckbox;