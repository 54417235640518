import React, { useEffect, useContext } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Logout = props => {
  /**
   * Constants
   */

  /**
   * States
   */

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('logout', {
      idUser: ac.user?.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (!ac.user) {
    return <Navigate to='/' replace />
  }

  return (
    <Container maxWidth='xs' fixed>
      <Typography variant='subtitle2'>Logout - ceva nu a mers cum trebuie</Typography>
    </Container>
  );
};

export default Logout;