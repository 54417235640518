import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CountryCountyCity from './CountryCountyCity';

const Wizard02MyBranches = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    Boolean(props.data) && !Boolean(data) && setData(props.data);
    Boolean(props.deps) && !Boolean(deps) && setDeps(props.deps);
    Boolean(props.defs) && !Boolean(defs) && setDefs(props.defs);

    Boolean(props.data) && Boolean(props.deps) && Boolean(props.defs) && !isLoaded && setIsLoaded(true);
  }, [props, data, deps, defs, isLoaded]);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.branch === '' ? 'Nu ati completat numele punctului de lucru' : message;
    message = message !== null ? message : data.idCountry === '' ? 'Nu ati selectat tara' : message;
    message = message !== null ? message : data.idCounty === '' ? 'Nu ati selectat judetul' : message;
    message = message !== null ? message : data.idCity === '' ? 'Nu ati selectat localitatea' : message;
    message = message !== null ? message : data.address === '' ? 'Nu ati completat adresa' : message;
    message = message !== null ? message : data.email === '' ? 'Nu ati completat adresa de email' : message;
    message = message !== null ? message : data.phone === '' ? 'Nu ati completat numarul de telefon' : message;

    setNotification(message);
  }, [isLoaded, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleConfirm = () => () => {
    ac.ajax('saveBranch', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Container maxWidth='sm' fixed sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Box>
        <Typography variant='subtitle2' color=''>Perfect, suntem pe drumul cel bun!</Typography>
      </Box>

      <Typography>Asa cum este normal, orice societate are si cel putin un punct de lucru.</Typography>
      <Typography>Haideti sa introducem mai jos informatiile primului punct de lucru.</Typography>
      <Typography>Daca aveti mai multe puncte de lucru, dupa aceasta etapa veti putea sa va adaugati si alte puncte de lucru accesand panoul de administrare.</Typography>
      <Typography>Dupa cum probabil deja ati observat in formularul de mai jos, adresa este deja completata cu adresa sediului social al societatii. Schimbati adresa daca nu corespunde realitatii.</Typography>

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField disabled
              label='ID'
              placeholder='ID'
              name='id'
              value={data.id}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField select required disabled
              label='Societate'
              placeholder='Societate'
              name='idBeneficiary'
              value={data.idBeneficiary}
              onChange={handleDataChange()}
            >
              <MenuItem value={data.idBeneficiary}>{deps.myCompany.beneficiary}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <TextField required autoFocus
              disabled={ac.isLoading}
              label='Punct de lucru'
              placeholder='Punct de lucru'
              name='branch'
              value={data.branch}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <CountryCountyCity
              layout      ={{  country: {sx: 12, sm: 4},   county: {sx: 12, sm: 4},   city: {sx: 12, sm: 4}}}
              allowAdd    ={{  country: true           ,   county: true           ,   city: true           }}
              disabled    ={{  country: ac.isLoading   ,   county: ac.isLoading   ,   city: ac.isLoading   }}
              labels      ={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
              placeholders={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
              names       ={{  country: 'country'      ,   county: 'county'       ,   city: 'city'         }}
              value       ={{idCountry: data.idCountry , idCounty: data.idCounty  , idCity: data.idCity    }}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField required
              disabled={ac.isLoading}
              label='Adresa'
              placeholder='Adresa (str. nr., bl., sc., et., ap.)'
              name='address'
              value={data.address}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField required
              disabled={ac.isLoading}
              label='Email'
              placeholder='Email'
              name='email'
              value={data.email}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField required
              disabled={ac.isLoading}
              label='Telefon'
              placeholder='Telefon'
              name='phone'
              value={data.phone}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField multiline rows={3}
              disabled={ac.isLoading}
              label='Observatii'
              placeholder='Observatii'
              name='description'
              value={data.description}
              onChange={handleDataChange()}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </Typography>
      </Box>

      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
      </DialogActions>
    </Container>
  );
};

export default Wizard02MyBranches;