/**
 * var userObject = {userId: 24, name: 'Jack Bauer'};
 * localStorage.setObject(localStorageKey, userObject);
 */
Storage.prototype.setObject = function(key, value) {
  this.setItem(key, JSON.stringify(value));
};

/**
 * userObject = localStorage.getObject(localStorageKey);
 */
Storage.prototype.getObject = function(key) {
  var value = this.getItem(key);
  return value ? JSON.parse(value) : null;
};

const monthNames = {
   1: 'Ianuarie',
   2: 'Februarie',
   3: 'Martie',
   4: 'Aprilie',
   5: 'Mai',
   6: 'Iunie',
   7: 'Iulie',
   8: 'August',
   9: 'Septembrie',
  10: 'Octombrie',
  11: 'Noiembrie',
  12: 'Decembrie',
};
const monthShortNames = {
   1: 'Ian',
   2: 'Feb',
   3: 'Mar',
   4: 'Apr',
   5: 'Mai',
   6: 'Iun',
   7: 'Iul',
   8: 'Aug',
   9: 'Sep',
  10: 'Oct',
  11: 'Noi',
  12: 'Dec',
};
const weekdayNames = {
  0: 'Duminica',
  1: 'Luni',
  2: 'Marti',
  3: 'Miercuri',
  4: 'Joi',
  5: 'Vineri',
  6: 'Sambata',
};
const weekdayShortNames = {
  0: 'Dum',
  1: 'Lun',
  2: 'Mar',
  3: 'Mie',
  4: 'Joi',
  5: 'Vin',
  6: 'Sam',
};

// eslint-disable-next-line
Date.prototype.format = function(format = 'DD.MM.YYYY') {
  const year = this.getFullYear();
  const month = this.getMonth() + 1;
  const day = this.getDate();
  const weekday = this.getDay();
  const hours = this.getHours();
  const minutes = this.getMinutes();
  const seconds = this.getSeconds();

  var date = format;

  date = date.replace(/\bss\b/g, seconds.toString().padStart(2, '0'));
  date = date.replace(/\bs\b/g, seconds.toString());

  date = date.replace(/\bmm\b/g, minutes.toString().padStart(2, '0'));
  date = date.replace(/\bm\b/g, minutes.toString());

  date = date.replace(/\bhh\b/g, hours.toString().padStart(2, '0'));
  date = date.replace(/\bh\b/g, hours.toString());

  date = date.replace(/\bDDDD\b/g, weekdayNames[weekday.toString()]);
  date = date.replace(/\bDDD\b/g, weekdayShortNames[weekday.toString()]);
  date = date.replace(/\DD\b/g, day.toString().padStart(2, '0'));
  date = date.replace(/\bD\b/g, day.toString());

  date = date.replace(/\bMMMM\b/g, monthNames[month.toString()]);
  date = date.replace(/\bMMM\b/g, monthShortNames[month.toString()]);
  date = date.replace(/\bMM\b/g, month.toString().padStart(2, '0'));
  date = date.replace(/\bM\b/g, month.toString());

  date = date.replace(/\bYYYY\b/g, year.toString());

  return date;
};

// eslint-disable-next-line
Date.prototype.addDays = function(days = 0) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Array.prototype.max = function(key, defaultValue = 0) {
  const maxValue = this.reduce((acc, item) => Math.max(acc, parseInt(item[key])), -Infinity);
  return Math.max(maxValue, defaultValue);
};
Array.prototype.min = function(key, defaultValue = 0) {
  const minValue = this.reduce((acc, item) => Math.min(acc, parseInt(item[key])), Infinity);
  return Math.min(minValue, defaultValue);
};

String.prototype.cnpParse = function() {
  const result = {
    isValid: true,
    error: null,
    sex: '?',
    birthDate: new Date().format('YYYY-MM-DD'),
  };

  if (this.length !== 13) {
    result.isValid = false;
    result.error = 'Lungimea CNP-ului nu este de 13 caractere';
  } else {
    var i=0, year=0, hashResult=0, hashTable=[2,7,9,1,4,6,3,5,8,2,7,9];

    for (i = 0 ; i < 13 ; i++) {
      if(isNaN(parseInt(this.charAt(i), 10))) {
        result.isValid = false;
        result.error = 'Caracterele din CNP nu sunt toate numerice';
      }
      if (i < 12) {
        hashResult = hashResult + parseInt(this.charAt(i), 10) * hashTable[i];
      }
    }

    if (result.isValid) {
      hashResult = hashResult % 11 === 10 ? 1 : hashResult % 11;

      year = (parseInt(this.charAt(1), 10) * 10) + parseInt(this.charAt(2), 10);
      switch(parseInt(this.charAt(0), 10)) {
        case 1:
          result.sex = 'M';
          year += 1900;
          break;
        case 2:
          result.sex = 'F';
          year += 1900;
          break;
        case 3:
          result.sex = 'M';
          year += 1800;
          break;
        case 4:
          result.sex = 'F';
          year += 1800;
          break;
        case 5:
          result.sex = 'M';
          year += 2000;
          break;
        case 6:
          result.sex = 'F';
          year += 2000;
          break;
        case 7:
        case 8:
        case 9:
          year = year + (year + 2000 > parseInt(new Date().getYear(), 10) - 14 ? 1900 : 2000);
          break;
        default:
          result.isValid = false;
          result.error = 'Prima cifra din CNP nu este valida';
      }

      if (result.isValid) {
        if (year < 1800 || year > 2099) {
          result.isValid = false;
          result.error = 'Anul nasterii calculat din CNP nu este intre 1800 si 2099';
        } else if (parseInt(this.charAt(12), 10) !== hashResult) {
          result.isValid = false;
          result.error = 'CNP-ul introdus nu este valid';
        }
      }
    }
  }

  return {
    ...result,
    birthDate: `${year}-${this.charAt(3)}${this.charAt(4)}-${this.charAt(5)}${this.charAt(6)}`,
  };
};
String.prototype.isBirthday = function(date = null) {
  const birthDate = new Date(this);
  date = date ?? new Date();
  return birthDate.getMonth() === date.getMonth() && birthDate.getDate() === date.getDate();
};
// String.prototype.toCurrency = function(currency = 'RON') {
//   const number = parseFloat(this.replace(/,/g, ''));

//   if (isNaN(number)) {
//     return 'Invalid number';
//   }

//   return new Intl.NumberFormat('ro-RO', {
//     style: 'decimal',
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   }).format(number) + ` ${currency}`;
// };

String.prototype.toCurrency = function(currency = 'RON', thousandsSeparator = '.', decimalSeparator = ',', decimals = 2) {
  let number = parseFloat(this);

  if (isNaN(number)) {
    return 'N/A';
  }

  let numberString = number.toFixed(decimals).toString();
  let [integerPart, decimalPart] = numberString.split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  return `${integerPart}${decimalPart ? decimalSeparator + decimalPart : ''}${currency ? ` ${currency}` : ''}`;
};

Number.prototype.toCurrency = function(currency = 'RON', thousandsSeparator = '.', decimalSeparator = ',', decimals = 2) {
  let numberString = this.toFixed(decimals).toString();
  let [integerPart, decimalPart] = numberString.split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

  return `${integerPart}${decimalPart ? decimalSeparator + decimalPart : ''}${currency ? ` ${currency}` : ''}`;
};

const toBase64 = file => {
  console.log(`type:`, typeof file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
};