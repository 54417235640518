import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import CountryCountyCity from './CountryCountyCity';

const CustomerAddress = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCustomerAddress', {
      idCustomerAddress: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.customerAddress.id)) {
            setDefaultData(response.result.data.customerAddress);
            setData(response.result.data.customerAddress);
          } else {
            setDefaultData(response.result.defaultValues.customerAddress);
            setData({
              ...response.result.data.customerAddress,
              idCustomer: props.value.idCustomer,
            });
          }

          setDeps(response.result.dependencies);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.idCountry === '' ? 'Nu ati selectat tara' : message;
    message = message !== null ? message : data.idCounty === '' ? 'Nu ati selectat judetul' : message;
    message = message !== null ? message : data.idCity === '' ? 'Nu ati selectat localitatea' : message;
    message = message !== null ? message : data.street === '' ? 'Nu ati completat numele strazii' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveCustomerAddress', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='sm' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Adresa client: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label='Client'
                placeholder='Client'
                value={props.customer}
              />
            </Grid>
            <Grid item xs={12}>
              <CountryCountyCity
                layout      ={{  country: {sx: 12, sm: 4},   county: {sx: 12, sm: 4},   city: {sx: 12, sm: 4}}}
                allowAdd    ={{  country: true           ,   county: true           ,   city: true           }}
                disabled    ={{  country: ac.isLoading   ,   county: ac.isLoading   ,   city: ac.isLoading   }}
                labels      ={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
                placeholders={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
                names       ={{  country: 'country'      ,   county: 'county'       ,   city: 'city'         }}
                value       ={{idCountry: data.idCountry , idCounty: data.idCounty  , idCity: data.idCity    }}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Strada'
                placeholder='Strada'
                name='street'
                value={data.street}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required
                disabled={ac.isLoading}
                label='Numar'
                placeholder='Numar'
                name='streetNumber'
                value={data.streetNumber}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required
                disabled={ac.isLoading}
                label='Bloc'
                placeholder='Bloc'
                name='building'
                value={data.building}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required
                disabled={ac.isLoading}
                label='Scara'
                placeholder='Scara'
                name='staircase'
                value={data.staircase}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required
                disabled={ac.isLoading}
                label='Etaj'
                placeholder='Etaj'
                name='floor'
                value={data.floor}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required
                disabled={ac.isLoading}
                label='Apartament'
                placeholder='Apartament'
                name='apartment'
                value={data.apartment}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerAddress;