import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';
import '../Utils';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const GdprAgreement = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getGdprAgreement', {
      idCustomer: props.value.idCustomer,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.gdprAgreement.id)) {
            setDefaultData(response.result.data.gdprAgreement);
            setData(response.result.data.gdprAgreement);
          } else {
            setDefaultData(response.result.defaultValues.gdprAgreement);
            setData({
              ...response.result.data.gdprAgreement,
              idCustomer: props.value.idCustomer,
              idGdprAgreementType: response.result.dependencies.gdprAgreementTypes.find(item => item.isDefault)?.id || response.result.defaultValues.gdprAgreement.idGdprAgreementType,
            });
          }

          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.idGdprAgreementType === '' ? 'Nu ati selectat tipul acordului GDPR' : message;
    message = message !== null ? message : getGdprAgreementType().isFileRequired && data.idFile === defs.gdprAgreement.idFile && data.newFile === defs.gdprAgreement.newFile ? 'Nu ati selectat documentul (fisierul)' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  useEffect(() => {
    if (!isLoaded) return;

    if (data.newFile === defs.gdprAgreement.newFile) {
      setData(prevState => ({
        ...prevState,
        newFileName: defs.gdprAgreement.newFileName,
        newFileBase64: defs.gdprAgreement.newFileBase64,
      }));
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        setData(prevState => ({
          ...prevState,
          newFileName: data.newFile.name.replace(/.*[\/\\]/, ''),
          newFileBase64: reader.result,
        }));
      };
      reader.readAsDataURL(data.newFile);
    }
  }, [isLoaded, data?.newFile]);

  /**
   * Getters
   */
  const getGdprAgreementTypes = useCallback(() => {
    return deps.gdprAgreementTypes/*.filter(item => !item.isOnline)*/.sort(firstBy('gdprAgreementType'));
  }, [deps]);
  const getGdprAgreementType = useCallback((idGdprAgreementType = null) => {
    return deps.gdprAgreementTypes.find(item => item.id === (idGdprAgreementType ?? data.idGdprAgreementType));
  }, [data?.idGdprAgreementType]);

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'idGdprAgreementType':
        mods = {
          ...mods,
          idFile: !getGdprAgreementType(evt.target.value).isFileRequired ? defs.gdprAgreement.idFile : defaultData.idFile,
          newFile: !getGdprAgreementType(evt.target.value).isFileRequired ? defs.gdprAgreement.newFile : data.newFile,
          newFileName: !getGdprAgreementType(evt.target.value).isFileRequired ? defs.gdprAgreement.newFileName : data.newFileName,
          newFileBase64: !getGdprAgreementType(evt.target.value).isFileRequired ? defs.gdprAgreement.newFileBase64 : data.newFileBase64,
        };
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      case 'file':
        value = evt.target.files.length === 1 ? evt.target.files[0] : defs.gdprAgreement.newFile;
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveGdprAgreement', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xs' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Acord GDPR client: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                label='Client'
                placeholder='Client'
                value={props.customer}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField select required autoFocus
                disabled={ac.isLoading}
                label='Tip acord GDPR'
                placeholder='Tip acord GDPR'
                name='idGdprAgreementType'
                value={data.idGdprAgreementType}
                onChange={handleDataChange()}
              >
                {getGdprAgreementTypes().map(item => (
                  <MenuItem key={item.id} value={item.id} disabled={item.isOnline}><Typography variant='body1'>{item.gdprAgreementType}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sx={{justifyContent: 'space-between'}}>
              <FormControlLabel disabled
                label='Cookies'
                control={
                  <Checkbox size='small' disabled
                    name='allowCookies'
                    checked={data.allowCookies}
                    onChange={handleDataChange()}
                  />
                }
              />
              <FormControlLabel
                disabled={ac.isLoading || getGdprAgreementType().isOnline}
                label='Email'
                control={
                  <Checkbox size='small'
                  disabled={ac.isLoading || getGdprAgreementType().isOnline}
                    name='allowEmail'
                    checked={data.allowEmail}
                    onChange={handleDataChange()}
                  />
                }
              />
              <FormControlLabel
                disabled={ac.isLoading || getGdprAgreementType().isOnline}
                label='Telefon'
                control={
                  <Checkbox size='small'
                  disabled={ac.isLoading || getGdprAgreementType().isOnline}
                    name='allowPhone'
                    checked={data.allowPhone}
                    onChange={handleDataChange()}
                  />
                }
              />
              <FormControlLabel
                disabled={ac.isLoading || getGdprAgreementType().isOnline}
                label='SMS'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading || getGdprAgreementType().isOnline}
                    name='allowSms'
                    checked={data.allowSms}
                    onChange={handleDataChange()}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <IconButton component='label'
                      disabled={ac.isLoading || !getGdprAgreementType().isFileRequired}
                    >
                      <CloudUploadIcon />
                      <input type='file' hidden style={{display: 'none'}}
                        name='newFile'
                        onChange={handleDataChange()}
                      />
                    </IconButton>
                  ),
                }}
                disabled={ac.isLoading || !getGdprAgreementType().isFileRequired}
                label='Acord GDPR'
                placeholder='Acord GDPR'
                error={getGdprAgreementType().isFileRequired && data.idFile === defs.gdprAgreement.idFile && data.newFile === defs.gdprAgreement.newFile}
                value={data.newFileName !== defs.gdprAgreement.newFileName ? data.newFileName.replace(/.*[\/\\]/, '') : data.idFile !== defs.gdprAgreement.idFile ? data.fileName : 'Nici un fisier selectat'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GdprAgreement;