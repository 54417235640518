import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const defaultData = {
  email: '',
  password: '',
};

const Login = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [data, setData] = useState(defaultData);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    let message = null;

    message = message !== null ? message : data.email === '' ? 'Nu ati completat adresa de email' : message;
    message = message !== null ? message : data.password === '' ? 'Nu ati completat parola' : message;

    setNotification(message);
  }, [data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClickLogin = () => () => {
    ac.ajax('login', {
      email: data.email,
      password: data.password,
    });
  };

  /**
   * Renderer
   */
  if (ac.user) {
    return <Navigate to='/' replace />
  }

  return (
    <Container maxWidth='xs' fixed>
      <Typography variant='subtitle2'>Completati mai jos datele de autentificare ale contului dumneavoastra si apasati pe butonul <strong>AUTENTIFICARE</strong>.</Typography>
      <Grid container spacing={1} mt={1}>
        <Grid item xs={12} mt={1} sx={{display: 'flex', gap: 1}}>
          <Box sx={{flexGrow: 1}} />
        </Grid>
        <Grid item xs={12}>{localStorage.getObject(props.localStorageKey)}</Grid>
        <Grid item xs={12}>
          <TextField /*type='text'*/
            disabled={ac.isLoading}
            label='Adresa de email'
            placeholder='exemplu@domeniu.com'
            name='email'
            value={data.email}
            onChange={handleDataChange()}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField type='password'
            disabled={ac.isLoading}
            label='Parola'
            placeholder='Ceva complicat'
            name='password'
            value={data.password}
            onChange={handleDataChange()}
          />
        </Grid>
        {Boolean(notification) &&
          <Grid item xs={12} mt={1}>
            <Typography color='error'>{notification}</Typography>
          </Grid>
        }
        <Grid item xs={12} mt={1} sx={{display: 'flex', gap: 1}}>
          <Box sx={{flexGrow: 1}} />
          <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleClickLogin()}>Autentificare</Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;