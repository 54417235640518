import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

/**
 * Static core components
 */

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import CustomerAddress from './CustomerAddress';
import DefaultCustomerAddress from './DefaultCustomerAddress';
import CustomerIdentityCard from './CustomerIdentityCard';
import DefaultCustomerIdentityCard from './DefaultCustomerIdentityCard';
import CustomerPhone from './CustomerPhone';
import DefaultCustomerPhone from './DefaultCustomerPhone';
import CustomerEmail from './CustomerEmail';
import DefaultCustomerEmail from './DefaultCustomerEmail';

const dateTimePickerSlotProps = {
  textField: {
    // variant: 'outlined',
    InputProps: {readOnly: true},
    onBeforeInput: evt => evt?.preventDefault() && evt?.stopPropagation(),
  },
};

const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Customer = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCustomer', {
      idCustomer: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.customer.id)) {
            setDefaultData(response.result.data.customer);
            setData(response.result.data.customer);
          } else {
            setDefaultData(response.result.defaultValues.customer);
            setData(response.result.data.customer);
          }

          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.cnp === '' ? 'Nu ati completat CNP-ul clientului' : message;
    message = message !== null ? message : !data.isForeign && data.cnp.length !== 13 ? 'CNP-ul trebuie sa aiba exact 13 caractere' : message;
    message = message !== null ? message : data.cnp.length === 13 && !data.isForeign && !data.cnp.cnpParse().isValid ? data.cnp.cnpParse().error : message;
    message = message !== null ? message : data.name === '' ? 'Nu ati completat numele clientului' : message;
    message = message !== null ? message : data.surname === '' ? 'Nu ati completat prenumele clientului' : message;
    message = message !== null ? message : data.sex === '' ? 'Nu ati selectat sexul clientului' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getUnknownItems = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'isForeign':
        if (data.cnp.length === 13 && !evt.target.checked && data.cnp.cnpParse().isValid) {
          mods.sex = data.cnp.cnpParse().sex;
          mods.birthDate = data.cnp.cnpParse().birthDate;
        }
      case 'cnp':
        if (evt.target.value.length === 13 && !data.isForeign && evt.target.value.cnpParse().isValid) {
          mods.sex = evt.target.value.cnpParse().sex;
          mods.birthDate = evt.target.value.cnpParse().birthDate;
        }
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleSaveAndContinue = () => () => {
    ac.ajax('saveCustomer', data)
      .then(response => {
        if (response.status.ok) {
          setDefaultData(response.result.data.customer);
          setData(response.result.data.customer);
          props.onConfirm(response, false);
        }
      });
  };
  const handleConfirm = () => () => {
    ac.ajax('saveCustomer', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='md' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Client: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} sx={{alignItems: 'flex-start'}}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField disabled
                    label='ID'
                    placeholder='ID'
                    name='id'
                    value={data.id}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    label='Strain'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='isForeign'
                        checked={data.isForeign}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField required autoFocus
                    disabled={ac.isLoading}
                    label='CNP'
                    placeholder='CNP'
                    name='cnp'
                    value={data.cnp}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField required
                    disabled={ac.isLoading}
                    label='Nume'
                    placeholder='Nume'
                    name='name'
                    value={data.name}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField required
                    disabled={ac.isLoading}
                    label='Prenume'
                    placeholder='Prenume'
                    name='surname'
                    value={data.surname}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField select required
                    disabled={ac.isLoading || !data.isForeign}
                    label='Sex'
                    placeholder='Sex'
                    name='sex'
                    value={data.sex}
                    onChange={handleDataChange()}
                  >
                    <MenuItem value={'M'}>Masculin</MenuItem>
                    <MenuItem value={'F'}>Feminin</MenuItem>
                    <MenuItem value={'?'}>Necunoscut / altele</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker slotProps={dateTimePickerSlotProps} maxDate={dayjs(new Date())} views={['year', 'month', 'day']}
                    disabled={ac.isLoading || !data.isForeign}
                    label='Data nasterii'
                    value={dayjs(data.birthDate)}
                    onChange={newValue => handleDataChange()({target: {type: 'datePicker', name: 'birthDate', value: newValue}})}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField required
                    disabled={ac.isLoading}
                    label='Tutore sau reprezentant legal'
                    placeholder='Tutore sau reprezentant legal'
                    name='idGuardian'
                    value={data.idGuardian}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List dense sx={{flexGrow: 1}}>
                    <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px'}}>
                      <Typography variant='subtitle2'>Adrese</Typography>
                      <Box sx={{display: 'flex', gap: 1}}>
                        <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                          disabled={ac.isLoading || data.id === defs.customer.id}
                          onClick={handleSelectionChange({entity: {...defs.customerAddress, idCustomer: data.id}, op: 'editCustomerAddress'})}
                        >
                          Adauga
                        </Button>
                      </Box>
                    </ListSubheader>

                    <Entities data={data.addresses} deps={deps} selection={selection} sx={{padding: '0px !important'}}
                      sortBy={['country', 'county', 'city', 'street', 'streetNumber', 'building', 'staircase', 'apartment']}
                      messageNoData='Nici o adresa definita'
                      renderEntity={{
                        icon: null,
                        textPrimary: entity => (
                          <Typography variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${entity.street} nr. ${entity.streetNumber}, bl. ${entity.building}, sc. ${entity.staircase}, et. ${entity.floor}, ap. ${entity.apartment}`}</Typography>
                        ),
                        textSecondary: [
                          entity => <Typography variant='body2'>{`${entity.city}, jud. ${entity.county}, ${entity.country}`}</Typography>,
                        ],
                        textIcons: [
                          entity => ({text: 'Aceasta adresa este definita ca implicita', icon: 'Home', color: 'primary', when: entity.isDefault}),
                        ],
                      }}
                      contextualMenu={[
                        entity => ({text: 'Modifica'        , icon: null, action: 'editCustomerAddress'      , when: true                       }),
                        entity => ({text: 'Seteaza implicit', icon: null, action: 'setDefaultCustomerAddress', when: !selection.entity.isDefault}),
                        entity => ({text: 'Sterge'          , icon: null, action: 'deleteCustomerAddress'    , when: true                       }),
                      ]}
                      // newEntity={{
                      //   op: 'editCustomerAddress',
                      //   entity: {
                      //     ...defs.customerAddress,
                      //     idCustomer: data.id,
                      //   },
                      // }}
                      onChangeSelection={setSelection}
                    />
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline rows={3}
                    disabled={ac.isLoading}
                    label='Observatii'
                    placeholder='Observatii'
                    name='description'
                    value={data.description}
                    onChange={handleDataChange()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{alignItems: 'flex-start'}}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <List dense sx={{flexGrow: 1}}>
                    <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>Acte de identitate</Typography>
                      <Box sx={{display: 'flex', gap: 1}}>
                        <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                          disabled={ac.isLoading || data.id === defs.customer.id}
                          onClick={handleSelectionChange({entity: {...defs.customerIdentityCard, idCustomer: data.id}, op: 'editCustomerIdentityCard'})}
                        >
                          Adauga
                        </Button>
                      </Box>
                    </ListSubheader>

                    <Entities data={data.identityCards} deps={deps} selection={selection}
                      sortBy={['validTo']}
                      messageNoData='Nici un act de identitate definit'
                      renderEntity={{
                        icon: null,
                        textPrimary: entity => (
                          <Typography variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${deps.identityCardTypes.find(item => item.id === entity.idIdentityCardType).identityCardType} ${entity.serial} ${entity.number}`}</Typography>
                        ),
                        textSecondary: [
                          entity => <Typography variant='body2'>{`Eliberat de ${entity.issuedBy} la ${entity.issuedAt}`}</Typography>,
                          entity => <Typography variant='body2'>{`Expira la ${entity.validTo}`}</Typography>,
                        ],
                        textIcons: [
                          entity => ({text: 'Acest act de identitate este definit ca implicit', icon: 'Home', color: 'primary', when: entity.isDefault}),
                        ],
                      }}
                      contextualMenu={[
                        entity => ({text: 'Modifica'        , icon: null, action: 'editCustomerIdentityCard'      , when: true                       }),
                        entity => ({text: 'Seteaza implicit', icon: null, action: 'setDefaultCustomerIdentityCard', when: !selection.entity.isDefault}),
                        entity => ({text: 'Sterge'          , icon: null, action: 'deleteCustomerIdentityCard'    , when: true                       }),
                      ]}
                      // newEntity={{
                      //   op: 'editCustomerIdentityCard',
                      //   entity: {
                      //     ...defs.customerIdentityCard,
                      //     idCustomer: data.id,
                      //   },
                      // }}
                      onChangeSelection={setSelection}
                    />
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <List dense sx={{flexGrow: 1}}>
                    <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>Numere de telefon</Typography>
                      <Box sx={{display: 'flex', gap: 1}}>
                        <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                          disabled={ac.isLoading || data.id === defs.customer.id}
                          onClick={handleSelectionChange({entity: {...defs.customerPhone, idCustomer: data.id}, op: 'editCustomerPhone'})}
                        >
                          Adauga
                        </Button>
                      </Box>
                    </ListSubheader>

                    <Entities data={data.phones} deps={deps} selection={selection}
                      sortBy={['phone']}
                      messageNoData='Nici un numar de telefon definit'
                      renderEntity={{
                        icon: null,
                        textPrimary: entity => (
                          <Typography variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.phone}</Typography>
                        ),
                        textSecondary: null,
                        textIcons: [
                          entity => ({text: 'Acest numar de telefon este definit ca implicit', icon: 'Home', color: 'primary', when: entity.isDefault}),
                        ],
                      }}
                      contextualMenu={[
                        entity => ({text: 'Modifica'        , icon: null, action: 'editCustomerPhone'      , when: true                       }),
                        entity => ({text: 'Seteaza implicit', icon: null, action: 'setDefaultCustomerPhone', when: !selection.entity.isDefault}),
                        entity => ({text: 'Sterge'          , icon: null, action: 'deleteCustomerPhone'    , when: true                       }),
                      ]}
                      // newEntity={{
                      //   op: 'editCustomerPhone',
                      //   entity: {
                      //     ...defs.customerPhone,
                      //     idCustomer: data.id,
                      //   },
                      // }}
                      onChangeSelection={setSelection}
                    />
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <List dense sx={{flexGrow: 1}}>
                    <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                      <Typography variant='subtitle2'>Adrese de email</Typography>
                      <Box sx={{display: 'flex', gap: 1}}>
                        <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                          disabled={ac.isLoading || data.id === defs.customer.id}
                          onClick={handleSelectionChange({entity: {...defs.customerEmail, idCustomer: data.id}, op: 'editCustomerEmail'})}
                        >
                          Adauga
                        </Button>
                      </Box>
                    </ListSubheader>

                    <Entities data={data.emails} deps={deps} selection={selection}
                      sortBy={['email']}
                      messageNoData='Nici o adresa de email definita'
                      renderEntity={{
                        icon: null,
                        textPrimary: entity => (
                          <Typography variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.email}</Typography>
                        ),
                        textSecondary: null,
                        textIcons: [
                          entity => ({text: 'Aceasta adresa de email este definita ca implicita', icon: 'Home', color: 'primary', when: entity.isDefault}),
                        ],
                      }}
                      contextualMenu={[
                        entity => ({text: 'Modifica'        , icon: null, action: 'editCustomerEmail'      , when: true                       }),
                        entity => ({text: 'Seteaza implicit', icon: null, action: 'setDefaultCustomerEmail', when: !selection.entity.isDefault}),
                        entity => ({text: 'Sterge'          , icon: null, action: 'deleteCustomerEmail'    , when: true                       }),
                      ]}
                      // newEntity={{
                      //   op: 'editCustomerEmail',
                      //   entity: {
                      //     ...defs.customerEmail,
                      //     idCustomer: data.id,
                      //   },
                      // }}
                      onChangeSelection={setSelection}
                    />
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        {data.id === defs.customer.id
          ? <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleSaveAndContinue()}>Continua</Button>
          : <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        }
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>

      {/**
       * Add/edit customer address
       */}
      {Boolean(selection.entity) && selection.op === 'editCustomerAddress' &&
        <CustomerAddress
          customer={`${data.name} ${data.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Set default customer address
       */}
      {Boolean(selection.entity) && selection.op === 'setDefaultCustomerAddress' &&
        <DefaultCustomerAddress
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Add/edit customer identity card
       */}
      {Boolean(selection.entity) && selection.op === 'editCustomerIdentityCard' &&
        <CustomerIdentityCard
          customer={`${data.name} ${data.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Set default customer identity card
       */}
      {Boolean(selection.entity) && selection.op === 'setDefaultCustomerIdentityCard' &&
        <DefaultCustomerIdentityCard
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Add/edit customer phone
       */}
      {Boolean(selection.entity) && selection.op === 'editCustomerPhone' &&
        <CustomerPhone
          customer={`${data.name} ${data.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Set default customer phone
       */}
      {Boolean(selection.entity) && selection.op === 'setDefaultCustomerPhone' &&
        <DefaultCustomerPhone
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Add/edit customer email
       */}
      {Boolean(selection.entity) && selection.op === 'editCustomerEmail' &&
        <CustomerEmail
          customer={`${data.name} ${data.surname}`}
          value={selection.entity}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }

      {/**
       * Set default customer email
       */}
      {Boolean(selection.entity) && selection.op === 'setDefaultCustomerEmail' &&
        <DefaultCustomerEmail
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={response => {
            setData(response.result.data.customer);
            handleSelectionChange({op: defaultSelection.op})();
          }}
        />
      }
    </Dialog>
  );
};

export default Customer;