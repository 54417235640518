import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ServiceActionFile = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    if (defaultData === null /*JSON.stringify(props.value) !== JSON.stringify(defaultData)*/) {
      setDefaultData(props.value);
      setData(props.value);

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded) return;

    if (JSON.stringify(data) !== JSON.stringify(props.value)) {
      props.onChange({
        target: {
          type: 'component',
          data: data,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <IconButton component='label'
            disabled={props.disabled}
          >
            <CloudUploadIcon />
            <input type='file' hidden style={{display: 'none'}}
              onChange={evt => {
                if (evt.target.files.length === 1) {
                  const file = evt.target.files[0];
                  const reader = new FileReader();
                  reader.onload = () => {
                    setData(prevState => ({
                      ...prevState,
                      value: file.name.replace(/.*[\/\\]/, ''),
                      idFile: '',
                      fileName: file.name.replace(/.*[\/\\]/, ''),
                      newFile: file,
                      newFileName: file.name.replace(/.*[\/\\]/, ''),
                      newFileBase64: reader.result,
                    }));
                  };
                  reader.readAsDataURL(file);
                } else {
                  setData(prevState => ({
                    ...prevState,
                    value: defaultData.value,
                    idFile: defaultData.idFile,
                    fileName: defaultData.fileName,
                    newFile: null,
                    newFileName: defaultData.newFileName,
                    newFileBase64: defaultData.newFileBase64,
                  }));
                }
              }}
            />
          </IconButton>
        ),
      }}
      disabled={props.disabled}
      required={props.isRequired}
      error={props.isRequired && data.value === ''}
      label={props.title}
      placeholder={props.title}
      // error={getGdprAgreementType().isFileRequired && data.idFile === defs.gdprAgreement.idFile && data.newFile === defs.gdprAgreement.newFile}
      value={data.value || 'Nici un fisier selectat'}
    />
  );
};

export default ServiceActionFile;