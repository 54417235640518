import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const Dataset = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getDataset', {
      idDataset: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.dataset.id)) {
            setDefaultData(response.result.data.dataset);
            setData(response.result.data.dataset);
          } else {
            setDefaultData(response.result.defaultValues.dataset);
            setData({
              ...response.result.data.dataset,
              dataset: props.value.dataset,
              idParent: props.value.idParent,
              type: props.value.type,
              isDetail: props.value.isDetail,
              isResult: props.value.isResult,
              isNormal: props.value.isResult,
              parentDataset: props.value.parentDataset,
            });
          }

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.dataset === '' ? 'Nu ati completat numele setului de date' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'isDetail':
        mods = {
          ...mods,
          isResult: !evt.target.checked,
          isGood: false,
          isNormal: false,
          isBad: false,
        };
        break;
      case 'isResult':
        mods = {
          ...mods,
          isDetail: !evt.target.checked,
          isGood: false,
          isNormal: evt.target.checked && data.type === 'Value' ? true : false,
          isBad: false,
        };
        break;
      case 'isGood':
        mods = {
          ...mods,
          isGood: true,
          isNormal: false,
          isBad: false,
        };
        break;
      case 'isNormal':
        mods = {
          ...mods,
          isGood: false,
          isNormal: true,
          isBad: false,
        };
        break;
      case 'isBad':
        mods = {
          ...mods,
          isGood: false,
          isNormal: false,
          isBad: true,
        };
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveDataset', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xs' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Set de date: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                label='Set de date parinte'
                placeholder='Set de date parinte'
                value={props.value.parentDataset || 'Set de date principal'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Set de date'
                placeholder='Set de date'
                name='dataset'
                value={data.dataset}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12} sx={{justifyContent: 'space-between'}}>
              <FormControlLabel
                disabled={ac.isLoading || Boolean(data.idParent)}
                label='Detalii serviciu'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading || Boolean(data.idParent)}
                    name='isDetail'
                    checked={data.isDetail}
                    onChange={handleDataChange()}
                  />
                }
              />
              <FormControlLabel
                disabled={ac.isLoading || Boolean(data.idParent)}
                label='Rezultate serviciu'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading || Boolean(data.idParent)}
                    name='isResult'
                    checked={data.isResult}
                    onChange={handleDataChange()}
                  />
                }
              />
            </Grid>
            {data.type === 'Value' && data.isResult &&
              <Grid item xs={12} sx={{justifyContent: 'space-between'}}>
                <FormControlLabel
                  disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                  label='Rezultat bun'
                  control={
                    <Checkbox size='small' color='success'
                      disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                      name='isGood'
                      checked={data.isGood}
                      onChange={handleDataChange()}
                    />
                  }
                />
                <FormControlLabel
                  disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                  label='Rezultat normal'
                  control={
                    <Checkbox size='small'
                      disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                      name='isNormal'
                      checked={data.isNormal}
                      onChange={handleDataChange()}
                    />
                  }
                />
                <FormControlLabel
                  disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                  label='Rezultat rau'
                  control={
                    <Checkbox size='small' color='warning'
                      disabled={ac.isLoading || data.isDetail || data.type === 'Collection'}
                      name='isBad'
                      checked={data.isBad}
                      onChange={handleDataChange()}
                    />
                  }
                />
              </Grid>
            }
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Dataset;