import React, { useState, useContext, useEffect, useCallback } from 'react';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import RegisterType from './RegisterType';
import DisableRegisterType from './DisableRegisterType';
import EnableRegisterType from './EnableRegisterType';

const defaultFilter = {
  idSpecialty: '0',
  text: '',
};
const defaultDeps = {
  specialties: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const RegisterTypes = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getRegisterTypes')
      .then(response => {
        if (response.status.ok) {
          setData(response.result.data.registerTypes);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */
  const getSpecialties = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);
  const getRegisters = (idRegisterType = null) => deps.registers.filter(register => idRegisterType === null || register.idRegisterType === idRegisterType).sort(firstBy('year').thenBy('branch'));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.registerTypes);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.registerTypes.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={6}>
        <TextField select
          disabled={ac.isLoading}
          label='Specialitate'
          placeholder='Specialitate'
          name='idSpecialty'
          value={filter.idSpecialty}
          onChange={handleFilterChange()}
        >
          <MenuItem value={defaultFilter.idSpecialty}><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Toate</Typography></MenuItem>
          {getSpecialties().map(item => (
            <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.specialty}</Typography></MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'          , path: '/'     , icon: 'Home'           },
          {text: 'Administrare'   , path: '/Admin', icon: 'Settings'       },
          {text: 'Tipuri registre', path: null    , icon: 'AppRegistration'},
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['specialty', 'registerType']}
        groupBy={'specialty'}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.registerType}>{entity.id}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' color={entity.isEnabled ? 'textPrimary' : 'rgba(0, 0, 0, 0.26)'} sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.registerType}</Highlighted>
          ),
          textSecondary: [
            entity => getRegisters(entity.id).map(register => (
              <Box key={register.id} sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                {register.isEnabled
                  ? <CheckCircleIcon fontSize='small' color='info' />
                  : <CancelIcon fontSize='small' color='warning' />
                }
                <Highlighted highlight={filter.text}>{register.year || 'Toti anii'} - {register.branch || 'Toate punctele de lucru'} ({register.serial} {register.nextNumber})</Highlighted>
              </Box>
            )),
          ],
          textIcons: [
            entity => ({text: 'Acest tip de registru se reseteaza anual'   , icon: 'LockReset'  , color: 'primary' , when: entity.isAnnual }),
            entity => ({text: 'Acest tip de registru nu se reseteaza anual', icon: 'LockReset'  , color: 'disabled', when: !entity.isAnnual}),
            entity => ({text: 'Acest tip de registru este activ'           , icon: 'CheckCircle', color: 'primary' , when: entity.isEnabled }),
            entity => ({text: 'Acest tip de registru nu este activ'        , icon: 'Cancel'     , color: 'error'   , when: !entity.isEnabled}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'    , icon: null, action: 'editEntity'   , when: true                                                     }),
          entity => ({text: 'Dezactiveaza', icon: null, action: 'disableEntity', when: !selection.entity.isDefault && selection.entity.isEnabled}),
          entity => ({text: 'Activeaza'   , icon: null, action: 'enableEntity' , when: !selection.entity.isEnabled                              }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.registerType,
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <RegisterType
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as disabled
       */}
      {Boolean(selection.entity) && selection.op === 'disableEntity' &&
        <DisableRegisterType
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as enabled
       */}
      {Boolean(selection.entity) && selection.op === 'enableEntity' &&
        <EnableRegisterType
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default RegisterTypes;