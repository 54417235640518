import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import * as Icons from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Navigation = props => {
  /**
   * Constants
   */

  /**
   * States
   */

  /**
   * Hooks
   */
  const navigate = useNavigate();

  /**
   * Effects
   */

  /**
   * Getters
   */
  const DynamicIcon = ({icon, ...props}) => icon === null ? null : Icons[icon] ? React.createElement(Icons[icon], props) : <HelpOutlineIcon {...props} />;

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  return (
    <Box sx={{mb: 1, ...props.sx}}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
      >
        {props.paths?.map((path, i) => (
          Boolean(path.path)
            ? typeof path.path === 'number'
              ? <Box key={i} sx={{display: 'flex', alignItems: 'center', color: 'LinkText', cursor: 'pointer'}} onClick={() => navigate(path.path)}>
                  <DynamicIcon icon={path.icon} fontSize='inherit' sx={{mr: 0.5}}></DynamicIcon>
                  <Typography variant='subtitle2' color='inherit'>{path.text}</Typography>
                </Box>
              : <Link key={i} to={path.path} className='link' style={{display: 'flex', alignItems: 'center'}}>
                  <DynamicIcon icon={path.icon} fontSize='inherit' sx={{mr: 0.5}}></DynamicIcon>
                  <Typography variant='subtitle2' color='inherit'>{path.text}</Typography>
                </Link>
            : <Box key={i} sx={{display: 'flex', alignItems: 'center'}}>
                <DynamicIcon icon={path.icon} fontSize='inherit' sx={{mr: 0.5}}></DynamicIcon>
                <Typography variant='subtitle2' color='text.primary'>{path.text}</Typography>
              </Box>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default Navigation;