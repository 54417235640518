import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/**
 * Static core components
 */

const DuplicateService = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    if (data !== null) return;

    setDefaultData({
      idService: props.value.id,
      idBeneficiary: props.value.idSpecialty,
      idSpecialty: props.value.idSpecialty,
      service: props.value.service,
    });
    setData({
      idService: props.value.id,
      idBeneficiary: props.value.idBeneficiary,
      idSpecialty: props.value.idSpecialty,
      service: props.value.service,
    });
    setIsLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.service === '' ? 'Nu ati completat numele serviciului' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {}

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('duplicateService', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response, {
            entity: response.result.data.services.find(item => item.id === response.result.lastId),
            op: 'editEntity',
          });
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='sm' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Duplicare serviciu</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>Completati mai jos numele noului serviciu. Se va crea un serviciu nou care va fi identic cu serviciul initial. Dupa definirea acestuia, modificati-l dupa nevoi.</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Serviciu'
                placeholder='Serviciu'
                name='service'
                value={data.service}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateService;