import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import MoreIcon from '@mui/icons-material/More';

const Description = props => {
  /**
   * Constants
   */

  /**
   * States
   */

  /**
   * Hooks
   */

  /**
   * Effects
   */

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  return (
    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
      <Typography variant='caption' color='primary' sx={{...props.sx, lineHeight: '100%', whiteSpace: 'pre'}}>{props.text?.split('\n')[0] || ' '}</Typography>
      {props.text?.split('\n').length > 1 &&
        <Tooltip title={<Typography variant='caption' sx={{whiteSpace: 'pre-wrap'}}>{props.text}</Typography>}>
          <MoreIcon fontSize='inherit' color='secondary' />
        </Tooltip>
      }
    </Box>
  );
};

export default Description;