import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import CountryCountyCity from './CountryCountyCity';

const Branch = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getBranch', {
      idBeneficiary: props.value.idBeneficiary,
      idBranch: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.branch.id)) {
            setDefaultData(response.result.data.branch);
            setData(response.result.data.branch);
          } else {
            setDefaultData(response.result.defaultValues.branch);
            setData({
              ...response.result.data.branch,
              idBeneficiary: props.value.idBeneficiary,
            });
          }

          setDeps(response.result.dependencies);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.branch === '' ? 'Nu ati completat numele punctului de lucru' : message;
    message = message !== null ? message : data.idCountry === '' ? 'Nu ati selectat tara' : message;
    message = message !== null ? message : data.idCounty === '' ? 'Nu ati selectat judetul' : message;
    message = message !== null ? message : data.idCity === '' ? 'Nu ati selectat localitatea' : message;
    message = message !== null ? message : data.address === '' ? 'Nu ati completat adresa' : message;
    message = message !== null ? message : data.email === '' ? 'Nu ati completat adresa de email' : message;
    message = message !== null ? message : data.phone === '' ? 'Nu ati completat numarul de telefon' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveBranch', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='sm' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Punct de lucru: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label='Societate'
                placeholder='Societate'
                value={deps.beneficiary.beneficiary}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Punct de lucru'
                placeholder='Punct de lucru'
                name='branch'
                value={data.branch}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <CountryCountyCity
                layout      ={{  country: {sx: 12, sm: 4},   county: {sx: 12, sm: 4},   city: {sx: 12, sm: 4}}}
                allowAdd    ={{  country: true           ,   county: true           ,   city: true           }}
                disabled    ={{  country: ac.isLoading   ,   county: ac.isLoading   ,   city: ac.isLoading   }}
                labels      ={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
                placeholders={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
                names       ={{  country: 'country'      ,   county: 'county'       ,   city: 'city'         }}
                value       ={{idCountry: data.idCountry , idCounty: data.idCounty  , idCity: data.idCity    }}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required
                disabled={ac.isLoading}
                label='Adresa'
                placeholder='Adresa (str. nr., bl., sc., et., ap.)'
                name='address'
                value={data.address}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField required
                disabled={ac.isLoading}
                label='Email'
                placeholder='Email'
                name='email'
                value={data.email}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField required
                disabled={ac.isLoading}
                label='Telefon'
                placeholder='Telefon'
                name='phone'
                value={data.phone}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Branch;