import React, { useState, useContext, useEffect } from 'react';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Form from './Form';

const defaultFilter = {
  text: '',
};
const defaultDeps = {};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Forms = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getForms')
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter());
          setData(response.result.data.forms);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = () => defaultFilter;

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.forms);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.forms.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'       , path: '/'     , icon: 'Home'       },
          {text: 'Administrare', path: '/Admin', icon: 'Settings'   },
          {text: 'Formulare'   , path: null    , icon: 'Fingerprint'},
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['form']}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.form}>{entity.form[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.form}</Highlighted>
          ),
          textSecondary: null,
          textIcons: [],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica', icon: null, action: 'editEntity', when: true}),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.form,
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Form
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Forms;