import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Service from './Service';
import DisableService from './DisableService';
import EnableService from './EnableService';
import DuplicateService from './DuplicateService';

const defaultFilter = {
  idBeneficiary: '',
  idSpecialty: '',
  text: '',
  isService: true,
  isServicePack: true,
  isEnabled: true,
  isDisabled: true,
};
const defaultDeps = {
  specialties: [],
  registers: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Services = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getServices', {
      idBeneficiary: params.idBeneficiary,
      idSpecialty: params.idSpecialty,
    })
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter());
          setData(response.result.data.services);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = () => ({
    ...defaultFilter,
    idBeneficiary: params.idBeneficiary,
    idSpecialty: params.idSpecialty,
  });
  const getBeneficiaries = () => deps.beneficiaries.sort(firstBy('beneficiary'));
  const getSpecialties = () => deps.specialties.sort(firstBy('specialty'));
  const getBeneficiary = (idBeneficiary = null) => deps.beneficiaries.find(beneficiary => beneficiary.id === (idBeneficiary ?? filter.idBeneficiary));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.services);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.services.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextField select
          disabled={ac.isLoading}
          label='Beneficiar'
          placeholder='Beneficiar'
          name='idBeneficiary'
          value={filter.idBeneficiary}
        >
          {getBeneficiaries().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/Services/${item.id}/${filter.idSpecialty}`} replace className='link' value={item.id}>{item.beneficiary}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField select
          disabled={ac.isLoading}
          label='Specialitate'
          placeholder='Specialitate'
          name='idSpecialty'
          value={filter.idSpecialty}
        >
          {getSpecialties().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/Services/${filter.idBeneficiary}/${item.id}`} replace className='link' value={item.id}>{item.specialty}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControlLabel
          label='Servicii'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isService'
              checked={filter.isService}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControlLabel
          label='Pachete de servicii'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isServicePack'
              checked={filter.isServicePack}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControlLabel
          label='Servicii active'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isEnabled'
              checked={filter.isEnabled}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControlLabel
          label='Servicii inactive'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isDisabled'
              checked={filter.isDisabled}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'       , path: '/'                 , icon: 'Home'        },
          {text: 'Administrare', path: '/Admin'            , icon: 'Settings'    },
          {text: 'Specialitati', path: '/Admin/Specialties', icon: 'MonitorHeart'},
          {text: 'Servicii'    , path: null                , icon: null          },
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection} multiselect
        maxWidth='md'
        sortBy={['service']}
        groupBy={deps.specialties.find(specialty => specialty.id === params.idSpecialty).useServiceCategories ? 'serviceCategory' : null}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.service}>{entity.id}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' color={entity.isEnabled ? 'textPrimary' : 'rgba(0, 0, 0, 0.26)'} sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.service}</Highlighted>
          ),
          textSecondary: null,
          textIcons: [
            entity => ({text: 'Acest serviciu este activ'                                                                        , icon: 'CheckCircle'       , color: 'primary' , when: entity.isEnabled          }),
            entity => ({text: 'Acest serviciu nu este activ'                                                                     , icon: 'Cancel'            , color: 'error'   , when: !entity.isEnabled         }),
            entity => ({text: 'Pentru acest serviciu se tiparesc etichete cu coduri de bare'                                     , icon: 'QrCode2'           , color: 'success' , when: entity.labelsCount !== '0'}),
            entity => ({text: 'Pentru acest serviciu nu se tiparesc etichete cu coduri de bare'                                  , icon: 'QrCode2'           , color: 'disabled', when: entity.labelsCount === '0'}),
            entity => ({text: 'Acest serviciu poate fi vandut/cumparat individual'                                               , icon: 'AttachMoney'       , color: 'success' , when: entity.isPurchasable      }),
            entity => ({text: 'Acest serviciu nu poate fi vandut/cumparat individual'                                            , icon: 'AttachMoney'       , color: 'disabled', when: !entity.isPurchasable     }),
            entity => ({text: 'Acest serviciu poate fi adaugat in lista de asteptare'                                            , icon: 'Notifications'     , color: 'success' , when: entity.isBookable         }),
            entity => ({text: 'Acest serviciu nu poate fi adaugat in lista de asteptare'                                         , icon: 'NotificationsOff'  , color: 'disabled', when: !entity.isBookable        }),
            entity => ({text: 'Acest serviciu poate fi programat'                                                                , icon: 'Schedule'          , color: 'success' , when: entity.isSchedulable      }),
            entity => ({text: 'Acest serviciu nu poate fi programat'                                                             , icon: 'Schedule'          , color: 'disabled', when: !entity.isSchedulable     }),
            entity => ({text: 'Acest serviciu este exclusiv (se deschide o receptie separata pentru el)'                         , icon: 'Filter1'           , color: 'success' , when: entity.isExclusive        }),
            entity => ({text: 'Acest serviciu nu este exclusiv (se poate combina cu alte servicii pe aceeasi receptie)'          , icon: 'Filter1'           , color: 'disabled', when: !entity.isExclusive       }),
            entity => ({text: `Acest serviciu permite preturi diferite in functie de setul de date "${entity.datasetMultiPrice}"`, icon: 'CurrencyExchange'  , color: 'success' , when: entity.isMultiPrice       }),
            entity => ({text: 'Acest serviciu nu permite preturi diferite in functie de un set de date'                          , icon: 'CurrencyExchange'  , color: 'disabled', when: !entity.isMultiPrice      }),
            entity => ({text: 'Pretul acestui serviciu se calculeaza automat in functie de componenta pachetului'                , icon: 'Calculate'         , color: 'success' , when: entity.isAutoPrice        }),
            entity => ({text: 'Pretul acestui serviciu se configureaza individual de catre utilizator'                           , icon: 'Calculate'         , color: 'disabled', when: !entity.isAutoPrice       }),
            entity => ({text: 'Acest serviciu necesita un medic'                                                                 , icon: 'MedicalInformation', color: 'success' , when: entity.isDoctorRequired   }),
            entity => ({text: 'Acest serviciu nu necesita un medic'                                                              , icon: 'MedicalInformation', color: 'disabled', when: !entity.isDoctorRequired  }),
            entity => ({text: `Acest serviciu este un pachet de servicii:\n${entity.childServicesText}`                          , icon: 'ListAlt'           , color: 'success' , when: entity.isServicePack      }),
            entity => ({text: 'Acest serviciu nu este un pachet de servicii'                                                     , icon: 'ListAlt'           , color: 'disabled', when: !entity.isServicePack     }),
            entity => ({text: `Acest serviciu are atribute asociate:\n${entity.serviceActionsText}`                              , icon: 'DynamicForm'       , color: 'success' , when: entity.serviceActionsText }),
            entity => ({text: 'Acest serviciu nu are atribute asociate'                                                          , icon: 'DynamicForm'       , color: 'disabled', when: !entity.serviceActionsText}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'     , icon: null, action: 'editEntity'                                         , when: true                }),
          entity => ({text: 'Dezactiveaza' , icon: null, action: 'disableEntity'                                      , when: entity.isEnabled    }),
          entity => ({text: 'Activeaza'    , icon: null, action: 'enableEntity'                                       , when: !entity.isEnabled   }),
          entity => ({text: 'NIY - Preturi', icon: null, action: `/Admin/ServicePrices/Service/${selection.entity.id}`, when: entity.isPurchasable}),
          entity => ({text: 'Duplicare'    , icon: null, action: 'duplicateEntity'                                    , when: true                }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: {
            ...defs.service,
            idBeneficiary: params.idBeneficiary,
            beneficiary: getBeneficiary(params.idBeneficiary).beneficiary,
            idSpecialty: params.idSpecialty,
          },
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Service
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as disabled
       */}
      {Boolean(selection.entity) && selection.op === 'disableEntity' &&
        <DisableService
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set entity as enabled
       */}
      {Boolean(selection.entity) && selection.op === 'enableEntity' &&
        <EnableService
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Duplicate entity
       */}
      {Boolean(selection.entity) && selection.op === 'duplicateEntity' &&
        <DuplicateService
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Services;