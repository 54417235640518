import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
// import Service from './Service';

const defaultFilter = {
  idBeneficiary: '',
  idBranch: '',
  idBeneficiaryContract: '',
  idDoctorContract: '',
  idSpecialty: '',
  idService: '',
  text: '',
  isService: true,
  isServicePack: true,
};
const defaultDeps = {
  beneficiaries: [],
  branches: [],
  beneficiaryContracts: [],
  doctorContracts: [],
  specialties: [],
  services: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const ServicePrices = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getServicePrices', {
      mode: props.mode,
      idBeneficiary: params.idBeneficiary ? params.idBeneficiary : null,
      idBranch: params.idBranch ? params.idBranch : null,
      idBeneficiaryContract: params.idBeneficiaryContract ? params.idBeneficiaryContract : null,
      idDoctorContract: params.idDoctorContract ? params.idDoctorContract : null,
      idSpecialty: params.idSpecialty ? params.idSpecialty : null,
      idService: params.idService ? params.idService : null,
    })
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter());
          setData(response.result.data.servicePrices);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = () => ({
    ...defaultFilter,
    idBeneficiary: params.idBeneficiary ? params.idBeneficiary : defaultFilter.idBeneficiary,
    idBranch: params.idBranch ? params.idBranch : defaultFilter.idBranch,
    idBeneficiaryContract: params.idBeneficiaryContract ? params.idBeneficiaryContract : defaultFilter.idBeneficiaryContract,
    idDoctorContract: params.idDoctorContract ? params.idDoctorContract : defaultFilter.idDoctorContract,
    idSpecialty: params.idSpecialty ? params.idSpecialty : defaultFilter.idSpecialty,
    idService: params.idService ? params.idService : defaultFilter.idService,
  });
  const getNavigationPaths = () => {
    let paths = [
      {text: 'Acasa'           , path: '/'     , icon: 'Home'    },
      {text: 'Administrare'    , path: '/Admin', icon: 'Settings'},
    ];
    if (!!~['MyCompanyBranch', 'BeneficiaryContract'].indexOf(props.mode)) {
      paths = paths.concat({text: 'Beneficiari'    , path: '/Admin/Beneficiaries', icon: null});
    }
    if (!!~['DoctorContract'].indexOf(props.mode)) {
      paths = paths.concat({text: 'Medici'         , path: '/Admin/Doctors'      , icon: null});
    }
    if (!!~['Service'].indexOf(props.mode)) {
      paths = paths.concat({text: 'Specialitati'   , path: '/Admin/Specialties'  , icon: null});
    }
    if (props.mode) {
      paths = paths.concat(({
        MyCompany:           {text: 'Beneficiari'          , path: '/Admin/Beneficiaries'                   , icon: null},
        MyCompanyBranch:     {text: 'Puncte de lucru'      , path: `/Admin/Branches/${params.idBeneficiary}`, icon: null},
        Beneficiary:         {text: 'Beneficiari'          , path: '/Admin/Beneficiaries'                   , icon: null},
        BeneficiaryContract: {text: 'Contracte beneficiari', path: -1                                       , icon: null},
        Doctor:              {text: 'Medici'               , path: '/Admin/Doctors'                         , icon: null},
        DoctorContract:      {text: 'Contracte medici'     , path: -1                                       , icon: null},
        Specialty:           {text: 'Specialitati'         , path: '/Admin/Specialties'                     , icon: null},
        Service:             {text: 'Servicii'             , path: -1                                       , icon: null},
      })[props.mode]);
    }
    paths = paths.concat({text: 'Preturi servicii', path: null    , icon: 'Money'   });

    return paths;
  };
  const getBeneficiaries = () => deps.beneficiaries.sort(firstBy('beneficiary'));
  const getBranches = () => deps.branches.filter(item => item.idBeneficiary === filter.idBeneficiary).sort(firstBy('branch'));
  const getBeneficiaryContracts = () => deps.beneficiaryContracts.filter(item => item.idBeneficiary === filter.idBeneficiary).sort(firstBy('contract'));
  const getDoctorContracts = () => deps.doctorContracts.filter(item => item.idBranch === filter.idBranch).sort(firstBy('contract'));
  const getSpecialties = () => deps.specialties.sort(firstBy('specialty'));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleDataChange = entityId => evt => {
    let mods = {};
    switch (evt.target.name) {
      // case 'price':
      //   mods = {
      //     ...mods,
      //     isSaved: false,
      //   };
      //   break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      case 'timePicker':
        value = evt.target.value.$d.format('hh:mm');
        break;
      default:
        value = evt.target.value;
    }

    const index = data.findIndex(item => item.id === entityId);
    setData(Object.assign([], data, {[index]: {
      ...data[index],
      [evt.target.name]: value,
      ...mods,
    }}));
  };
  const handleEntitySave = entity => () => {
    console.log(entity);
    ac.ajax('saveServicePrice', {
      ...entity,
      mode: props.mode,
    }).then(response => {
      if (response.status.ok) {
        setData(response.result.data.servicePrices);
        setDeps(response.result.dependencies);
        setDefs(response.result.defaultValues);
      }
    });
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      {Boolean(~['MyCompany', 'MyCompanyBranch', 'Beneficiary', 'BeneficiaryContract'].indexOf(props.mode)) &&
        <Grid item xs={12} sm={4}>
          <TextField select
            disabled={ac.isLoading}
            label='Beneficiar'
            placeholder='Beneficiar'
            name='idBeneficiary'
            value={filter.idBeneficiary}
          >
            {getBeneficiaries().map(item => (
              <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/${item.isMyCompany ? 'MyCompany' : 'Beneficiary'}/${item.id}`} className='link' value={item.id}>{item.beneficiary}</MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      {(props.mode === 'MyCompany' || props.mode === 'MyCompanyBranch' || props.mode === 'DoctorContract') &&
        <Grid item xs={12} sm={4}>
          <TextField select
            disabled={ac.isLoading}
            label='Punct de lucru'
            placeholder='Punct de lucru'
            name='idBranch'
            value={filter.idBranch}
            onChange={handleFilterChange()}
          >
            {props.mode === 'MyCompany' &&
              <MenuItem value={defaultFilter.idBranch}><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Toate</Typography></MenuItem>
            }
            {getBranches().map(item => (
              <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/MyCompanyBranch/${params.idBeneficiary}/${item.id}`} replace className='link' value={item.id}>{item.branch}</MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      {Boolean(~['Beneficiary', 'BeneficiaryContract'].indexOf(props.mode)) &&
        <Grid item xs={12} sm={4}>
          <TextField select
            disabled={ac.isLoading}
            label='Contract beneficiar'
            placeholder='Contract beneficiar'
            name='idBeneficiaryContract'
            value={filter.idBeneficiaryContract}
            onChange={handleFilterChange()}
          >
            {getBeneficiaryContracts().map(item => (
              <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/BeneficiaryContract/${item.id}`} replace className='link' value={item.id}>{item.contract}</MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      {props.mode === 'DoctorContract' &&
        <Grid item xs={12} sm={4}>
          <TextField select
            disabled={ac.isLoading}
            label='Contract medic'
            placeholder='Contract medic'
            name='idDoctorContract'
            value={filter.idDoctorContract}
            onChange={handleFilterChange()}
          >
            {getDoctorContracts().map(item => (
              <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/DoctorContract/${item.id}`} replace className='link' value={item.id}>{item.contract}</MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      {props.mode === 'Specialty' &&
        <Grid item xs={12} sm={6}>
          <TextField select
            disabled={ac.isLoading}
            label='Specialitate'
            placeholder='Specialitate'
            name='idSpecialty'
            value={filter.idSpecialty}
            onChange={handleFilterChange()}
          >
            {getSpecialties().map(item => (
              <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/Specialty/${item.id}`} replace className='link' value={item.id}>{item.specialty}</MenuItem>
            ))}
          </TextField>
        </Grid>
      }
      <Grid item xs={12} sm={!!~['Specialty'].indexOf(props.mode) ? 6 : 4} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'space-between'}}>
        <FormControlLabel
          label='Servicii'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isService'
              checked={filter.isService}
              onChange={handleFilterChange()}
            />
          }
        />
        <FormControlLabel
          label='Pachete'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isServicePack'
              checked={filter.isServicePack}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      <Navigation paths={getNavigationPaths()} />

      <Typography variant='h6' textAlign='center'>Preturi pentru serviciul XXXXXX</Typography>

      {renderFilter()}

      <Entities maxWidth='md' defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['service']}
        groupBy={
          props.mode === 'Service'
            ? 'beneficiary'
            : props.mode === 'Specialty'
              ? 'service'
              : props.mode === 'DoctorContract'
                ? 'specialty'
                : props.mode === 'Doctor'
                  ? 'doctorContract'
                  : props.mode === 'BeneficiaryContract'
                    ? 'specialty'
                    : props.mode === 'Beneficiary'
                      ? 'beneficiaryContract'
                      : props.mode === 'MyCompanyBranch'
                        ? 'specialty'
                        : props.mode === 'MyCompany'
                          ? 'branch'
                          : null
        }
        renderEntity={{
          avatar: entity => <Avatar alt={entity.specialty}>{entity.specialty[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={9}>
                <Box>
                  <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.service}</Highlighted>
                  <Typography variant='body2' color='primary'>Pret de lista: xxx RON</Typography>
                  <Typography variant='caption' color='error'>{!entity.isPurchasable ? 'Acest serviciu nu se poate vinde' : ''}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Beneficiar: {entity.beneficiary}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Punct de lucru: {entity.branch}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Contract beneficiar: {entity.contract}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Doctor: {entity.doctor}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Contract doctor: {entity.contract}</Typography>
                  <Typography variant='caption' sx={{display: 'block'}}>Specialitate: {entity.specialty}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField variant='outlined' required
                  inputProps={{
                    style: {textAlign: 'right'},
                  }}
                  InputProps={{
                    startAdornment: entity.price !== entity.defaultPrice
                      ? <InputAdornment position='start'>
                          <Tooltip title='Anuleaza modificarile facute asupra acestui pret'>
                            <IconButton size='small' color='error'
                              disabled={ac.isLoading}
                              onClick={() => handleDataChange(entity.id)({target: {type: 'priceReset', name: 'price', value: entity.defaultPrice}})}
                            >
                              <ClearIcon fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      : null,
                    // endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                  }}
                  disabled={ac.isLoading || !entity.isPurchasable}
                  error={!Boolean(entity.price) || entity.price !== entity.defaultPrice}
                  helperText={!Boolean(entity.price) ? 'Pret neconfigurat' : entity.price !== entity.defaultPrice ? 'Pretul nu este salvat' : ''}
                  label='Pret (RON)'
                  placeholder='Pret (RON)'
                  name='price'
                  value={entity.price}
                  onChange={handleDataChange(entity.id)}
                />
              </Grid>
            </Grid>
          ),
          textIcons: [],
        }}
        secondaryAction={entity => entity.price !== entity.defaultPrice && entity.price !== '0'
          ? <Tooltip title='Salveaza modificarile facute asupra acestui pret'>
              <IconButton color='primary'
                disabled={ac.isLoading}
                onClick={handleEntitySave(entity)}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          : parseInt(entity.id) > 0 && entity.price === '0'
            ? <Tooltip title='Sterge pretul acestui serviciu. Un serviciu fara pret nu se poate vinde'>
                <IconButton color='error'
                  disabled={ac.isLoading}
                  onClick={handleEntitySave(entity)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            : null
        }
        newEntity={Boolean(~['BeneficiaryContract', 'DoctorContract'].indexOf(props.mode))
          ? {
              op: 'editEntity',
              entity: {
                ...defs.servicePrice,
                idBeneficiaryContract: filter.idBeneficiaryContract,
                idDoctorContract: filter.idDoctorContract,
              },
            }
          : null
        }
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {/* {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Service
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      } */}
    </Container>
  );
};

export default ServicePrices;