import React, {useState, useEffect} from 'react';
import { useTheme } from '@emotion/react';
import './Utils';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from './Hooks/AppContext';
import { BrowserRouter, Link, Routes, Route, Navigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AccountCircle from '@mui/icons-material/AccountCircle';
import ListIcon from '@mui/icons-material/List';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Login from './Components/Login';
import Logout from './Components/Logout';
import Dashboard from './Components/Dashboard';
import Admin from './Components/Admin';
import AdminLists from './Components/AdminLists';
import Beneficiaries from './Components/Beneficiaries';
import BeneficiaryContracts from './Components/BeneficiaryContracts';
import Branches from './Components/Branches';
import Countries from './Components/Countries';
import Counties from './Components/Counties';
import Cities from './Components/Cities';
import Datasets from './Components/Datasets';
import Doctors from './Components/Doctors';
import DoctorContracts from './Components/DoctorContracts';
import FileTypes from './Components/FileTypes';
import Forms from './Components/Forms';
import GdprAgreementTypes from './Components/GdprAgreementTypes';
import IdentityCardTypes from './Components/IdentityCardTypes';
import Printers from './Components/Printers';
import PrinterDrivers from './Components/PrinterDrivers';
import PrinterTypes from './Components/PrinterTypes';
import RegisterTypes from './Components/RegisterTypes';
import ServiceCategories from './Components/ServiceCategories';
import ServicePrices from './Components/ServicePrices';
import ServicePricesForBeneficiary from './Components/ServicePricesForBeneficiary';
import ServicePricesForBeneficiaryContract from './Components/ServicePricesForBeneficiaryContract';
import ServicePricesForDoctor from './Components/ServicePricesForDoctor';
import ServicePricesForDoctorContract from './Components/ServicePricesForDoctorContract';
import ServicePricesForMyCompany from './Components/ServicePricesForMyCompany';
import ServicePricesForSpecialty from './Components/ServicePricesForSpecialty';
import Services from './Components/Services';
import Specialties from './Components/Specialties';

import NotImplementedYet from './Components/Core/NotImplementedYet';

const milestones = [
  {eta: '2023.10.01', doneAt: '2023.10.02', milestone: 'Autentificarea utilizatorilor interni in aplicatie si managementul sesiunilor'},
  {eta: '2023.10.05', doneAt: '', milestone: 'Verificarea modulelor aplicatiei instalate si accesibile'},
  {eta: '2023.10.05', doneAt: '2023.10.03', milestone: 'Definirea detaliilor propriei societati'},
  {eta: '2023.10.06', doneAt: '2023.10.04', milestone: 'Definirea primului punct de lucru'},
  {eta: '2023.10.08', doneAt: '2023.10.07', milestone: 'Administrarea nomenclatoarelor de tari/judete/localitati (implementare model nomenclatoare)'},
  {eta: '2023.10.09', doneAt: '2023.10.07', milestone: 'Administrarea punctelor de lucru proprii (adaugare/modificare)'},
  {eta: '2023.10.10', doneAt: '2023.10.10', milestone: 'Definirea programului standard pentru punctele de lucru proprii'},
  {eta: '2023.10.10', doneAt: '2023.10.11', milestone: 'Definirea programului preferential pentru punctele de lucru proprii'},
  {eta: '2023.10.10', doneAt: '', milestone: 'Administrarea utilizatorilor aplicatiei (adaugare/modificare)'},
  {eta: '2023.12.31', doneAt: '', milestone: 'Administrarea permisiunilor individuale ale utilizatorilor'},
  {eta: '2023.10.11', doneAt: '2024.01.09', milestone: 'Definirea imprimantelor (case de marcat, imprimante de etichete, etc.)'},
  {eta: '2023.10.12', doneAt: '2023.10.13', milestone: 'Definirea medicilor'},
  {eta: '2023.10.13', doneAt: '2023.10.12', milestone: 'Definirea specialitatilor medicale'},
  {eta: '2023.10.15', doneAt: '2023.10.20', milestone: 'Definirea contractelor medicilor pe punct de lucru si specialitate'},
  {eta: '2023.10.20', doneAt: '2023.01.19', milestone: 'Definirea programului standard pentru medici in baza contractelor definite anterior'},
  {eta: '2023.10.21', doneAt: '2023.01.19', milestone: 'Definirea programului preferential pentru medici in baza programului standard definit anterior'},
  {eta: '2023.10.24', doneAt: '2023.10.11', milestone: 'Definirea registrelor in baza carora se vor face alocarea numerelor pentru facturi, consultatii, avize si alte documente'},
  {eta: '2023.10.26', doneAt: '2023.10.16', milestone: 'Definirea serviciilor'},
  {eta: '2023.10.29', doneAt: '2023.10.19', milestone: 'Definirea pachetelor de servicii'},
  {eta: '2023.11.02', doneAt: '2024.01.05', milestone: 'Definirea preturilor serviciilor "la liber"'},
  {eta: '2023.11.05', doneAt: '2023.10.08', milestone: 'Definirea beneficiarilor'},
  {eta: '2023.11.06', doneAt: '2023.10.08', milestone: 'Definirea punctelor de lucru ale beneficiarilor (folosea la ceva dar nu mai stiu la ce)'},
  {eta: '2023.11.08', doneAt: '2024.01.06', milestone: 'Definirea contractelor beneficiarilor'},
  {eta: '2023.11.12', doneAt: '2024.01.07', milestone: 'Definirea preturilor serviciilor in baza contractelor cu beneficiarii (pretul nu ia in calcul punctul de lucru)'},
  {eta: '2023.11.13', doneAt: '2024.01.05', milestone: 'Definirea preturilor serviciilor in baza contractelor cu medicii (se pot defini preturi diferite pe puncte de lucru diferite)'},
  {eta: '2023.11.14', doneAt: '2024.01.10', milestone: 'Definirea tipurilor de fisiere care pot fi incarcate in aplicatie (acord GDPR, fisa medicala, scrisoare medicala, etc.)'},
  {eta: '2023.11.15', doneAt: '2024.01.10', milestone: 'Definirea tipurilor actelor de identitate ale clientilor'},
  {eta: '2023.11.22', doneAt: '', milestone: 'Definirea primului client fara acord GDPR si programarea acestuia'},
  {eta: '2023.??.??', doneAt: '', milestone: 'Inregistrarea datelor primei consultatii si generarea in PDF a acesteia'},
  {eta: '2023.11.30', doneAt: '', milestone: 'Inchiderea primei programari si incasarea serviciului/serviciilor prestate cu bon fiscal'},
  {eta: '2023.12.04', doneAt: '', milestone: 'Deschiderea primei receptii fara programare, inchiderea si incasarea acesteia cu bon fiscal si eventual factura fiscala'},
  {eta: '2023.12.06', doneAt: '', milestone: 'Deschiderea primei receptii pentru un client in baza unui contract cu un beneficiar si inchiderea acesteia'},
  {eta: '2023.12.12', doneAt: '', milestone: 'Emiterea primei facturi la termen catre un beneficiar (si a desfasuratorului cu serviciilor prestate)'},
  {eta: '2023.12.14', doneAt: '', milestone: 'Inregistrarea primei plati efectuate de un beneficiar in baza unei facturi catre el'},
  {eta: '2023.12.15', doneAt: '', milestone: 'Emiterea primei facturi care sa nu contina servicii (factura decont CAS, factura incasare avans, etc.)'},
  {eta: '2023.12.18', doneAt: '', milestone: 'Emiterea primei facturi catre un beneficiar in care se storneaza un avans facturat anterior'},
  {eta: '2023.12.19', doneAt: '', milestone: 'Anularea facturii anterioare'},
  {eta: '2023.??.??', doneAt: '', milestone: 'Emiterea unei facturi catre un beneficiar in care se storneaza un avans facturat anterior si emiterea unei dispozitii de plata'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Definirea primului task general sau doar pentru un utilizator'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Implementarea notificatiilor in AppBar'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inchiderea sesiunilor inactive in aplicatie (fara activitate in ultimele X ore)'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Deschiderea primei fise auto'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Generarea registrelor de consultatii'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inregistrarea rezultatelor consultatiilor fisei deschise si inchiderea acesteia'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Deschiderea primei fise de SC'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inregistrarea rezultatelor consultatiilor fisei deschise si inchiderea acesteia'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Deschiderea primei fise de medicina muncii'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inregistrarea rezultatelor consultatiilor fisei deschise si inchiderea acesteia'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Generarea rapoartelor... dupa caz'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Programarea de catre o scoala de soferi a primei fise auto'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Transformarea programarii facute de beneficiar in receptie cu serviciile aferente'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inchiderea fisei auto si anuntarea beneficiarului'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Programarea de catre un beneficiar a primei fise SC'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Transformarea programarii facute de beneficiar in receptie cu serviciile aferente'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inchiderea fisei SC si anuntarea beneficiarului'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Programarea de catre un beneficiar a primei fise de medicina muncii'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Transformarea programarii facute de beneficiar in receptie cu serviciile aferente'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inchiderea fisei de medicina muncii si anuntarea beneficiarului'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Prima programare care sa contina servicii ale unei aplicatii terte (folosind serviceProviders)'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Tiparirea primului bon fiscal pentru servicii ale unei aplicatii terte (folosind serviceProviders)'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Emiterea primei facturi pentru servicii ale unei aplicatii terte (folosind serviceProviders)'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Inregistrarea primei plati pentru o factura care contine servicii ale unei aplicatii terte'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Clientii isi pot crea cont in aplicatie folosind numarul de telefon existent si confirmare prin SMS'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Clientii isi pot reseta parola folosind numarul de telefon existent si confirmare prin SMS'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Beneficiarii care au acces la aplicatie isi pot crea proprii utilizatori'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Prima societate definita de catre un beneficiar care ofera servicii externe pentru angajatii/clientii altor societati'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Prima programare facuta de un beneficiar care ofera servicii externe pentru angajatii/clientii altor societati'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Prima factura emisa catre un beneficiar care ofera servicii externe altor societati'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Anuntarea beneficiarilor de expirarea valabilitatii serviciilor efectuate anterior'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Anuntarea primului client de expirarea valabilitatii serviciilor efectuate anterior'},
  {eta: '2024.??.??', doneAt: '', milestone: 'Cam asta ar fi tot. Daca am ajuns aici, avem o aplicatie functionala!'},
];

const silentOps = [
  'getBeneficiariesSilent',
  'getBranch',
  'getCitiesSilent',
  'getCity',
  'getCountiesSilent',
  'getCountriesCountiesCities',
  'getCustomersSilent',
  'getNotifications',
  'getServicePackServices',
  'getServicesSilent',
];

const App = props => {
  /**
   * States
   */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = useState(null); // user.userType: visitor, customer, partner, admin
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  /**
   * Hooks
   */

  /**
   * Effects
   */
  useEffect(() => {
    if (user === null && localStorage.getObject(props.localStorageKey)) {
      ajax('login');
    }

    localStorage.setObject(props.localStorageKey, user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  /**
   * AJAX
   */
  const ajax = async (op, data = {}, files = []) => {
    if (!Boolean(~silentOps.indexOf(op)))
      setIsLoading(true);

    const sid = user?.sid ? user.sid : localStorage.getObject(props.localStorageKey) ? localStorage.getObject(props.localStorageKey).sid : null;

    var formData = new FormData();
    formData.append('op', JSON.stringify(op));
    formData.append('sid', JSON.stringify(sid));

    Object.keys(data).forEach(key => formData.append(key, JSON.stringify(data[key])));
    files.forEach((file, i) => formData.append(Boolean(file.paramName) ? file.paramName : `file_${i}`, Boolean(file.paramName) ? file.file : file));

    return await fetch(`${props.url.api}?op=${op}${sid ? `&sid=${sid}` : ''}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        // console.log('App:', response);

        if (!Boolean(~silentOps.indexOf(op)))
          setIsLoading(false);

        if (!Boolean(response))
          throw new Error(`AJAX failed, response is not an object! The response is of type '${typeof response}' and its value is '${response}'`);

        if (Boolean(response.status.message))
          setMessage(response.status);

        if (response.result.userSession !== null)
          setUser(response.result.userSession);

        response.logs.forEach(log => console.log(`Ajax: ${log.type} - ${log.message}`));

        return response;
      })
      .then(response => {
        if (Boolean(response.result.attachments.length)) {
          function base64ToArrayBuffer(base64) {
            var binaryString =  window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++)        {
              var ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            return bytes;
          }

          var saveByteArray = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return function (data, contentType, name) {
              var blob = new Blob(data, {type: contentType});
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = name;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            };
          }());

          response.result.attachments.forEach(attachment => {
            saveByteArray([base64ToArrayBuffer(attachment.data)], attachment.contentType, attachment.fileName);
          });
        }

        return response;
      })
      .catch(err => {
        if (!Boolean(~silentOps.indexOf(op)))
          setIsLoading(false);
        setMessage({
          ok: false,
          message: 'Comunicarea cu serverul a esuat',
          severity: 'error'
        });
        console.error('Eroare: Comunicarea cu serverul a esuat cu urmatorul mesaj:\n', err);
      });
  };

  /**
   * appContext
   */
  const appContext = {
    url: props.url,
    theme: useTheme(),
    ajax: ajax,
    isLoading: isLoading,
    user: user,
  };

  /**
   * Renderer
   */
  return (
    <BrowserRouter>
      <AppContext.Provider value={appContext}>
        <AppBar position='fixed'>
          <Container maxWidth='xl' fixed>
            <Toolbar variant='dense' disableGutters>
              <Typography variant='h5' sx={{flexGrow: 1}}>Sunmed</Typography>
              {Boolean(user)
                ? <IconButton onClick={evt => setAnchorEl(evt.currentTarget)} sx={{p: 0}}>
                    <Avatar alt={`${user.surname} ${user.name}`} src='https://mui.com/static/images/avatar/2.jpg' />
                  </IconButton>
                : <IconButton size='large' color='inherit'
                    onClick={evt => setAnchorEl(evt.currentTarget)}
                  >
                    <AccountCircle />
                  </IconButton>
              }
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {Boolean(user)
                  ? <Box>
                      {user.isAdmin &&
                        <MenuItem component={Link} to='/Admin' className='link' onClick={() => setAnchorEl(null)}>Administrare</MenuItem>
                      }
                      <MenuItem component={Link} to='/Profile' className='link' onClick={() => setAnchorEl(null)}>Profil</MenuItem>
                      <MenuItem component={Link} to='/MyAccount' className='link' onClick={() => setAnchorEl(null)}>Contul meu</MenuItem>
                      <MenuItem component={Link} to='/Logout' className='link' onClick={() => setAnchorEl(null)}>Deconectare</MenuItem>
                    </Box>
                  : <Box>
                      <MenuItem component={Link} to='/Login' className='link' onClick={() => setAnchorEl(null)}>Autentificare</MenuItem>
                    </Box>
                }
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>

        <Container maxWidth='xl' fixed sx={{pt: 7, pb: 10}}>
          <Routes>
            {Boolean(user)
              ? <Route index         element={<Dashboard />}   />
              : <Route index         element={<Login />} />
            }
            <Route path='/Login'     element={<Login />} />

            <Route path='/Profile'   element={<NotImplementedYet />} />
            <Route path='/MyAccount' element={<NotImplementedYet />} />

            <Route path='/Admin'>
              <Route index element={<Admin />} />
              <Route path='Beneficiaries'>
                <Route index                                                            element={<Beneficiaries />} />
                <Route path='Contracts/:idBeneficiary'                                  element={<NotImplementedYet />} />
              </Route>
              <Route path='BeneficiaryContracts/:idBeneficiary'                         element={<BeneficiaryContracts />} />
              <Route path='Branches/:idBeneficiary'                                     element={<Branches />} />
              <Route path='Countries'                                                   element={<Countries />} />
              <Route path='Counties/:idCountry'                                         element={<Counties />} />
              <Route path='Cities/:idCounty'                                            element={<Cities />} />
              <Route path='Doctors'                                                     element={<Doctors />} />
              <Route path='DoctorContracts/:idDoctor'                                   element={<DoctorContracts />} />
              <Route path='Forms'                                                       element={<Forms />} />
              <Route path='Forms/:idForm'                                               element={<NotImplementedYet />} />
              <Route path='Lists'>
                <Route index                                                            element={<AdminLists />} />
                <Route path='FileTypes'                                                 element={<FileTypes />} />
                <Route path='GdprAgreementTypes'                                        element={<GdprAgreementTypes />} />
                <Route path='IdentityCardTypes'                                         element={<IdentityCardTypes />} />
                <Route path='PrinterDrivers'                                            element={<PrinterDrivers />} />
                <Route path='PrinterTypes'                                              element={<PrinterTypes />} />
                <Route path='Datasets'                                                  element={<Datasets />} />
              </Route>
              <Route path='Printers/:idBranch?'                                         element={<Printers />} />              {/** :idBranch is used just to filter entities */}
              <Route path='RegisterTypes'                                               element={<RegisterTypes />} />
              <Route path='Services'>
                <Route index                                                            element={<Services />} />              {/** At least :idSpecialty must be provided */}
                <Route path=':idBeneficiary/:idSpecialty'                               element={<Services />} />              {/** :idSpecialty is used to retrieve entities from server */}
              </Route>
              <Route path='ServiceCategories/:idSpecialty'                              element={<ServiceCategories />} />
              <Route path='ServicePacks'                                                element={<NotImplementedYet />} />
              <Route path='ServicePrices'>
                <Route index                                                            element={<ServicePrices />} />
                <Route path='MyCompany/:idBeneficiary'                                  element={<ServicePricesForMyCompany           />} /> {/** Preturi servicii "de lista" pentru toate punctele de lucru */}
                <Route path='MyCompany/:idBeneficiary/:idBranch'                        element={<ServicePricesForMyCompany           />} /> {/** Preturi servicii "de lista" pentru un anumit punct de lucru */}
                <Route path='Specialty/:idSpecialty'                                    element={<ServicePricesForSpecialty           />} /> {/** Preturi servicii pentru o specialitate */}
                <Route path='Doctor/:idDoctor'                                          element={<ServicePricesForDoctor              />} /> {/** Se alege un contract pentru a se afisa preturile */}
                <Route path='DoctorContract/:idDoctor/:idDoctorContract'                element={<ServicePricesForDoctorContract      />} /> {/** Preturi servicii pentru un contract medic */}
                <Route path='Beneficiary/:idBeneficiary'                                element={<ServicePricesForBeneficiary         />} /> {/** Se alege un contract pentru a se afisa preturile */}
                <Route path='BeneficiaryContract/:idBeneficiary/:idBeneficiaryContract' element={<ServicePricesForBeneficiaryContract />} /> {/** Preturi servicii pentru un contract beneficiar */}

                <Route path='Service/:idService'                                        element={<ServicePrices mode='Service'             />} /> {/** Preturi pentru un singur serviciu */}
              </Route>
              <Route path='Specialties'                                                 element={<Specialties />} />
              <Route path='Users'                                                       element={<NotImplementedYet />} />
              <Route path='UserSessions'                                                element={<NotImplementedYet />} />
            </Route>

            <Route path='/Logout' element={<Logout />} />

            <Route path='*'       element={<Navigate to='/' replace />} />
          </Routes>

          <Container maxWidth='xl' sx={{mt: 20}}>
            <Paper elevation={5} sx={{mt: 2, p: 1}}>
              <Typography variant='subtitle2'>ToDo:</Typography>
              {milestones.map((milestone, i) => (
                <Box key={i} sx={{display: 'flex', alignItems: 'center', my: .5}}>
                  {Boolean(milestone.doneAt)
                    ? <Chip variant='outlined' size='small' color='success' sx={{mr: 1}} label={milestone.doneAt} />
                    : <Chip variant='outlined' size='small' color='default' sx={{mr: 1, color: 'gray'}} label={milestone.eta} />
                  }
                  {Boolean(milestone.doneAt)
                    ? <CheckCircleOutlineIcon color='success' fontSize='small' sx={{mr: 1}} />
                    : <ListIcon color='disabled' fontSize='small' sx={{mr: 1}} />
                  }
                  <Typography>{milestone.milestone}</Typography>
                </Box>
              ))}
            </Paper>
          </Container>

          {Boolean(message) &&
            <Stack sx={{position: 'fixed', bottom: '32px', left: '50%', transform: 'translateX(-50%)', minWidth: '320px', maxWidth: '100vw', zIndex: (theme) => theme.zIndex.modal + 2}} spacing={2}>
              <Alert variant='filled' severity={message.severity} onClose={() => setMessage(null)}>{message.message}</Alert>
            </Stack>
          }
        </Container>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;