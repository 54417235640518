import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import ClearIcon from '@mui/icons-material/Clear';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Country from './Country';
import DefaultCountry from './DefaultCountry';

const defaultFilter = {
  text: '',
};
const defaultDeps = {};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Countries = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCountries')
      .then(response => {
        if (response.status.ok) {
          setData(response.result.data.countries);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.countries);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.countries.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'       , path: '/'     , icon: 'Home'    },
          {text: 'Administrare', path: '/Admin', icon: 'Settings'},
          {text: 'Tari'        , path: null    , icon: 'Flag'    },
        ]}
      />

      {renderFilter()}

      <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['country']}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.country}>{entity.alphaCode2}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.country}</Highlighted>
          ),
          textSecondary: null,
          textIcons: [
            entity => ({text: 'Aceasta tara este definita ca implicita'                , icon: 'Home'    , color: 'primary', when: entity.isDefault           }),
            entity => ({text: 'Codul de 2 caractere conform ISO 3166-1 este definit'   , icon: 'LooksTwo', color: 'success', when: Boolean(entity.alphaCode2) }),
            entity => ({text: 'Codul de 2 caractere conform ISO 3166-1 nu este definit', icon: 'LooksTwo', color: 'error'  , when: !Boolean(entity.alphaCode2)}),
            entity => ({text: 'Codul de 3 caractere conform ISO 3166-1 este definit'   , icon: 'Looks3'  , color: 'success', when: Boolean(entity.alphaCode3) }),
            entity => ({text: 'Codul de 3 caractere conform ISO 3166-1 nu este definit', icon: 'Looks3'  , color: 'error'  , when: !Boolean(entity.alphaCode3)}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'        , icon: null, action: 'editEntity'                            , when: true                       }),
          entity => ({text: 'Seteaza implicit', icon: null, action: 'setDefaultCountry'                     , when: !selection.entity.isDefault}),
          entity => ({text: 'Judete'          , icon: null, action: `/Admin/Counties/${selection.entity.id}`, when: true                       }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.country,
        }}
        onChangeSelection={setSelection}
      />

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Country
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set default entity
       */}
      {Boolean(selection.entity) && selection.op === 'setDefaultCountry' &&
        <DefaultCountry
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Countries;