import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const dateTimePickerSlotProps = {
  textField: {
    // variant: 'outlined',
    InputProps: {readOnly: true},
    onBeforeInput: evt => evt?.preventDefault() && evt?.stopPropagation(),
  },
};

const DoctorContract = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getDoctorContract', {
      idDoctor: props.value.idDoctor,
      idDoctorContract: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.doctorContract.id)) {
            setDefaultData(response.result.data.doctorContract);
            setData(response.result.data.doctorContract);
          } else {
            setDefaultData(response.result.defaultValues.doctorContract);
            setData({
              ...response.result.data.doctorContract,
              idDoctor: props.value.idDoctor,
              validFrom: (new Date()).format('YYYY-MM-DD'),
            });
          }

          setDeps(response.result.dependencies);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.contract === '' ? 'Nu ati completat numele contractului' : message;
    message = message !== null ? message : data.idBeneficiary === '' ? 'Nu ati selectat beneficiarul' : message;
    message = message !== null ? message : !isInsuranceHouse(data.idBeneficiary) && data.idBranch === '' ? 'Nu ati selectat punctul de lucru' : message;
    message = message !== null ? message : data.idSpecialty === '' ? 'Nu ati selectat specialitatea' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getBeneficiaries = useCallback(() => {
    return deps.beneficiaries.sort(firstBy('isMyCompany', 'desc').thenBy('beneficiary'));
  }, [deps]);
  const getBranches = useCallback(() => {
    return deps.branches.sort(firstBy('branch'));
  }, [deps]);
  const getSpecialties = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);

  const isMyCompany = idBeneficiary => Boolean(idBeneficiary) ? deps.beneficiaries.find(item => item.id === idBeneficiary)?.isMyCompany : null;
  const isInsuranceHouse = idBeneficiary => Boolean(idBeneficiary) ? deps.beneficiaries.find(item => item.id === idBeneficiary)?.isInsuranceHouse : null;

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'idBeneficiary':
        mods.idBranch = '';
        mods.isSchedulable = isMyCompany(evt.target.value) ? false : data.isSchedulable;
        mods.dailySchedulesLimit = isMyCompany(evt.target.value) ? '0' : data.dailySchedulesLimit;
        break;
      case 'isSchedulable':
        mods.dailySchedulesLimit = '0';
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      default:
        value = evt.target.value;
    }

    setData(prevState => ({
      ...prevState,
      [evt.target.name]: value,
      ...mods,
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveDoctorContract', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='sm' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Contract medic: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label='Medic'
                placeholder='Medic'
                value={deps.doctor.doctor}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Contract'
                placeholder='Contract'
                name='contract'
                value={data.contract}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField select
                disabled={ac.isLoading}
                label='Beneficiar'
                placeholder='Beneficiar'
                name='idBeneficiary'
                value={data.idBeneficiary}
                onChange={handleDataChange()}
              >
                {getBeneficiaries().map(item => (
                  <MenuItem key={item.id} value={item.id}><Typography variant='body1' sx={{fontWeight: item.isMyCompany ? 'bold' : 'initial'}}>{item.beneficiary}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading || isInsuranceHouse(data.idBeneficiary)}
                label='Punct de lucru'
                placeholder='Punct de lucru'
                name='idBranch'
                value={data.idBranch}
                onChange={handleDataChange()}
              >
                {getBranches().map(item => (
                  <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.branch}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                label='Specialitate'
                placeholder='Specialitate'
                name='idSpecialty'
                value={data.idSpecialty}
                onChange={handleDataChange()}
              >
                {getSpecialties().map(item => (
                  <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.specialty}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <DatePicker slotProps={dateTimePickerSlotProps} maxDate={dayjs(data.validTo)}
                disabled={ac.isLoading}
                label='De la data'
                value={dayjs(data.validFrom)}
                onChange={newValue => handleDataChange()({target: {type: 'datePicker', name: 'validFrom', value: newValue}})}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker slotProps={dateTimePickerSlotProps} minDate={dayjs(data.validFrom)}
                disabled={ac.isLoading}
                label='Pana la data'
                value={dayjs(data.validTo)}
                onChange={newValue => handleDataChange()({target: {type: 'datePicker', name: 'validTo', value: newValue}})}
              />
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                label='Serviciile de pe acest contract se pot programa (aceasta optiune este valabila doar pentru contractele cu CAS)'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading || !isInsuranceHouse(data.idBeneficiary)}
                    name='isSchedulable'
                    checked={data.isSchedulable}
                    onChange={handleDataChange()}
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField required type='number' inputProps={{min: '0', max: '255', step: '1'}}
                disabled={ac.isLoading || !data.isSchedulable}
                label='Limita zilnica'
                placeholder='Limita zilnica'
                name='dailySchedulesLimit'
                value={data.dailySchedulesLimit}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DoctorContract;