import React, { useState, useContext, useEffect } from 'react';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const variantOptions = [
  {value: 'filled'  , label: 'filled'  },
  {value: 'outlined', label: 'outlined'},
  {value: 'standard', label: 'standard'},
];
const sizeOptions = [
  {value: 'small' , label: 'small' },
  {value: 'medium', label: 'medium'},
];

const FormElementInputText = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(props.value) !== JSON.stringify(defaultData)) {
      setDefaultData(props.value);
      setData(props.value);

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.label === '' ? 'Nu ati completat campul label' : message;
    message = message !== null ? message : data.placeholder === '' ? 'Nu ati completat campul placeholder' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {default: break;}

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: index === null
          ? {...prevState[property], [evt.target.name]: value}
          : Object.assign([], prevState[property], {[index]: {
            ...prevState[property][index],
            [evt.target.name]: value,
          }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    props.onConfirm(data);
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth={'sm'} fullWidth open keepMounted
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Proprietati componenta formular tip {data.type}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField autoFocus
                disabled={ac.isLoading}
                label='Valoare initiala'
                placeholder='Valoare initiala'
                name='value'
                value={data.value}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField required
                disabled={ac.isLoading}
                label='Label'
                placeholder='Label'
                name='label'
                value={data.props.label}
                onChange={handleDataChange('props')}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField required
                disabled={ac.isLoading}
                label='Placeholder'
                placeholder='Placeholder'
                name='placeholder'
                value={data.props.placeholder}
                onChange={handleDataChange('props')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField required
                disabled={ac.isLoading}
                label='Prefix'
                placeholder='Prefix'
                name='startAdornment'
                value={data.startAdornment}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField required
                disabled={ac.isLoading}
                label='Sufix'
                placeholder='Sufix'
                name='endAdornment'
                value={data.endAdornment}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                label='Varianta'
                placeholder='Varianta'
                name='variant'
                value={data.props.variant}
                onChange={handleDataChange('props')}
              >
                {variantOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                label='Dimensiune'
                placeholder='Dimensiune'
                name='size'
                value={data.props.size}
                onChange={handleDataChange('props')}
              >
                {sizeOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label='Acest camp este obligatoriu de completat'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading}
                    name='required'
                    checked={data.props.required}
                    onChange={handleDataChange('props')}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label='Se sugereaza valorile introduse anterior'
                control={
                  <Checkbox size='small'
                    disabled={ac.isLoading}
                    name='isAutocomplete'
                    checked={data.isAutocomplete}
                    onChange={handleDataChange()}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      {/**
       * Notification message
       */}
      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormElementInputText;