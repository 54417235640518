import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { Link as UrlLink } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import HelpIcon from '@mui/icons-material/Help';

const Country = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCountry', {
      idCountry: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.country.id)) {
            setDefaultData(response.result.data.country);
            setData(response.result.data.country);
          } else {
            setDefaultData(response.result.defaultValues.country);
            setData({
              ...response.result.data.country,
              country: props.value.country,
            });
          }

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.country === '' ? 'Nu ati completat numele tarii' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveCountry', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xs' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Tara: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Tara'
                placeholder='Tara'
                name='country'
                value={data.country}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField inputProps={{style: {textTransform: 'uppercase'}}}
                disabled={ac.isLoading}
                label='ISO 3166-1 alpha-2'
                placeholder='ISO 3166-1 alpha-2'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip disableInteractive={false}
                        title={
                          <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                            <Typography variant='caption'>ISO 3166-1 alpha-2 sunt coduri de două litere, utilizate și de domeniile de internet de nivel superior pentru țări.</Typography>
                            <UrlLink href='https://ro.wikipedia.org/wiki/ISO_3166-1' target='_blank' rel='noreferrer' sx={{color: 'inherit'}}>Aici gasiti lista codurilor ISO 3166-1</UrlLink>
                          </Box>
                        }
                      >
                        <HelpIcon sx={{cursor: 'help'}} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                name='alphaCode2'
                value={data.alphaCode2}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField inputProps={{style: {textTransform: 'uppercase'}}}
                disabled={ac.isLoading}
                label='ISO 3166-1 alpha-3'
                placeholder='ISO 3166-1 alpha-3'
                name='alphaCode3'
                value={data.alphaCode3}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Country;