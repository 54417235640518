import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';

const defaultFilter = {
  idBeneficiary: '',
  idBeneficiaryContract: '',
  text: '',
  isService: true,
  isServicePack: true,
};
const defaultDeps = {
  beneficiaries: [],
  beneficiaryContracts: [],
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const ServicePricesForBeneficiary = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getServicePricesForBeneficiary', {
      idBeneficiary: params.idBeneficiary ? params.idBeneficiary : null,
    })
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter());
          setData(response.result.data.servicePrices);
          setDeps(response.result.dependencies);
          // setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = () => ({
    ...defaultFilter,
    idBeneficiary: params.idBeneficiary ? params.idBeneficiary : defaultFilter.idBeneficiary,
  });
  const getBeneficiaries = () => deps.beneficiaries.sort(firstBy('beneficiary'));
  const getBeneficiaryContracts = () => deps.beneficiaryContracts.sort(firstBy('contract'));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleDataChange = entityId => evt => {
    let mods = {};
    switch (evt.target.name) {
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      case 'timePicker':
        value = evt.target.value.$d.format('hh:mm');
        break;
      default:
        value = evt.target.value;
    }

    const index = data.findIndex(item => item.id === entityId);
    setData(Object.assign([], data, {[index]: {
      ...data[index],
      [evt.target.name]: value,
      ...mods,
    }}));
  };
  const handleEntityFieldFocus = entity => evt => {
    evt.target.select();
    handleSelectionChange({entity: entity})();
  };
  const handleEntitySave = entity => () => {
    console.log(entity);
    ac.ajax('saveServicePrice', {
      ...entity,
      mode: 'Beneficiary',
    }).then(response => {
      if (response.status.ok) {
        setData(response.result.data.servicePrices);
        setDeps(response.result.dependencies);
        // setDefs(response.result.defaultValues);
      }
    });
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextField select
          disabled={ac.isLoading}
          label='Beneficiar'
          placeholder='Beneficiar'
          name='idBeneficiary'
          value={filter.idBeneficiary}
          onChange={handleFilterChange()}
        >
          {getBeneficiaries().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/Beneficiary/${item.id}`} replace className='link' value={item.id}>{item.beneficiary}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField select
          disabled={ac.isLoading}
          label='Contract beneficiar'
          placeholder='Contract beneficiar'
          name='idBeneficiaryContract'
          value={filter.idBeneficiaryContract}
          onChange={handleFilterChange()}
        >
          {getBeneficiaryContracts().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/BeneficiaryContract/${item.idBeneficiary}/${item.id}`} replace className='link' value={item.id}>{item.contract}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={4} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={4} sx={{display: 'flex', justifyContent: 'space-between'}}>
        <FormControlLabel
          label='Servicii'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isService'
              checked={filter.isService}
              onChange={handleFilterChange()}
            />
          }
        />
        <FormControlLabel
          label='Pachete'
          control={
            <Checkbox size='small'
              disabled={ac.isLoading}
              name='isServicePack'
              checked={filter.isServicePack}
              onChange={handleFilterChange()}
            />
          }
        />
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      <Navigation paths={[
        {text: 'Acasa'           , path: '/'                   , icon: 'Home'    },
        {text: 'Administrare'    , path: '/Admin'              , icon: 'Settings'},
        {text: 'Beneficiari'     , path: '/Admin/Beneficiaries', icon: 'Store'   },
        {text: 'Preturi servicii', path: null                  , icon: 'Money'   },
      ]} />

      <Typography variant='h6' textAlign='center'>Preturi servicii pentru un beneficiar</Typography>

      {renderFilter()}

      <Entities maxWidth='md' defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['service']}
        groupBy={'beneficiaryContract'}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.service}>{entity.service[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <Box>
                  <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.service}</Highlighted>
                  {/* <Typography variant='body2' color='primary'>Pret de lista: xxx RON</Typography> */}
                  <Typography variant='caption' color='error'>{!entity.isPurchasable ? 'Acest serviciu nu se poate vinde' : ''}</Typography>
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Beneficiar: {entity.beneficiary}</Typography> */}
                  <Typography variant='caption' sx={{display: 'block'}}>Punct de lucru: {entity.branch}</Typography>
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Contract beneficiar: {entity.beneficiaryContract}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Doctor: {entity.doctor}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Contract doctor: {entity.doctorContract}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Specialitate: {entity.specialty}</Typography> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={2}>
                <TextField variant='outlined' required
                  inputProps={{
                    style: {textAlign: 'right'},
                  }}
                  InputProps={{
                    startAdornment: entity.price !== entity.defaultPrice
                      ? <InputAdornment position='start'>
                          <Tooltip title='Anuleaza modificarile facute asupra acestui pret'>
                            <IconButton size='small' color='error'
                              disabled={ac.isLoading}
                              onClick={() => handleDataChange(entity.id)({target: {type: 'priceReset', name: 'price', value: entity.defaultPrice}})}
                            >
                              <ClearIcon fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      : null,
                    // endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                  }}
                  disabled={ac.isLoading || !entity.isPurchasable}
                  error={!Boolean(entity.price) || entity.price !== entity.defaultPrice}
                  // helperText={!Boolean(entity.price) ? 'Neconfigurat' : entity.price !== entity.defaultPrice ? 'Nesalvat' : ''}
                  label='Pret (RON)'
                  placeholder='Pret (RON)'
                  name='price'
                  value={entity.price}
                  onFocus={handleEntityFieldFocus(entity)}
                  onChange={handleDataChange(entity.id)}
                />
              </Grid>
            </Grid>
          ),
          textIcons: [],
        }}
        secondaryAction={entity => entity.price !== entity.defaultPrice && entity.price !== '0'
          ? <Tooltip title='Salveaza modificarile facute asupra acestui pret'>
              <IconButton color='primary'
                disabled={ac.isLoading}
                onClick={handleEntitySave(entity)}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          : parseInt(entity.id) > 0 && entity.price === '0'
            ? <Tooltip title='Sterge pretul acestui serviciu. Un serviciu fara pret nu se poate vinde'>
                <IconButton color='error'
                  disabled={ac.isLoading}
                  onClick={handleEntitySave(entity)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            : null
        }
        onChangeSelection={setSelection}
      />
    </Container>
  );
};

export default ServicePricesForBeneficiary;