import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { firstBy } from 'thenby';
import dayjs from 'dayjs';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import SortIcon from '@mui/icons-material/Sort';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

const defaultSelection = {
  index: null,
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};
const defaultNotification = {
  message: null,
  index: null,
};
const weekdayNames = {
  '1': 'Duminica',
  '2': 'Luni',
  '3': 'Marti',
  '4': 'Miercuri',
  '5': 'Joi',
  '6': 'Vineri',
  '7': 'Sambata',
};
const dateTimePickerSlotProps = {
  textField: {
    variant: 'outlined',
    InputProps: {readOnly: true},
    onBeforeInput: evt => evt?.preventDefault() && evt?.stopPropagation(),
  },
};

const BranchPrograms = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);
  const [notification, setNotification] = useState(defaultNotification);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getBranchPrograms', {
      idBeneficiary: props.value.idBeneficiary,
      idBranch: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          setDefaultData(response.result.data);
          setData(response.result.data);
          // setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;
    let myIndex = null;

    if (!message) {
      data.branchPrograms.forEach((entity, index) => {
        if (message) {
          return false;
        }

        message = message !== null ? message : entity.isFreeDay && (Boolean(entity.timeStart) || Boolean(entity.timeStop)) ? 'Error: Daca vezi asta, suna-ma!' : message;
        message = message !== null ? message : !entity.isFreeDay && (entity.timeStart >= entity.timeStop || !Boolean(entity.timeStart) || !Boolean(entity.timeStop)) ? 'Intervalul orar selectat nu este valid' : message;
        message = message !== null ? message : entity.day && entity.description === '' ? 'Nu ati completat de ce ati schimbat programul' : message;

        if (entity.day) { // daca programul specific este identic cu programul implicit
          const weekday = (new Date(entity.day).getDay() + 1).toString();
          const weekdayProgram = data.branchPrograms.find(item => item.weekday === weekday);
          if (weekdayProgram.isFreeDay === entity.isFreeDay && weekdayProgram.timeStart === entity.timeStart && weekdayProgram.timeStop === entity.timeStop) {
            message = 'Programul specific nu modifica programul implicit';
          } else {
            data.branchPrograms.filter((item, i) => i !== index && data.branchPrograms[index].day === item.day).forEach((item, i) => {
              if (message) {
                return false;
              }

              if (entity.isFreeDay !== item.isFreeDay) {
                message = 'Aveti mai multe inregistrari pentru aceasta zi, din care cel putin una defineste ziua ca fiind libera';
              } else if (entity.isFreeDay && item.isFreeDay) {
                message = 'Aveti mai multe inregistrari care defineste aceasta zi ca fiind libera';
              } else if (!entity.isFreeDay && !item.isFreeDay) {
                const start1 = `${data.branchPrograms[index].day} ${data.branchPrograms[index].timeStart}`;
                const stop1 = `${data.branchPrograms[index].day} ${data.branchPrograms[index].timeStop}`;
                const start2 = `${item.day} ${item.timeStart}`;
                const stop2 = `${item.day} ${item.timeStop}`;

                if (start1 === stop2 || start2 === stop1) {
                  message = 'Aveti intervale orare in aceasta zi care sunt adiacente';
                } else if (start1 < stop2 && start2 < stop1) {
                  message = 'Intervalele orare din aceasta zi se suprapun';
                }
              }
            });
          }
        }

        if (message) {
          myIndex = index;
          return false;
        }
      });
    }

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification({
      message: message ? message : defaultNotification.message,
      index: myIndex ? myIndex : defaultNotification.index,
    });
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleDataChange = index => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'isFreeDay':
        mods = {
          ...mods,
          timeStart: evt.target.checked ? '' : '08:00',
          timeStop: evt.target.checked ? '' : '16:30',
        };
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      case 'timePicker':
        value = evt.target.value.$d.format('hh:mm');
        break;
      default:
        value = evt.target.value;
    }

    setData(prevState => ({
      ...prevState,
      branchPrograms: Object.assign([], prevState.branchPrograms, {[index]: {
        ...prevState.branchPrograms[index],
        [evt.target.name]: value,
        ...mods,
      }}),
    }));
  };

  const handleAddBranchProgram = () => () => {
    setData(prevState => ({
      ...prevState,
      branchPrograms: prevState.branchPrograms.concat({
        ...defs.branchProgram,
        idBranch: props.value.id,
        day: new Date().addDays(1).format('YYYY-MM-DD'),
      }).sort(firstBy(item => Boolean(item.weekday) ? 0 : 1).thenBy(item => item.weekday === '1' ? '8' : item.weekday).thenBy('day').thenBy('timeStart')),
    }));

    setSelection(defaultSelection);
  };
  const handleDeleteBranchProgram = index => () => {
    setData(prevState => ({
      ...prevState,
      branchPrograms: data.branchPrograms.filter((item, i) => i !== index),
    }));
  };
  const handleSortBranchPrograms = () => () => {
    setData(prevState => ({
      ...prevState,
      branchPrograms: prevState.branchPrograms.sort(firstBy(item => Boolean(item.weekday) ? 0 : 1).thenBy(item => item.weekday === '1' ? '8' : item.weekday).thenBy('day').thenBy('timeStart')),
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveBranchPrograms', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xl' fullWidth open keepMounted scroll='paper'
      fullScreen={fullScreen}
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Program punct de lucru</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent sx={{flexGrow: 1}}>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} sx={{alignItems: 'flex-start'}}>
              <List dense sx={{width: '100%'}}>
                <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant='subtitle2' sx={{lineHeight: '48px'}}>Program implicit</Typography>
                </ListSubheader>
                {data.branchPrograms.map((entity, index) =>
                  Boolean(entity.weekday)
                    ? <Box key={index}>
                        <ListItem disableGutters disablePadding divider>
                          <ListItemButton disableGutters disableTouchRipple sx={{cursor: 'default'}}
                            selected={index === selection.index}
                            onClick={handleSelectionChange({index: index})}
                          >
                            <ListItemIcon>
                              {index === notification.index &&
                                <WarningIcon color='error' />
                              }
                            </ListItemIcon>
                            <ListItemText disableTypography sx={{display: 'flex', flexDirection: 'column', my: 0, mr: 6}}
                              primary={
                                <Box sx={{display: 'flex'}}>
                                  <Grid container spacing={1} sx={{alignItems: 'center'}}>
                                    <Grid item xs={6} md={3}>
                                      <Typography sx={{fontWeight: 'bold'}}>{weekdayNames[entity.weekday]}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2} sx={{display: 'flex', justifyContent: 'center'}}>
                                      <FormControlLabel
                                        label='Liber'
                                        control={
                                          <Checkbox size='small'
                                            disabled={ac.isLoading}
                                            name='isFreeDay'
                                            checked={entity.isFreeDay}
                                            onChange={handleDataChange(index)}
                                          />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                      {!entity.isFreeDay &&
                                        <TimePicker slotProps={dateTimePickerSlotProps} ampm={false}
                                          disabled={ac.isLoading || entity.isFreeDay}
                                          label='De la'
                                          value={dayjs(`${new Date().format('YYYY-MM-DD')}T${entity.timeStart}`)}
                                          onChange={newValue => handleDataChange(index)({target: {type: 'timePicker', name: 'timeStart', value: newValue}})}
                                        />
                                      }
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                      {!entity.isFreeDay &&
                                        <TimePicker slotProps={dateTimePickerSlotProps} ampm={false}
                                          disabled={ac.isLoading || entity.isFreeDay}
                                          label='Pana la'
                                          value={dayjs(`${new Date().format('YYYY-MM-DD')}T${entity.timeStop}`)}
                                          onChange={newValue => handleDataChange(index)({target: {type: 'timePicker', name: 'timeStop', value: newValue}})}
                                        />
                                      }
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <TextField variant='outlined'
                                        disabled={ac.isLoading}
                                        label='Descriere'
                                        placeholder='Descriere'
                                        name='description'
                                        value={entity.description}
                                        onChange={handleDataChange(index)}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              }
                            />
                            <ListItemSecondaryAction />
                          </ListItemButton>
                        </ListItem>
                      </Box>
                    : null
                )}
              </List>
            </Grid>
            <Grid item xs={12} lg={6} sx={{alignItems: 'flex-start'}}>
              <List dense sx={{flexGrow: 1}}>
                <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography variant='subtitle2' sx={{lineHeight: '48px'}}>Program specific</Typography>
                  <Box sx={{display: 'flex', gap: 1}}>
                    <Button variant='outlined' color='primary' startIcon={<SortIcon />}
                      disabled={data.branchPrograms.filter(item => item.day).length < 2}
                      onClick={handleSortBranchPrograms()}
                    >
                      Sorteaza
                    </Button>
                    <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                      onClick={handleAddBranchProgram()}
                    >
                      Adauga
                    </Button>
                  </Box>
                </ListSubheader>
                {data.branchPrograms.map((entity, index) =>
                  Boolean(entity.day)
                    ? <Box key={index}>
                        <ListItem disableGutters disablePadding divider>
                          <ListItemButton disableGutters disableTouchRipple sx={{cursor: 'default'}}
                            selected={index === selection.index}
                            onClick={handleSelectionChange({index: index})}
                          >
                            <ListItemIcon>
                              {index === notification.index &&
                                <WarningIcon color='error' />
                              }
                            </ListItemIcon>
                            <ListItemText disableTypography sx={{display: 'flex', flexDirection: 'column', my: 0, mr: 6}}
                              primary={
                                <Box sx={{display: 'flex'}}>
                                  <Grid container spacing={1} sx={{alignItems: 'center'}}>
                                    <Grid item xs={6} md={3}>
                                      <DatePicker slotProps={dateTimePickerSlotProps} disablePast
                                        disabled={ac.isLoading}
                                        label='Data'
                                        value={dayjs(entity.day)}
                                        onChange={newValue => handleDataChange(index)({target: {type: 'datePicker', name: 'day', value: newValue}})}
                                      />
                                    </Grid>
                                    <Grid item xs={6} md={2} sx={{display: 'flex', justifyContent: 'center'}}>
                                      <FormControlLabel
                                        label='Liber'
                                        control={
                                          <Checkbox size='small'
                                            disabled={ac.isLoading}
                                            name='isFreeDay'
                                            checked={entity.isFreeDay}
                                            onChange={handleDataChange(index)}
                                          />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                      {!entity.isFreeDay &&
                                        <TimePicker slotProps={dateTimePickerSlotProps} ampm={false}
                                          disabled={ac.isLoading || entity.isFreeDay}
                                          label='De la'
                                          value={dayjs(`${entity.day}T${entity.timeStart}`)}
                                          onChange={newValue => handleDataChange(index)({target: {type: 'timePicker', name: 'timeStart', value: newValue}})}
                                        />
                                      }
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                      {!entity.isFreeDay &&
                                        <TimePicker slotProps={dateTimePickerSlotProps} ampm={false}
                                          disabled={ac.isLoading || entity.isFreeDay}
                                          label='Pana la'
                                          value={dayjs(`${entity.day}T${entity.timeStop}`)}
                                          onChange={newValue => handleDataChange(index)({target: {type: 'timePicker', name: 'timeStop', value: newValue}})}
                                        />
                                      }
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                      <TextField variant='outlined'
                                        disabled={ac.isLoading}
                                        error={!Boolean(entity.description)}
                                        label='Descriere'
                                        placeholder='Descriere'
                                        name='description'
                                        value={entity.description}
                                        onChange={handleDataChange(index)}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              }
                            />
                            <ListItemSecondaryAction>
                              {Boolean(entity.day) &&
                                <IconButton color='error'
                                  onClick={handleDeleteBranchProgram(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                            </ListItemSecondaryAction>
                          </ListItemButton>
                        </ListItem>
                      </Box>
                    : null
                )}
              </List>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent sx={{flexGrow: 0, minHeight: '64px', paddingTop: '20px'}}>
        <DialogContentText color='error' sx={{visibility: Boolean(notification.message) ? 'initial' : 'hidden'}}>
          {Boolean(notification.message) ? notification.message : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification.message)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BranchPrograms;