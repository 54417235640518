import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

/**
 * Static core components
 */
import AdminTile from './Core/AdminTile';
import Navigation from './Core/Navigation';

const AdminLists = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Handlers
   */

  /**
   * Effects
   */
  useEffect(() => {
    // ac.ajax('getAdmin')
    //   .then(response => {
    //     console.log(response);
    //     // setDeps(response.result.dependencies);
    //   });
    setIsLoaded(true);
  }, []);

  /**
   * Getters
   */

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  if (isLoaded === false) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      {/**
       * Navigation
       */}
      {<Navigation
        paths={[
          {text: 'Acasa'        , path: '/'           , icon: 'Home'    },
          {text: 'Administrare' , path: '/Admin'      , icon: 'Settings'},
          {text: 'Nomenclatoare', path: '/Admin/Lists', icon: 'Toc'     },
        ]}
      />}

      <Typography variant='subtitle2'>{JSON.stringify(ac.user)}</Typography>

      <Grid container spacing={1} mt={1}>
        <AdminTile path='/Admin/Countries'                icon='Flag'             title='Adrese'                    description='Tari, judete si localitati' />
        <AdminTile path='/Admin/Lists/PrinterTypes'       icon='Receipt'          title='Tipuri imprimante'         description='Tipuri de imprimante folosite' />
        <AdminTile path='/Admin/Lists/PrinterDrivers'     icon='Terminal'         title='Drivere imprimante'        description='Drivere de imprimante care se pot folosi' />
        <AdminTile path='/Admin/Lists/GdprAgreementTypes' icon='Fingerprint'      title='Tipuri acorduri GDPR'      description='Tipuri de acorduri GDPR ale clientilor' />
        <AdminTile path='/Admin/Lists/IdentityCardTypes'  icon='ContactEmergency' title='Tipuri acte de identitate' description='Tipuri de acte de identitate ale clientilor' />
        <AdminTile path='/Admin/Lists/FileTypes'          icon='AttachFile'       title='Tipuri fisiere'            description='Tipuri de fisiere care se pot incarca in aplicatie' />
        <AdminTile path='/Admin/Lists/Datasets'           icon='Dataset'          title='Seturi de date'            description='Seturi de date utilizate in cadrul serviciilor' />
      </Grid>
    </Container>
  );
};

export default AdminLists;