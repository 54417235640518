import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const dateTimePickerSlotProps = {
  textField: {
    // variant: 'outlined',
    InputProps: {readOnly: true},
    onBeforeInput: evt => evt?.preventDefault() && evt?.stopPropagation(),
  },
};

const CustomerIdentityCard = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getCustomerIdentityCard', {
      idCustomerIdentityCard: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.customerIdentityCard.id)) {
            setDefaultData(response.result.data.customerIdentityCard);
            setData(response.result.data.customerIdentityCard);
          } else {
            setDefaultData(response.result.defaultValues.customerIdentityCard);
            setData({
              ...response.result.data.customerIdentityCard,
              idCustomer: props.value.idCustomer,
              idIdentityCardType: response.result.dependencies.identityCardTypes.find(item => item.isDefault)?.id || response.result.defaultValues.customerIdentityCard.idCustomerIdentityCard,
            });
          }

          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.idIdentityCardType === '' ? 'Nu ati selectat tipul actului de identitate' : message;
    message = message !== null ? message : data.serial === '' ? 'Nu ati completat seria actului de identitate' : message;
    message = message !== null ? message : data.number === '' ? 'Nu ati completat numarul actului de identitate' : message;
    message = message !== null ? message : data.validTo === '' ? 'Nu ati completat valabilitatea actului de identitate' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getIdentityCardTypes = useCallback(() => {
    return deps.identityCardTypes.sort(firstBy('identityCardType'));
  }, [deps]);
  const getIdentityCardType = useCallback((idIdentityCardType = null) => {
    return deps.identityCardTypes.find(item => item.id === (idIdentityCardType ?? data.idIdentityCardType));
  }, [data?.idIdentityCardType]);

  /**
   * Handlers
   */
  const handleDataChange = (property = null, index = null) => evt => {
    let mods = {};
    switch (evt.target.name) {
      case 'idIdentityCardType':
        mods = {
          ...mods,
          serial: getIdentityCardType(evt.target.value).hasSerial ? data.serial : defs.customerIdentityCard.serial,
          number: getIdentityCardType(evt.target.value).hasNumber ? data.number : defs.customerIdentityCard.number,
          issuedBy: getIdentityCardType(evt.target.value).hasIssuedBy ? data.issuedBy : defs.customerIdentityCard.issuedBy,
          issuedAt: getIdentityCardType(evt.target.value).hasIssuedAt ? data.issuedAt : defs.customerIdentityCard.issuedAt,
          validTo: getIdentityCardType(evt.target.value).hasValidTo ? data.validTo : defs.customerIdentityCard.validTo,
        };
        break;
      default: break;
    }

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      default:
        value = evt.target.value;
    }

    if (property) {
      setData(prevState => ({
        ...prevState,
        [property]: Object.assign([], prevState[property], {[index]: {
          ...prevState[property][index],
          [evt.target.name]: value,
        }}),
        ...mods,
      }));
    } else {
      setData(prevState => ({
        ...prevState,
        [evt.target.name]: value,
        ...mods,
      }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveCustomerIdentityCard', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xs' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Act de identitate client: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                label='Client'
                placeholder='Client'
                value={props.customer}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField select required autoFocus
                disabled={ac.isLoading}
                label='Tip act de identitate'
                placeholder='Tip act de identitate'
                name='idIdentityCardType'
                value={data.idIdentityCardType}
                onChange={handleDataChange()}
              >
                {getIdentityCardTypes().map(item => (
                  <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.identityCardType} ({item.identityCardTypeShort})</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField required
                disabled={ac.isLoading || !getIdentityCardType().hasSerial}
                label='Serie'
                placeholder='Serie'
                name='serial'
                value={data.serial}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField required
                disabled={ac.isLoading || !getIdentityCardType().hasNumber}
                label='Numar'
                placeholder='Numar'
                name='number'
                value={data.number}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required
                disabled={ac.isLoading || !getIdentityCardType().hasIssuedBy}
                label='Eliberat de'
                placeholder='Eliberat de'
                name='issuedBy'
                value={data.issuedBy}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker slotProps={dateTimePickerSlotProps} maxDate={dayjs(new Date())} views={['year', 'month', 'day']}
                disabled={ac.isLoading || !getIdentityCardType().hasIssuedAt}
                label='Eliberat la'
                value={dayjs(data.issuedAt)}
                onChange={newValue => handleDataChange()({target: {type: 'datePicker', name: 'issuedAt', value: newValue}})}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker slotProps={dateTimePickerSlotProps} /*minDate={dayjs(new Date())}*/ views={['year', 'month', 'day']}
                disabled={ac.isLoading || !getIdentityCardType().hasValidTo}
                label='Valabil pana la'
                value={dayjs(data.validTo)}
                onChange={newValue => handleDataChange()({target: {type: 'datePicker', name: 'validTo', value: newValue}})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerIdentityCard;