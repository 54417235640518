import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import * as Icons from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const AdminTile = props => {
  /**
   * Constants
   */

  /**
   * States
   */

  /**
   * Hooks
   */
  const navigate = useNavigate();

  /**
   * Effects
   */

  /**
   * Getters
   */
  const DynamicIcon = ({icon, ...props}) => icon === null ? <HelpOutlineIcon {...props} /> : Icons[icon] ? React.createElement(Icons[icon], props) : <HelpOutlineIcon {...props} />;

  /**
   * Handlers
   */

  /**
   * Renderer
   */
  return (
    <Grid item xs={12} sm={6} md={4} sx={{flexDirection: 'column', alignItems: 'stretch'}}>
      <Paper elevation={3} sx={{display: 'flex', gap: 1, height: '100%', p: 1, cursor: 'pointer'}} onClick={() => navigate(props.path)}>
        <DynamicIcon icon={props.icon} color='info' fontSize='large' />
        <Box>
          <Typography variant='subtitle2' className='link'>{props.title}</Typography>
          <Typography variant='body2'>{props.description}</Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default AdminTile;