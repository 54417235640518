import React, {useState, useEffect, useCallback} from 'react';

import escapeRegExp from 'lodash/escapeRegExp';

import Typography from '@mui/material/Typography';

const Highlighted = props => {
  const [text, setText] = useState(props.children);
  const [highlight, setHighlight] = useState('');
  const [otherProps, setOtherProps] = useState(null);
  const [regex, setRegex] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getNodeText = useCallback(node => {
    return ['string', 'number'].includes(typeof node)
      ? node
      : node instanceof Array
        ? node.map(getNodeText).join('')
        : getNodeText(node.props.children);
  }, []);

  useEffect(() => {
    const {children: text = '', highlight = '', ...otherProps} = props;

    setText(getNodeText(text));
    setHighlight(highlight);
    setOtherProps(otherProps);
    setRegex(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));
    setIsLoaded(true);
  }, [props, getNodeText]);

  if (!isLoaded) {
    return <Typography {...otherProps}>{text}</Typography>
  } else if (!highlight.trim()) {
    return <Typography {...otherProps}>{text}</Typography>
  } else {
    return (
      <Typography {...otherProps}>
        {text.split(regex).filter(part => part).map((part, i) => (
          regex.test(part)
            ? <mark key={i}>{part}</mark>
            : <span key={i}>{part}</span>
        ))}
      </Typography>
    );
  }
}

export default Highlighted;