import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const Register = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getRegister', {
      idRegister: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.register.id)) {
            setDefaultData(response.result.data.register);
            setData(response.result.data.register);
          } else {
            setDefaultData(response.result.defaultValues.register);
            setData({
              ...response.result.data.register,
              idRegisterType: props.value.idRegisterType,
            });
          }

          setDeps(response.result.dependencies);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.register === '' ? 'Nu ati completat numele registrului' : message;
    message = message !== null ? message : data.nextNumber === '' ? 'Nu ati completat urmatorul numar care se va aloca' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getBranches = useCallback(() => {
    return deps.branches.sort(firstBy('branch'));
  }, [deps]);

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      case 'datePicker':
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value ? evt.target.value.$d.format('YYYY') : '',
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveRegister', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='xs' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Registru: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                label='Tip de registru'
                value={props.registerType}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField select autoFocus
                disabled={ac.isLoading}
                label='Punct de lucru'
                placeholder='Punct de lucru'
                name='idBranch'
                value={data.idBranch}
                onChange={handleDataChange()}
              >
                <MenuItem value='0'><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Toate</Typography></MenuItem>
                {getBranches().map(item => (
                  <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.branch}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField select
                disabled={ac.isLoading}
                label='An'
                placeholder='An'
                name='year'
                value={data.year}
                onChange={handleDataChange()}
              >
                <MenuItem value='0'><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Toti anii</Typography></MenuItem>
                {[dayjs().year().toString(), (dayjs().year() + 1).toString()].map(year => (
                  <MenuItem key={year} value={year}><Typography variant='body1'>{year}</Typography></MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={ac.isLoading}
                label='Serie'
                placeholder='Serie'
                name='serial'
                value={data.serial}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField type='number'
                disabled={ac.isLoading}
                label='Urmatorul numar de alocat'
                placeholder='Urmatorul numar de alocat'
                name='nextNumber'
                value={data.nextNumber}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Register;