import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import usePrevious from '../Hooks/usePrevious';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';

const defaultFilter = {
  idSpecialty: '',
  idBranch: 'all',
  text: '',
  isServicePack: 'all',
  isPriceSet: 'all',
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const ServicePricesForSpecialty = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const params = useParams();
  const prev = usePrevious({params});

  /**
   * Effects
   */
  useEffect(() => {
    if (JSON.stringify(params) === JSON.stringify(prev?.params)) return;

    ac.ajax('getServicePricesForSpecialty', {
      idSpecialty: params.idSpecialty ? params.idSpecialty : null,
    })
      .then(response => {
        if (response.status.ok) {
          setFilter(getDefaultFilter(response.result.dependencies.branches.find(branch => branch.isDefault).id));
          setData(response.result.data.servicePrices);
          setDeps(response.result.dependencies);
          // setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /**
   * Getters
   */
  const getDefaultFilter = (idBranch = null) => ({
    ...defaultFilter,
    idSpecialty: params.idSpecialty ? params.idSpecialty : defaultFilter.idSpecialty,
    idBranch: idBranch ? idBranch : filter.idBranch,
    isPriceSet: false,
    // text: 'Ascunde tot',
  });
  const getSpecialties = () => deps?.specialties.sort(firstBy('specialty')) || [];
  const getBranches = () => deps?.branches.sort(firstBy('branch')) || [];

  /**
   * Handlers
   */
  const handleFilterChange = (property = null) => evt => {
    if (property) {
      setFilter(prevState => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
        },
      }));
    } else {
      setFilter(prevState => ({
        ...prevState,
        [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value,
      }));
    }
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleFilterReset = () => () => {
    setFilter(getDefaultFilter());
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleDataChange = entityId => evt => {
    let mods = {};
    switch (evt.target.name) {}

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      case 'datePicker':
        value = evt.target.value.$d.format('YYYY-MM-DD');
        break;
      case 'timePicker':
        value = evt.target.value.$d.format('hh:mm');
        break;
      default:
        value = evt.target.value;
    }

    const index = data.findIndex(item => item.id === entityId);
    setData(Object.assign([], data, {[index]: {
      ...data[index],
      [evt.target.name]: value,
      ...mods,
    }}));
  };
  const handleEntityFieldFocus = entity => evt => {
    evt.target.focus() && evt.target.select && evt.target.select();
    handleSelectionChange({entity: entity})();
  };
  const handleServicePriceAutoCalculate = entityId => evt => {
    const entity = data.find(item => item.id === entityId);
    const price = deps.servicePrices.filter(servicePrice => true
      && servicePrice.idBeneficiary === entity.idBeneficiary
      && servicePrice.idBranch === entity.idBranch
      && servicePrice.idBeneficiaryContract === entity.idBeneficiaryContract
      && servicePrice.idDoctorContract === entity.idDoctorContract
      && deps.servicePackServices.some(servicePackService => servicePackService.idServicePack === entity.idService && servicePackService.idService === servicePrice.idService)
      && servicePrice.idDatasetValue === entity.idDatasetValue
    ).reduce((acc, servicePrice) => acc + parseFloat(servicePrice.price), 0);
    handleDataChange(entity.id)({target: {type: 'component', name: 'price', value: price.toCurrency('', '', '.', 2)}});
  };
  const handleEntitySave = entity => () => {
    console.log(entity);
    ac.ajax('saveServicePrice', {
      ...entity,
      mode: 'Specialty',
    }).then(response => {
      if (response.status.ok) {
        setData(response.result.data.servicePrices);
        setDeps(response.result.dependencies);
        // setDefs(response.result.defaultValues);
      }
    });
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <TextField select
          disabled={ac.isLoading}
          label='Specialitate'
          placeholder='Specialitate'
          name='idSpecialty'
          value={filter.idSpecialty}
        >
          {getSpecialties().map(item => (
            <MenuItem key={item.id} component={Link} to={`/Admin/ServicePrices/Specialty/${item.id}`} className='link' value={item.id}>{item.specialty}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField select
          disabled={ac.isLoading}
          error={filter.idBranch !== defaultFilter.idBranch}
          label='Punct de lucru'
          name='idBranch'
          value={filter.idBranch}
          onChange={handleFilterChange()}
        >
          <MenuItem value='all'>Toate punctele de lucru</MenuItem>
          {getBranches().map(item => (
            <MenuItem key={item.id} value={item.id}>{item.branch}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField select
          disabled={ac.isLoading}
          error={filter.isServicePack !== defaultFilter.isServicePack}
          label='Serviciu / pachet de servicii'
          name='isServicePack'
          value={filter.isServicePack}
          onChange={handleFilterChange()}
        >
          <MenuItem value='all'>Oricare</MenuItem>
          <MenuItem value={false}>Doar serviciile</MenuItem>
          <MenuItem value={true}>Doar pachetele de servicii</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField select
          disabled={ac.isLoading}
          error={filter.isPriceSet !== defaultFilter.isPriceSet}
          label='Cu / fara pret setat'
          name='isPriceSet'
          value={filter.isPriceSet}
          onChange={handleFilterChange()}
        >
          <MenuItem value='all'>Oricare</MenuItem>
          <MenuItem value={true}>Doar cu pret</MenuItem>
          <MenuItem value={false}>Doar fara pret</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center', gap: 1}}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />

        <Tooltip title='Reseteaza filtrele la valoarile initiale'>
          <div>
            <IconButton color='error'
              disabled={JSON.stringify(filter) === JSON.stringify(getDefaultFilter())}
              onClick={handleFilterReset()}
            >
              <FilterAltOffIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='md' fixed>
      <Navigation paths={[
        {text: 'Acasa'           , path: '/'                 , icon: 'Home'        },
        {text: 'Administrare'    , path: '/Admin'            , icon: 'Settings'    },
        {text: 'Specialitati'    , path: '/Admin/Specialties', icon: 'MonitorHeart'},
        {text: 'Preturi servicii', path: null                , icon: 'Money'       },
      ]} />

      <Typography variant='h6' textAlign='center'>Preturi servicii pentru specialitatea selectata</Typography>

      {renderFilter()}

      <Entities maxWidth='md' virtualized defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['service']}
        groupBy={filter.idBranch === 'all' ? 'service' : null}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.service}>{entity.service[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={7}>
                <Box>
                  <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {filter.idBranch === 'all' ? entity.branch : entity.service}
                  </Highlighted>
                  {entity.isMyCompany && entity.idBeneficiaryContract === '' && entity.idDoctorContract === '' &&
                    <Highlighted highlight={filter.text} variant='caption' color='secondary' sx={{flexGrow: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      Pret de lista
                    </Highlighted>
                  }
                  {/* <Typography variant='body2' color='primary'>Pret de lista: xxx RON</Typography> */}
                  {/* <Typography variant='caption' color='error'>{!entity.isPurchasable ? 'Acest serviciu nu se poate vinde' : ''}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Beneficiar: {entity.beneficiary}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Punct de lucru: {entity.branch}</Typography> */}
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Contract beneficiar: {entity.contract}</Typography> */}
                  {entity.idDoctorContract &&
                    <Typography variant='caption' sx={{display: 'block'}}>{entity.doctor} ({entity.doctorContract})</Typography>
                  }
                  {/* <Typography variant='caption' sx={{display: 'block'}}>Specialitate: {entity.specialty}</Typography> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {entity.isSchedulable &&
                  <TextField variant='outlined' select
                    disabled={ac.isLoading}
                    error={!Boolean(entity.duration) || entity.duration !== entity.defaultDuration}
                    label='Durata (min)'
                    placeholder='Durata (min)'
                    name='duration'
                    value={entity.duration}
                    onFocus={handleEntityFieldFocus(entity)}
                    onClick={handleEntityFieldFocus(entity)}
                    onChange={handleDataChange(entity.id)}
                  >
                    {['0', '5', '10', '15', '20', '25', '30', '40', '50', '60'].map(duration => (
                      <MenuItem key={duration} value={duration}>{duration} min</MenuItem>
                    ))}
                  </TextField>
                }
              </Grid>
              <Grid item xs={12} sm={1}>
                {entity.isAutoPrice &&
                  <Tooltip title='Acest serviciu are selectata optiunea de calculare automata a pretului ca suma a preturilor serviciilor sale componente. Apasati aici pentru a calcula acest pret'>
                    <IconButton color='primary'
                      disabled={ac.isLoading}
                      onClick={handleServicePriceAutoCalculate(entity.id)}
                    >
                      <AutoFixHighIcon />
                    </IconButton>
                  </Tooltip>
                }
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField variant='outlined' /*required*/
                  inputProps={{
                    style: {textAlign: 'right'},
                  }}
                  InputProps={{
                    startAdornment: entity.price !== entity.defaultPrice
                      ? <InputAdornment position='start'>
                          <Tooltip title='Anuleaza modificarile facute asupra acestui pret'>
                            <IconButton size='small' color='error'
                              disabled={ac.isLoading}
                              onClick={() => handleDataChange(entity.id)({target: {type: 'priceReset', name: 'price', value: entity.defaultPrice}})}
                            >
                              <ClearIcon fontSize='inherit' />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      : null,
                    // endAdornment: <InputAdornment position='end'>RON</InputAdornment>,
                  }}
                  disabled={ac.isLoading || !entity.isPurchasable}
                  error={!Boolean(entity.price) || entity.price !== entity.defaultPrice}
                  // helperText={!Boolean(entity.price) ? 'Neconfigurat' : entity.price !== entity.defaultPrice ? 'Nesalvat' : ''}
                  label={entity.datasetValue || 'Pret (RON)'}
                  placeholder={entity.datasetValue || 'Pret (RON)'}
                  name='price'
                  value={entity.price}
                  onFocus={handleEntityFieldFocus(entity)}
                  onChange={handleDataChange(entity.id)}
                />
              </Grid>
            </Grid>
          ),
          textIcons: [],
        }}
        secondaryAction={entity => (entity.duration !== entity.defaultDuration || entity.price !== entity.defaultPrice) && entity.price !== '0'
          ? <Tooltip title='Salveaza modificarile facute asupra acestui pret'>
              <IconButton color='primary'
                disabled={ac.isLoading}
                onClick={handleEntitySave(entity)}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          : parseInt(entity.id) > 0 && entity.price === '0'
            ? <Tooltip title='Sterge pretul acestui serviciu. Un serviciu fara pret nu se poate vinde'>
                <IconButton color='error'
                  disabled={ac.isLoading}
                  onClick={handleEntitySave(entity)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            : null
        }
        onChangeSelection={setSelection}
      />
    </Container>
  );
};

export default ServicePricesForSpecialty;