import React, { useState, useContext, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { firstBy } from 'thenby';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

/**
 * Static core components
 */
import Entities from '../Views/Entities';
import Register from './Register';
import DisableRegister from './DisableRegister';
import EnableRegister from './EnableRegister';

const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const RegisterType = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getRegisterType', {
      idRegisterType: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.registerType.id)) {
            setDefaultData(response.result.data.registerType);
          } else {
            setDefaultData(response.result.defaultValues.registerType);
          }

          setData(response.result.data.registerType);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.registerType === '' ? 'Nu ati completat numele tipului de registru' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  /**
   * Getters
   */
  const getSpecialties = useCallback(() => {
    return deps.specialties.sort(firstBy('specialty'));
  }, [deps]);

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      case 'datePicker':
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value ? evt.target.value.$d.format('YYYY') : '',
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };

  const handleReload = (response, newSelection = null) => {
    setData(prevState => ({
      ...prevState,
      registers: response.result.data.registerType.registers,
    }));
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.registerType.registers.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveRegisterType', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth='md' open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle>Tip de registru: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <TextField disabled
                    label='ID'
                    placeholder='ID'
                    name='id'
                    value={data.id}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField required autoFocus
                    disabled={ac.isLoading}
                    label='Tip de registru'
                    placeholder='Tip de registru'
                    name='registerType'
                    value={data.registerType}
                    onChange={handleDataChange()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField select
                    disabled={ac.isLoading}
                    label='Specialitate'
                    placeholder='Specialitate'
                    name='idSpecialty'
                    value={data.idSpecialty}
                    onChange={handleDataChange()}
                  >
                    <MenuItem value='0'><Typography variant='body1' fontStyle='italic' fontWeight='bold'>Fara specialitate</Typography></MenuItem>
                    {getSpecialties().map(item => (
                      <MenuItem key={item.id} value={item.id}><Typography variant='body1'>{item.specialty}</Typography></MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label='Se reseteaza la inceputul fiecarui an'
                    control={
                      <Checkbox size='small'
                        disabled={ac.isLoading}
                        name='isAnnual'
                        checked={data.isAnnual}
                        onChange={handleDataChange()}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline rows={3}
                    disabled={ac.isLoading}
                    label='Observatii'
                    placeholder='Observatii'
                    name='description'
                    value={data.description}
                    onChange={handleDataChange()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{alignItems: 'stretch'}}>
              <Grid container spacing={1}>
                <List dense sx={{flexGrow: 1}}>
                  <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant='subtitle2'>Registre</Typography>
                    <Box sx={{display: 'flex', gap: 1}}>
                      <Button variant='outlined' color='primary' startIcon={<AddCircleOutlineIcon />}
                        disabled={ac.isLoading || data.id === defs.register.id}
                        onClick={handleSelectionChange({entity: {...defs.register, idRegisterType: data.id}, op: 'editRegister'})}
                      >
                        Adauga
                      </Button>
                    </Box>
                  </ListSubheader>

                  <Entities data={data.registers} deps={deps} selection={selection}
                    sortBy={['year', 'branch']}
                    messageNoData='Nici un registru definit'
                    renderEntity={{
                      // icon: entity => <SwapVertIcon className='drag-handle' />,
                      textPrimary: entity => (
                        <Typography variant='body2' color={entity.isEnabled ? 'textPrimary' : 'rgba(0, 0, 0, 0.26)'} sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.year || 'Toti anii'} - {entity.branch || 'Toate punctele de lucru'}</Typography>
                      ),
                      textSecondary: [
                        entity => <Typography variant='body2'>Urmatorul numar care se va aloca: {entity.serial} {entity.nextNumber}</Typography>,
                      ],
                      textIcons: [
                        entity => ({text: 'Acest registru este activ'   , icon: 'CheckCircle', color: 'primary', when: entity.isEnabled }),
                        entity => ({text: 'Acest registru nu este activ', icon: 'Cancel'     , color: 'error'  , when: !entity.isEnabled}),
                      ],
                    }}
                    contextualMenu={[
                      entity => ({text: 'Modifica'    , icon: null, action: 'editRegister'   , when: true                       }),
                      entity => ({text: 'Dezactiveaza', icon: null, action: 'disableRegister', when: selection.entity.isEnabled }),
                      entity => ({text: 'Activeaza'   , icon: null, action: 'enableRegister' , when: !selection.entity.isEnabled}),
                    ]}
                    onChangeSelection={setSelection}
                  />
                </List>
              </Grid>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>

      {/**
       * Add/edit register
       */}
      {Boolean(selection.entity) && selection.op === 'editRegister' &&
        <Register
          value={selection.entity}
          registerType={data.registerType}
          onCancel={handleSelectionChange({entity: defaultSelection.entity, op: defaultSelection.op})}
          // onConfirm={response => {
          //   setData(prevState => ({
          //     ...prevState,
          //     registers: response.result.data.registerType.registers,
          //   }));
          //   handleSelectionChange({op: defaultSelection.op})();
          // }}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set register as disabled
       */}
      {Boolean(selection.entity) && selection.op === 'disableRegister' &&
        <DisableRegister
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }

      {/**
       * Set register as enabled
       */}
      {Boolean(selection.entity) && selection.op === 'enableRegister' &&
        <EnableRegister
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Dialog>
  );
};

export default RegisterType;