import React, { useState, useContext, useEffect } from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CountryCountyCity from './CountryCountyCity';

const Wizard01MyCompany = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(null);
  const [defs, setDefs] = useState(null);
  const [notification, setNotification] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);

  /**
   * Effects
   */
  useEffect(() => {
    Boolean(props.data) && !Boolean(data) && setData(props.data);
    Boolean(props.deps) && !Boolean(deps) && setDeps(props.deps);
    Boolean(props.defs) && !Boolean(defs) && setDefs(props.defs);

    Boolean(props.data) && Boolean(props.deps) && Boolean(props.defs) && !isLoaded && setIsLoaded(true);
  }, [props, data, deps, defs, isLoaded]);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.beneficiary === '' ? 'Nu ati completat numele societatii' : message;
    message = message !== null ? message : data.idCountry === '' ? 'Nu ati selectat tara sediului social' : message;
    message = message !== null ? message : data.idCounty === '' ? 'Nu ati selectat judetul sediului social' : message;
    message = message !== null ? message : data.idCity === '' ? 'Nu ati selectat localitatea sediului social' : message;
    message = message !== null ? message : data.address === '' ? 'Nu ati completat adresa sediului social' : message;
    message = message !== null ? message : data.taxCode === '' ? 'Nu ati completat codul unic de inregistrare' : message;
    message = message !== null ? message : data.registerNumber === '' ? 'Nu ati completat numarul de ordine de la Registrul Comertului' : message;
    message = message !== null ? message : data.email === '' ? 'Nu ati completat adresa de email' : message;
    message = message !== null ? message : data.phone === '' ? 'Nu ati completat numarul de telefon' : message;

    setNotification(message);
  }, [isLoaded, data]);

  /**
   * Getters
   */

  /**
   * Handlers
   */
  const handleDataChange = () => evt => {
    let mods = {};
    switch (evt.target.name) {}

    switch (evt.target.type) {
      case 'component':
        setData(prevState => ({
          ...prevState,
          ...evt.target.data,
          ...mods,
        }));
        break;
      default:
        setData(prevState => ({
          ...prevState,
          [evt.target.name]: evt.target.value,
          ...mods,
        }));
    }
  };

  const handleConfirm = () => () => {
    ac.ajax('saveBeneficiary', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Container maxWidth='sm' fixed sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
      <Box>
        <Typography variant='subtitle2' color=''>Bine ati venit in modulul de instalare al aplicatiei!</Typography>
      </Box>

      <Typography>Pentru a putea sa folositi aplicatia, avem nevoie de cateva informatii de baza despre societatea pe care o conduceti.</Typography>
      <Typography>Primul pas este completarea formularului de mai jos cu datele societatii d-voastra, adresa sediului social si informatiile de contact.</Typography>
      <Typography>Aceste informatii sunt necesare pentru emiterea facturilor catre beneficiarii serviciilor oferite de societatea d-voastra.</Typography>
      <Typography>Din fericire, tara 'Romania' este predefinita si implicita, asadar este selectata automat de catre aplicatie.</Typography>
      <Typography>Totusi, va lasam pe d-voastra sa va definiti judetul si localitatea. Tastati numele judetului si al localitatii in campurile aferente si alegeti optiunea de adaugare.</Typography>
      <Typography>Judetul si localitatea pe care le veti selecta acum vor deveni si valorile implicite pentru toate adresele noi.</Typography>
      <Typography>Mai tarziu, din panoul de administrare al aplicatiei va veti putea seta atat judetul implicit pentru fiecare tara pe care o definiti cat si localitatea implicita pentru fiecare judet definit.</Typography>

      <Box>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <TextField disabled
              label='ID'
              placeholder='ID'
              name='id'
              value={data.id}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField required autoFocus
              disabled={ac.isLoading}
              label='Societate'
              placeholder='Societate'
              name='beneficiary'
              value={data.beneficiary}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <CountryCountyCity
              layout      ={{  country: {sx: 12, sm: 4},   county: {sx: 12, sm: 4},   city: {sx: 12, sm: 4}}}
              allowAdd    ={{  country: true           ,   county: true           ,   city: true           }}
              disabled    ={{  country: ac.isLoading   ,   county: ac.isLoading   ,   city: ac.isLoading   }}
              labels      ={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
              placeholders={{  country: 'Tara'         ,   county: 'Judet'        ,   city: 'Localitate'   }}
              names       ={{  country: 'country'      ,   county: 'county'       ,   city: 'city'         }}
              value       ={{idCountry: data.idCountry , idCounty: data.idCounty  , idCity: data.idCity    }}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField required
              disabled={ac.isLoading}
              label='Adresa'
              placeholder='Adresa (str. nr., bl., sc., et., ap.)'
              name='address'
              value={data.address}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField required
              disabled={ac.isLoading}
              label='CUI'
              placeholder='CUI'
              name='taxCode'
              value={data.taxCode}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField required
              disabled={ac.isLoading}
              label='Registrul Comertului'
              placeholder='Registrul Comertului'
              name='registerNumber'
              value={data.registerNumber}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField required
              disabled={ac.isLoading}
              label='Email'
              placeholder='Email'
              name='email'
              value={data.email}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField required
              disabled={ac.isLoading}
              label='Telefon'
              placeholder='Telefon'
              name='phone'
              value={data.phone}
              onChange={handleDataChange()}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField multiline rows={3}
              disabled={ac.isLoading}
              label='Observatii'
              placeholder='Observatii'
              name='description'
              value={data.description}
              onChange={handleDataChange()}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </Typography>
      </Box>

      <DialogActions>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
      </DialogActions>
    </Container>
  );
};

export default Wizard01MyCompany;