import React, { useEffect, useMemo, useState, useRef } from 'react';
import { firstBy } from 'thenby';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import * as Icons from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

/**
 * Static core components
 */
import Description from '../Components/Core/Description';
import Navigation from '../Components/Core/Navigation';

const Entities = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [groups, setGroups] = useState({});

  /**
   * Hooks
   */
  const theme = useTheme();
  const listRef = useRef(null);

  /**
   * Effects
   */
  useEffect(() => {
    if (props.data && props.groupBy) {
      setGroups(props.data.reduce((acc, entity) => {
        return {
          ...acc,
          [entity[props.groupBy]]: groups.hasOwnProperty(entity[props.groupBy]) ? groups[entity[props.groupBy]] : getData.length < 25,
        };
      }, {}));
    } else {
      setGroups({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.groupBy]);

  /**
   * Getters
   */
  const DynamicIcon = ({icon, ...props}) => icon === null ? null : Icons[icon] ? React.createElement(Icons[icon], props) : <HelpOutlineIcon {...props} />;

  const getData = useMemo(() => {
    if (props.data === null) return null;

    const result = props.data.filter(entity => Object.keys(props.filter).reduce((acc, filterKey) => {
      if (filterKey === 'text') {
        return acc && Object.keys(entity).filter(entityKey => entity.hasOwnProperty(entityKey) && (typeof entity[entityKey]).toLowerCase() === 'string').reduce((acc2, entityKey) => {
          return acc2 || Boolean(~entity[entityKey].toLowerCase().indexOf(props.filter.text.toLowerCase()));
        }, props.filter.text === props.defaultFilter.text);
      } else {
        return acc && (props.filter[filterKey] === props.defaultFilter[filterKey] || entity[filterKey] === props.filter[filterKey]);
      }
    }, true)).sort(Array.isArray(props.sortBy) ? props.sortBy.reduce((acc, value) => acc.thenBy(value, {ignoreCase: true, direction: 'asc'}), firstBy(props.groupBy ? props.groupBy : null)) : Object.keys(props.sortBy).reduce((acc, sortByKey) => acc.thenBy(sortByKey, {ignoreCase: true, direction: props.sortBy[sortByKey]}), firstBy(props.groupBy ? props.groupBy : null)));

    return result;
  }, [props.defaultFilter, props.filter, props.data, props.sortBy, props.groupBy]);

  /**
   * Handlers
   */
  const handleSelectionChange = (value = {}, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    let entities = props.selection.entities;
    if (props.multiselect && value.hasOwnProperty('entity') && evt.ctrlKey) {
      entities = props.selection.entities.includes(value.entity.id)
        ? props.selection.entities.filter(entity => entity !== value.entity.id)
        : props.selection.entities.concat(value.entity.id);
    } else {
      entities = value.hasOwnProperty('entity') ? [value.entity.id] : props.selection.entities;
    }

    props.onChangeSelection({
      ...props.selection,
      ...value,
      entities: entities,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : props.selection.anchorEl,
    });
  };
  const handleToggleGroupCollapse = groupName => () => {
    setGroups({
      ...groups,
      [groupName]: !groups[groupName],
    });
  };

  /**
   * Subcomponents render
   */
  const renderEntities = () => {
    if (props.data === null) {
      return (
        <Box sx={{my: 2, textAlign: 'center'}}>
          <CircularProgress color='info' />
        </Box>
      );
    } else if (getData.length === 0) {
      return (
        <Box sx={{my: 2, textAlign: 'center'}}>
          <Typography variant='h6' color='error' sx={{textAlign: 'center'}}>{props.messageNoData}</Typography>
        </Box>
      );
    } else if (getData.length === 0) {
      return (
        <Box sx={{my: 2, textAlign: 'center'}}>
          <Typography variant='h6' color='error' sx={{textAlign: 'center'}}>Filtrarea dumneavoastra nu a intors nici un rezultat</Typography>
        </Box>
      );
    } else {
      if (props.virtualized && props.groupBy === null) {
        return (
          // <FixedSizeList width='100%'
          //   height={listHeight}
          //   itemCount={getData.length}
          //   itemSize={52}
          // >
          //   {renderEntities()}
          // </FixedSizeList>
          <List dense>
            {renderEntitiesList()}
          </List>
        );
      } else {
        return (
          <List dense>
            {renderEntitiesList()}
          </List>
        );
      }
    }
  };
  const renderEntitiesList = () => (
    <DraggableContainer dragHandleSelector='.drag-handle' lockAxis='y' onDrop={props.onSort}>
      {getData.map((entity, index) => (
        <Draggable key={index}>
          {renderEntityGroupHeader(entity, index)}
          {props.groupBy && groups[entity[props.groupBy]] === false
            ? null
            : <ListItemButton disableGutters disableTouchRipple sx={{cursor: 'default'}}
                autoFocus={entity.id === props.selection.entity?.id}
                selected={props.selection.entities.includes(entity?.id)}
                // onFocus={handleSelectionChange({entity: entity})}
                onClick={handleSelectionChange({entity: entity})}
              >
                {props.multiselect && props.selection.entities.length > 1 &&
                  <Checkbox
                    checked={props.selection.entities.includes(entity.id)}
                    onChange={evt => handleSelectionChange({entity: entity})({...evt, ctrlKey: true})}
                  />
                }
                {props.renderEntity.avatar &&
                  <ListItemAvatar sx={{'.MuiAvatar-root': {fontSize: '0.8em'}}}>
                    {props.renderEntity.avatar(entity)}
                    {/* <Avatar alt={entity.service}>{entity.id}</Avatar> */}
                  </ListItemAvatar>
                }
                {props.renderEntity.icon &&
                  <ListItemIcon>
                    {props.renderEntity.icon(entity)}
                  </ListItemIcon>
                }
                <ListItemText disableTypography sx={{display: 'flex', flexDirection: 'column', my: 0, mr: 6}}
                  primary={
                    <Box>
                      <Box sx={{display: 'flex', gap: .5}}>
                        {props.renderEntity.textPrimary(entity)}
                        {renderEntityIcons(entity)}
                      </Box>
                      {renderEntitySecondaryText(entity)}
                    </Box>
                  }
                  secondary={entity.description ? <Description text={entity.description} /> : null}
                />
                {props.contextualMenu &&
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={handleSelectionChange({entity: entity}, true)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                }
                {props.secondaryAction &&
                  <ListItemSecondaryAction>
                    {props.secondaryAction(entity)}
                  </ListItemSecondaryAction>
                }
              </ListItemButton>
          }
        </Draggable>
      ))}
    </DraggableContainer>
  );
  const renderEntityGroupHeader = (entity, index) => (
    props.groupBy && (index === 0 || (entity[props.groupBy] !== getData[index - 1][props.groupBy]))
      ? <ListItemButton disableGutters disableTouchRipple sx={{display: 'block', p: 0, /*cursor: 'default'*/}}
          onClick={handleToggleGroupCollapse(entity[props.groupBy])}
        >
          <ListSubheader disableSticky sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant='subtitle2' color={groups[entity[props.groupBy]] ? 'primary' : 'error'} sx={{lineHeight: '48px'}}>
              {/* {`${entity[props.groupBy] || 'Fara categorie'} (${props.data.filter(item => item[props.groupBy] === entity[props.groupBy]).length} entitati${groups[entity[props.groupBy]] ? '' : ' ascunse'})`} */}
              {`${entity[props.groupBy] || 'Fara categorie'} (${getData.filter(item => item[props.groupBy] === entity[props.groupBy]).length} entitati${groups[entity[props.groupBy]] ? '' : ' ascunse'})`}
            </Typography>
            <IconButton size='small'
              color={groups[entity[props.groupBy]] ? 'primary' : 'error'}
              onClick={handleToggleGroupCollapse(entity[props.groupBy])}
            >
              {groups[entity[props.groupBy]] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListSubheader>
        </ListItemButton>
      : null
  );
  const renderEntityIcons = entity => (
    <Box sx={{display: 'flex', gap: .5}}>
      {props.renderEntity.textIcons.map((textIcon, index) => {
        const icon = textIcon(entity);
        if (icon.when) {
          return (
            <Tooltip key={index} title={icon.text}>
              <div style={{lineHeight: 0}}>
                <DynamicIcon icon={icon.icon} fontSize='small' color={icon.color} />
              </div>
            </Tooltip>
          );
        } else {
          return null;
        }
      })}
    </Box>
  );
  const renderEntitySecondaryText = entity => {
    return props.renderEntity.textSecondary?.map((textSecondary, index) => <Box key={index}>{textSecondary(entity)}</Box>);
  };
  const renderContextualMenu = () => {
    if (!props.contextualMenu || !props.selection.entity || !~getData.map(entity => JSON.stringify(entity)).indexOf(JSON.stringify(props.selection.entity))) {
      return null;
    } else {
      return (
        <Box>
          <Menu
            anchorEl={props.selection.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(props.selection.anchorEl)}
            onClose={handleSelectionChange({}, false)}
          >
            {props.contextualMenu.map((menuItem, index) => {
              const menu = menuItem(props.selection.entity);
              if (menu.when) {
                return menu.action[0] === '/'
                  ? <MenuItem key={index} component={Link} to={menu.action} className='link'>{menu.text}</MenuItem>
                  : <MenuItem key={index} onClick={handleSelectionChange({op: menu.action}, false)} className='link'>{menu.text}</MenuItem>;
              } else {
                return null;
              }
            })}
          </Menu>
        </Box>
      );
    }
  };
  const renderFab = () => (
    props.newEntity
      ? <Box>
          <Zoom in={true} unmountOnExit
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: theme.transitions.duration.leavingScreen,
            }}
            style={{
              transitionDelay: `${true ? theme.transitions.duration.leavingScreen : 0}ms`,
            }}
          >
            <Fab color='primary' sx={{position: 'fixed', bottom: 16, right: 16}}
              onClick={() => props.onChangeSelection({...props.selection, ...props.newEntity})}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        </Box>
      : null
  );

  /**
   * Renderer
   */
  return (
    <Container maxWidth={props.maxWidth} fixed sx={{...props.sx, px: '16px !important'}}>
      {props.navigation &&
        <Navigation paths={props.navigation} />
      }

      {renderEntities()}

      {renderContextualMenu()}
      {renderFab()}
    </Container>
  );
};

export default Entities;

Entities.propTypes = {
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  virtualized: PropTypes.bool,
  sx: PropTypes.object,
  defaultFilter: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  deps: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  selection: PropTypes.object.isRequired,
  sortBy: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  groupBy: PropTypes.string,
  messageNoData: PropTypes.string,
  renderEntity: PropTypes.shape({
    avatar: PropTypes.func,
    icon: PropTypes.func,
    textPrimary: PropTypes.func.isRequired,
    textSecondary: PropTypes.arrayOf(PropTypes.func),
    textIcons: PropTypes.arrayOf(PropTypes.func),
  }).isRequired,
  contextualMenu: PropTypes.arrayOf(PropTypes.func),
  secondaryAction: PropTypes.func,
  newEntity: PropTypes.object,
  onChangeSelection: PropTypes.func.isRequired,
  onSort: PropTypes.func,
};
Entities.defaultProps = {
  maxWidth: 'sm',
  virtualized: false,
  sx: {},
  defaultFilter: {},
  filter: {},
  data: [],
  deps: [],
  sortBy: [],
  groupBy: null,
  messageNoData: 'Nu aveti definita nici o entitate',
  renderEntity: {
    avatar: null,
    icon: null,
    textSecondary: null,
    textIcons: [],
  },
  contextualMenu: null,
  secondaryAction: null,
  newEntity: null,
  onChangeSelection: null,
  onSort: null,
};