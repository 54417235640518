import React, { useState, useContext, useEffect, useMemo } from 'react';
import { firstBy } from 'thenby';
import useUndoRedo from '../Hooks/useUndoRedo';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { Responsive, WidthProvider } from 'react-grid-layout';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EjectIcon from '@mui/icons-material/Eject';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PreviewIcon from '@mui/icons-material/Preview';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RedoIcon from '@mui/icons-material/Redo';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import NotesIcon from '@mui/icons-material/Notes';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import UndoIcon from '@mui/icons-material/Undo';

/**
 * Dynamic dialog components
 */
import FormElementText from './FormElementText';
import FormElementInputText from './FormElementInputText';
import FormElementInputMultiline from './FormElementInputMultiline';
import FormElementInputSelect from './FormElementInputSelect';

const widths = [
  // {value: 'xs', label: 'Foarte mica'},
  {value: 'sm', label: 'Mica'},
  {value: 'md', label: 'Medie'},
  {value: 'lg', label: 'Mare'},
  // {value: 'xl', label: 'Foarte mare'},
];
const formElementTypes = [
  {
    group: 'text' , label: 'Abc', icon: <FormatColorTextIcon />, width: 12,
    type: 'Text',
    value: 'Zona text',
    props: {variant: 'body2', align: 'left'},
  },
  {
    group: 'space', label: 'Spatiu', icon: <SpaceBarIcon />, width: 4,
    type: 'EmptySpace',
    value: '',
    props: {},
  },
  {
    group: 'input', label: 'Text', icon: <TextFieldsIcon />, width: 4,
    type: 'InputText',
    value: '',
    isAutocomplete: false,
    startAdornment: '',
    endAdornment: '',
    props: {variant: 'filled', size: 'small', required: false, label: 'Label', placeholder: 'Placeholder'},
  },
  {
    group: 'input', label: 'Text lung', icon: <NotesIcon />, width: 12,
    type: 'InputMultiline',
    value: '',
    props: {variant: 'filled', size: 'small', required: false, label: 'Label', placeholder: 'Placeholder' , multiline: true, rows: 3},
  },
  {
    group: 'input', label: 'Select', icon: <ArrowDropDownIcon />, width: 4,
    type: 'InputSelect',
    value: '',
    props: {select: true, variant: 'filled', size: 'small', required: false, label: 'Label'},
    options: ['Optiunea 1', 'Optiunea 2', 'Optiunea 3'],
  },
  {
    group: 'input', label: 'Bifa', icon: <CheckBoxIcon />, width: 12,
    type: 'InputCheckbox',
    value: 'Label',
    props: {size: 'small', checked: false},
  },
  // {
  //   group: 'input', label: 'Radio'  , icon: <RadioButtonCheckedIcon />, width: 12,
  //   type: 'InputRadio',
  //   value: '',
  //   props: {},
  //   options: ['Optiunea 1', 'Optiunea 2', 'Optiunea 3'],
  // },
  // {
  //   group: 'input', label: 'Fisier' , icon: <AttachFileIcon />, width: 4,
  //   type: 'InputFile',
  //   value: '',
  //   props: {},
  // },
  // {
  //   group: 'image', label: 'Imagine', icon: <InsertPhotoIcon />, width: 4,
  //   type: 'Image',
  //   value: '',
  //   props: {},
  // },
];

const defaultReactGridLayoutOptions = {
  cols: {lg: 12, md: 12, sm: 12, xs: 12, xxs: 12},
  rowHeight: 48,
  compactType: 'horizontal',
  // useCSSTransforms: true,
  // autoSize: false,
};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Form = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultData, setDefaultData] = useState(null);
  const {present: data, update: setData, reset: resetData, isUndoable: isDataUndoable, undo: undoData, isRedoable: isDataRedoable, redo: redoData} = useUndoRedo(null);
  const [notification, setNotification] = useState(null);
  const [reactGridLayoutOptions, setReactGridLayoutOptions] = useState(defaultReactGridLayoutOptions);
  const [selection, setSelection] = useState(defaultSelection);
  const [clipboard, setClipboard] = useState(null);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  /**
   * Effects
   */
  useEffect(() => {
    console.clear();
    ac.ajax('getForm', {
      idForm: props.value.id,
    })
      .then(response => {
        if (response.status.ok) {
          if (Boolean(response.result.data.form.id)) {
            setDefaultData(response.result.data.form);
            resetData(response.result.data.form);
          } else {
            setDefaultData(response.result.defaultValues.form);
            resetData({
              ...response.result.data.form,
              form: props.value.form,
            });
          }

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    let message = null;

    message = message !== null ? message : data.form === '' ? 'Nu ati completat numele formularului' : message;

    message = message !== null ? message : JSON.stringify(data) === JSON.stringify(defaultData) ? 'Nu ati efectuat nici o modificare' : null;

    setNotification(message);
  }, [isLoaded, defaultData, data]);

  useEffect(() => {
    // console.log('Trigger compactType=horizontal because this is the default value');
    if (reactGridLayoutOptions.compactType === 'vertical') {
      setReactGridLayoutOptions(prevState => ({
        ...prevState,
        compactType: 'horizontal',
      }));
    }
  }, [reactGridLayoutOptions.compactType]);

  /**
   * Getters
   */
  {/*
  const ResponsiveReactGridItem = forwardRef(({style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props}, ref) => {
    return (
      <div style={style} className={className} ref={ref} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd}>
        {children}
      </div>
    );
  });
  */}

  const getFormElements = () => data.elements.sort(firstBy('y').thenBy('x'));
  const getNextFormElementY = requiredWidth => {
    const lastElement = getFormElements().at(-1);
    if (lastElement) {
      const lastUsedRow = lastElement.y;
      const lastRowElements = getFormElements().filter(formElement => formElement.y === lastUsedRow);
      const lastRowUsedCols = lastRowElements.reduce((acc, element) => acc + element.width, 0);
      // console.log('lastUsedRow:', lastUsedRow, 'lastRowElements:', lastRowElements, 'lastRowUsedCols:', lastRowUsedCols, 'requiredWidth:', requiredWidth);
      if (lastRowUsedCols + requiredWidth > reactGridLayoutOptions.cols.xxs) {
        // console.log('Add element on row', lastUsedRow + 1);
        return lastUsedRow + 1;
      } else {
        // console.log('Add element on row', lastUsedRow);
        return lastUsedRow;
      }
    } else {
      // console.log('Add element on row', 0);
      return 0;
    }
  };
  const getNextFormElementX = requiredWidth => {
    const lastElement = getFormElements().at(-1);
    if (lastElement) {
      const lastUsedRow = lastElement.y;
      const lastRowElements = getFormElements().filter(formElement => formElement.y === lastUsedRow);
      const lastRowUsedCols = lastRowElements.reduce((acc, element) => acc + element.width, 0);
      // console.log('lastUsedRow:', lastUsedRow, 'lastRowElements:', lastRowElements, 'lastRowUsedCols:', lastRowUsedCols, 'requiredWidth:', requiredWidth);
      if (lastRowUsedCols + requiredWidth > reactGridLayoutOptions.cols.xxs) {
        // console.log('Add element on col', 0);
        return 0;
      } else {
        // console.log('Add element on col', lastElement.x + lastElement.width);
        return lastElement.x + lastElement.width;
      }
    } else {
      // console.log('Add element on col', 0);
      return 0;
    }
  };

  /**
   * Handlers
   */
  // handleDataChange()({target: {name: 'name', value: ''}})              <=> data.name = value
  // handleDataChange('prop')({target: {name: 'name', value: ''}})        <=> data.prop.name = value
  // handleDataChange('items', n)({target: {name: 'name', value: ''}})    <=> data.items[n].name = value
  // handleDataChange('items', null, 'add')({target: {value: ''}})        <=> data.items[] = value
  // handleDataChange('items', n, 'delete')()                             <=> unset(data.items[n])
  // handleDataChange('items', null, 'delete')({target: {value: 'xxx'}})  <=> unset(data.items[<item with value xxx>])
  const handleDataChange = (property = null, index = null, op = null) => (evt = {target: {}}) => {
    evt = {
      ...evt,
      target: {
        ...evt.target,
        name: evt.target.name ?? null,
        type: evt.target.type ?? 'component',
        value: evt.target.value ?? '',
      },
    };

    console.log('Data changed:', property, index, op, evt);

    let mods = {};
    switch (evt.target.name) {default: break;}

    let value = null;

    switch (evt.target.type) {
      case 'checkbox':
        value = evt.target.checked;
        break;
      default:
        value = evt.target.value;
    }

    setData(prevState => {
      if (property === null) {
        if (index === null) { // data.name = value
          return {
            ...prevState,
            [evt.target.name]: value,
            ...mods,
          };
        } else { // data.name[index] = value
          return {
            ...prevState,
            [evt.target.name]: Object.assign([], prevState[evt.target.name], {[index]: value}),
            ...mods,
          };
        }
      } else {
        if (index === null) {
          if (op === 'add') { // data.property[] = value
            return {
              ...prevState,
              [property]: prevState[property].concat(value),
            };
          } else if (op === 'delete') { // unset(data.property[value])
            return {
              ...prevState,
              [property]: prevState[property].filter(item => JSON.stringify(item) !== JSON.stringify(value)),
            };
          } else  { // data.property.name = value
            return {
              ...prevState,
              [property]: {
                ...prevState[property],
                [evt.target.name]: value,
              },
            };
          }
        } else {
          if (op === 'delete') { // unset(data.property[index])
            return {
              ...prevState,
              [property]: prevState[property].filter((item, i) => i !== index),
            };
          } else { // data.property[index].name = value
            return {
              ...prevState,
              [property]: Object.assign([], prevState[property], {[index]: {
                ...prevState[property][index],
                [evt.target.name]: value,
              }}),
            };
          }
        }
      }
    });
  };

  const handleFormElementAdd = formElementType => () => {
    const {group, label, icon, ...rest} = formElementType;
    const newElement = {
      id: data.elements.min('id') - 1,
      x: getNextFormElementX(rest.width),
      y: getNextFormElementY(rest.width),
      w: rest.width,
      h: rest.type === 'InputMultiline' ? 2 : 1,
      isVisible: true,
      ...rest,
    };

    handleDataChange('elements', null, 'add')({target: {value: newElement}});
    setSelection(prevState => ({
      ...prevState,
      entity: newElement,
    }));
  };
  const handleFormElementChange = value => {
    const index = data.elements.findIndex(item => parseInt(item.id) === value.id);
    handleDataChange(null, index)({
      target: {
        name: 'elements',
        value: value,
      },
    });
    handleSelectionChange({entity: value, op: defaultSelection.op})();
  };
  const handleFormElementCut = (element = selection.entity) => {
    console.log('Cut', element);
    handleFormElementCopy(element);
    handleDataChange('elements', null, 'delete')({target: {value: element}});
  };
  const handleFormElementCopy = (element = selection.entity) => {
    console.log('Copy', element);
    setClipboard(element);
  };
  const handleFormElementPaste = (after = selection.entity) => {
    console.log('Paste', clipboard, 'after', after);
    handleDataChange('elements', null, 'add')({target: {value: {...clipboard, id: data.elements.min('id') - 1,}}});
  };
  const handleFormElementCopyAndPaste = () => () => {
    const newElement = {
      ...selection.entity,
      id: data.elements.min('id') - 1,
      x: getNextFormElementX(selection.entity.width),
      y: getNextFormElementY(selection.entity.width),
    };

    handleDataChange('elements', null, 'add')({target: {value: newElement}});
    setSelection(prevState => ({
      ...prevState,
      entity: newElement,
    }));
  };
  const handleFormElementRemove = (element = selection.entity) => () => {
    handleDataChange('elements', null, 'delete')({target: {value: element}});
    handleSelectionChange()();
  };
  const handleFormLayoutChange = () => layout => {
    const elements = data.elements.map(element => ({
      ...element,
      width: layout.find(layoutEl => parseInt(layoutEl.i) === element.id).w,
      x: layout.find(layoutEl => parseInt(layoutEl.i) === element.id).x,
      y: layout.find(layoutEl => parseInt(layoutEl.i) === element.id).y,
      w: layout.find(layoutEl => parseInt(layoutEl.i) === element.id).w,
      h: layout.find(layoutEl => parseInt(layoutEl.i) === element.id).h,
    })).sort(firstBy('y').thenBy('x'));

    if (JSON.stringify(data.elements) !== JSON.stringify(elements)) {
      handleDataChange()({target: {name: 'elements', value: elements}});
    }
  };
  const handleFormLayoutCleanup = () => {
    setReactGridLayoutOptions(prevState => ({
      ...prevState,
      compactType: 'vertical',
    }));

    // // console.log('handleFormLayoutChange with new layout', layout);
    // const maxY = layout.max('y', -1);
    // // console.log('maxY:', maxY);
    // if (maxY !== -1) {
    //   for (let i = 0; i <= maxY; i++) {
    //     // console.log('for loop i =', i);
    //     if (layout.filter(formElement => formElement.y === i).length === 0) {
    //       console.log('Trigger compactType=vertical because there are no form element on row', i);
    //       setReactGridLayoutOptions(prevState => ({
    //         ...prevState,
    //         compactType: 'vertical',
    //       }));
    //       return;
    //     } else {
    //       // console.log('There are form elements on row', i);
    //     }
    //   }
    // }
  };
  const handleSelectionChange = (value = {entity: defaultSelection.entity, op: defaultSelection.op}, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
        break;
      case 'escapeKeyDown':
        if (JSON.stringify(data) === JSON.stringify(defaultData)) {
          handleCancel()();
        }
        break;
      default: break;
    }
  };

  const handleCancel = () => () => props.onCancel();
  const handleConfirm = () => () => {
    ac.ajax('saveForm', data)
      .then(response => {
        if (response.status.ok) {
          props.onConfirm(response);
        }
      });
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return (
      <Backdrop open sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color='inherit' />
      </Backdrop>
    );
  }

  return (
    <Dialog maxWidth={data.maxWidth || 'lg'} fullWidth open keepMounted scroll='paper'
      disableEscapeKeyDown={ac.isLoading}
      onClose={handleClose}
    >
      {/**
       * Title
      */}
      <DialogTitle sx={{display: 'flex', alignItems: 'center'}}>
        <Typography variant='h6' sx={{flexGrow: 1}}>Formular: {defaultData.id === '' ? 'Adaugare' : 'Modificare'}</Typography>
        <Tooltip title='Descriere mod de lucru'>
          <span>
            <IconButton color='primary'
              disabled={ac.isLoading}
              onClick={handleSelectionChange({op: 'showHelp'})}
            >
              <HelpOutlineIcon />
            </IconButton>
          </span>
        </Tooltip>
      </DialogTitle>

      {/**
       * Content
      */}
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <TextField disabled
                label='ID'
                placeholder='ID'
                name='id'
                value={data.id}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField required autoFocus
                disabled={ac.isLoading}
                label='Formular'
                placeholder='Formular'
                name='form'
                value={data.form}
                onChange={handleDataChange()}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField select
                disabled={ac.isLoading}
                label='Dimensiune'
                placeholder='Dimensiune'
                name='maxWidth'
                value={data.maxWidth}
                onChange={handleDataChange()}
              >
                {widths.map(width => (
                  <MenuItem key={width.value} value={width.value}>{width.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField multiline rows={3}
                disabled={ac.isLoading}
                label='Observatii'
                placeholder='Observatii'
                name='description'
                value={data.description}
                onChange={handleDataChange()}
              />
            </Grid>

            {/**
             * Form element options buttons
             */}
            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                {[...new Set(formElementTypes.map(formElementType => formElementType.group))].map(group => (
                  <ButtonGroup key={group} color='primary' sx={{mr: 1}}>
                    {formElementTypes.filter(formElementType => formElementType.group === group).map(formElementType => (
                      <Tooltip key={formElementType.type} title={`Adauga o noua componenta de tip ${formElementType.label} la sfarsitul formularului`}>
                        <Button variant='outlined' sx={{px: data.maxWidth === 'sm' ? '8px' : '12px'}}
                          disabled={ac.isLoading || selection.op === 'showPreview'}
                          startIcon={data.maxWidth === 'lg' ? formElementType.icon : null}
                          onClick={handleFormElementAdd(formElementType)}
                        >
                          {data.maxWidth === 'sm'
                            ? formElementType.icon
                            : formElementType.label
                          }
                        </Button>
                      </Tooltip>
                    ))}
                  </ButtonGroup>
                ))}
              </Box>

              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title='Undo'>
                  <span>
                    <IconButton color='primary'
                      disabled={ac.isLoading || !isDataUndoable}
                      onClick={() => undoData()}
                    >
                      <UndoIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title='Redo'>
                  <span>
                    <IconButton color='primary'
                      disabled={ac.isLoading || !isDataRedoable}
                      onClick={() => redoData()}
                    >
                      <RedoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>

              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title='Previzualizare formular'>
                  <span>
                    <ToggleButton size='small' color={'warning'} value='showPreview'
                      disabled={ac.isLoading}
                      selected={selection.op === 'showPreview'}
                      onChange={handleSelectionChange({op: selection.op === 'showPreview' ? defaultSelection.op : 'showPreview'})}
                    >
                      <PreviewIcon />
                    </ToggleButton>
                  </span>
                </Tooltip>

                <Tooltip title='Elimina randurile goale'>
                  <span>
                    <IconButton color='primary'
                      disabled={ac.isLoading}
                      onClick={handleFormLayoutCleanup}
                    >
                      <EjectIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title='Elimina componenta selectata din formular'>
                  <span>
                    <IconButton color='error'
                      disabled={ac.isLoading || selection.entity === null || selection.op === 'showPreview'}
                      onClick={handleFormElementRemove()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title='Copiaza componenta selectata la sfarsitul formularului'>
                  <span>
                    <IconButton color='primary'
                      disabled={ac.isLoading || selection.entity === null || selection.op === 'showPreview'}
                      onClick={handleFormElementCopyAndPaste()}
                    >
                      <ControlPointDuplicateIcon />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title='Proprietati componenta selectata'>
                  <span>
                    <IconButton color='primary'
                      disabled={ac.isLoading || selection.entity === null || selection.op === 'showPreview' || selection.entity.type === 'EmptySpace'}
                      onClick={handleSelectionChange({op: 'editFormElement'})}
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>

      {/**
       * WYSIWYG form designer
       */}
      <DialogContent sx={{height: '46vh', pr: '4px', pb: '1px', overflowY: 'scroll'}}>
        {/*
        <Grid container spacing={1} sx={{alignContent: 'flex-start', width: '100%', minHeight: '46vh', margin: 0, border: '1px solid black', borderRadius: '4px'}}
          onDragOver={evt => {
            evt.preventDefault();
            const currentId = selection.entity.id.toString();
            const container = evt.target.dataset.id ? evt.target : evt.target.closest('[data-id]') ? evt.target.closest('[data-id]') : {offsetLeft: null, offsetTop: null, offsetWidth: null, offsetHeight: null};
            const targetId = evt.target.dataset.id?.toString() ?? evt.target.closest('[data-id]')?.dataset.id.toString() ?? null;
            if (targetId === null)
              console.log(`'${currentId}', '${targetId}', 'onDragOver - show element as last element`, container.offsetLeft, container.offsetTop, container.offsetWidth, container.offsetHeight, evt);
            else if (currentId === targetId)
              console.log(`'${currentId}', '${targetId}', 'onDragOver - do nothing`, container.offsetLeft, container.offsetTop, container.offsetWidth, container.offsetHeight, evt);
            else
              console.log(`'${currentId}', '${targetId}', 'onDragOver - show element before id ${targetId}`, container.offsetLeft, container.offsetTop, container.offsetWidth, container.offsetHeight, evt);

            evt.dataTransfer.dropEffect = evt.ctrlKey ? 'copy' : 'move';
            evt.dataTransfer.effectAllowed = evt.ctrlKey ? 'copy' : 'move';
          }}
          onDrop={evt => {
            evt.preventDefault();
            const targetId = evt.target.dataset.id ?? evt.target.closest('[data-id]')?.dataset.id;
            console.log('onDrop - move element with id', evt.dataTransfer.getData('text/plain'), 'before id', targetId);

            const draggedElementId = evt.dataTransfer.getData('text/plain');
            console.log('Dropped element with id', draggedElementId);
            const updatedElements = [...data.elements];
            const draggedElement = updatedElements.find(el => el.id.toString() === draggedElementId);
            console.log('draggedElement', draggedElement);
            const index = updatedElements.indexOf(draggedElement);
            updatedElements.splice(index, 1);
            // setData({
            //   ...data,
            //   elements: [...updatedElements, draggedElement],
            // });
          }}
        >
          {getFormElements().filter(element => element.isVisible).map(element => (
            <Grid key={element.id} item xs={element.width} tabIndex={0} data-id={element.id} sx={{border: '1px solid red', borderRadius: '4px'}} draggable
              onFocus={handleSelectionChange({entity: element})}
              onKeyDown={evt => {
                // console.log(evt.code);
                evt.code === 'Space' && selection.entity.type !== 'EmptySpace' && handleSelectionChange({op: 'editFormElement'})();
                evt.code === 'KeyX' && evt.ctrlKey && handleFormElementCut(element);
                evt.code === 'KeyC' && evt.ctrlKey && handleFormElementCopy(element);
                evt.code === 'KeyV' && evt.ctrlKey && handleFormElementPaste(element);
                evt.code === 'Delete' && handleFormElementRemove(element)();
              }}
              onDoubleClick={element.type === 'EmptySpace' ? null : handleSelectionChange({op: 'editFormElement'})}
              onDragStart={evt => {
                console.log('onDragStart', evt);

                evt.dataTransfer.setData('text/plain', element.id);
                evt.dataTransfer.dropEffect = evt.ctrlKey ? 'copy' : 'move';
                evt.dataTransfer.effectAllowed = evt.ctrlKey ? 'copy' : 'move';
              }}
            >
              <Box sx={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', width: '100%', mx: 1}}>
                {element.type === 'EmptySpace' &&
                  <Box>{element.value}</Box>
                }
                {element.type === 'Text' &&
                  <Typography {...element.props} sx={{width: '100%'}}>{element.value}</Typography>
                }
                {element.type === 'InputText' &&
                  <TextField {...element.props}
                    inputProps={{style: {pointerEvents: selection.op === 'showPreview' ? 'all' : 'none'}}}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>{element.startAdornment}</InputAdornment>,
                      endAdornment: <InputAdornment position='end'>{element.endAdornment}</InputAdornment>,
                    }}
                    label={element.isAutocomplete ? `${element.props.label} (cu sugestii)` : element.props.label}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  />
                }
                {element.type === 'InputMultiline' &&
                  <TextField {...element.props}
                    inputProps={{style: {pointerEvents: selection.op === 'showPreview' ? 'all' : 'none'}}}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  />
                }
                {element.type === 'InputFile' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
                {element.type === 'InputCheckbox' &&
                  <FormControlLabel
                    label={element.value}
                    control={
                      <Checkbox {...element.props}
                        disabled={ac.isLoading || selection.op !== 'showPreview'}
                      />
                    }
                  />
                }
                {element.type === 'InputRadio' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
                {element.type === 'InputSelect' &&
                  <TextField {...element.props}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  >
                    {element.options.map(option => (
                      <MenuItem key={option} value={option} sx={{fontStyle: option === '' ? 'italic' : 'initial'}}>{option === '' ? 'Selectati o optiune' : option}</MenuItem>
                    ))}
                  </TextField>
                }
                {element.type === 'Image' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
              </Box>
            </Grid>
          ))}
        </Grid>
        */}

        <ResponsiveReactGridLayout {...reactGridLayoutOptions} measureBeforeMount={false} style={{minHeight: '46vh', border: '1px solid black', borderRadius: '4px'}}
          // onDragStart={(layout, oldItem) => handleSelectionChange({entity: data.elements.find(item => item.id === parseInt(oldItem.i))})()}
          // onResizeStart={(layout, oldItem) => handleSelectionChange({entity: data.elements.find(item => item.id === parseInt(oldItem.i))})()}
          onLayoutChange={handleFormLayoutChange()}
        >
          {getFormElements().map(element => (
            <Box key={element.id} tabIndex={0}
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: theme => selection.op === 'showPreview' ? 'transparent' : element.id === selection.entity?.id ? theme.palette.primary.main : 'lightgray',
                borderRadius: '4px',
                '&:hover': {
                  borderColor: theme => selection.op === 'showPreview' ? 'lightgray' : element.id === selection.entity?.id ? theme.palette.primary.main : 'lightgray',
                },
                '&:focus': {
                  outlineWidth: '1px',
                  outlineStyle: 'auto',
                  outlineColor: theme => selection.op === 'showPreview' ? 'transparent' : theme.palette.secondary.main,
                },
              }}
              data-grid={{
                x: element.x,
                y: element.y,
                w: element.width,
                h: element.h,
                minW :1, maxW :12, minH: element.h, maxH: element.h,
                resizeHandles: selection.op === 'showPreview' ? [] : ['w', 'e'],
                isBounded: true,
                isDraggable: selection.op !== 'showPreview',
                isResizable: selection.op !== 'showPreview',
                static: false,
              }}
              onFocus={handleSelectionChange({entity: element})}
              onKeyDown={evt => {
                // console.log(evt.code);
                evt.code === 'Space' && selection.entity.type !== 'EmptySpace' && handleSelectionChange({op: 'editFormElement'})();
                evt.code === 'KeyX' && evt.ctrlKey && handleFormElementCut(element);
                evt.code === 'KeyC' && evt.ctrlKey && handleFormElementCopy(element);
                evt.code === 'KeyV' && evt.ctrlKey && handleFormElementPaste(element);
                evt.code === 'Delete' && handleFormElementRemove(element)();
              }}
              onDoubleClick={element.type === 'EmptySpace' ? null : handleSelectionChange({op: 'editFormElement'})}
            >
              <Box sx={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', width: '100%', mx: 1}}>
                {element.type === 'EmptySpace' &&
                  <Box>{element.value}</Box>
                }
                {element.type === 'Text' &&
                  <Typography {...element.props} sx={{width: '100%'}}>{element.value}</Typography>
                }
                {element.type === 'InputText' &&
                  <TextField {...element.props}
                    inputProps={{style: {pointerEvents: selection.op === 'showPreview' ? 'all' : 'none'}}}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>{element.startAdornment}</InputAdornment>,
                      endAdornment: <InputAdornment position='end'>{element.endAdornment}</InputAdornment>,
                    }}
                    label={element.isAutocomplete ? `${element.props.label} (cu sugestii)` : element.props.label}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  />
                }
                {element.type === 'InputMultiline' &&
                  <TextField {...element.props}
                    inputProps={{style: {pointerEvents: selection.op === 'showPreview' ? 'all' : 'none'}}}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  />
                }
                {element.type === 'InputFile' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
                {element.type === 'InputCheckbox' &&
                  <FormControlLabel
                    label={element.value}
                    control={
                      <Checkbox {...element.props}
                        disabled={ac.isLoading || selection.op !== 'showPreview'}
                      />
                    }
                  />
                }
                {element.type === 'InputRadio' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
                {element.type === 'InputSelect' &&
                  <TextField {...element.props}
                    disabled={ac.isLoading || selection.op !== 'showPreview'}
                    value={element.value}
                  >
                    {element.options.map(option => (
                      <MenuItem key={option} value={option} sx={{fontStyle: option === '' ? 'italic' : 'initial'}}>{option === '' ? 'Selectati o optiune' : option}</MenuItem>
                    ))}
                  </TextField>
                }
                {element.type === 'Image' && // Not implemented yet!
                  <Typography variant='subtitle2' color='error'>Not implemented yet!</Typography>
                }
              </Box>
            </Box>
          ))}
        </ResponsiveReactGridLayout>
      </DialogContent>

      {/**
       * Notification message
       */}
      <DialogContent>
        <DialogContentText color='error' sx={{visibility: Boolean(notification) ? 'initial' : 'hidden'}}>
          {Boolean(notification) ? notification : 'Totul pare OK!'}
        </DialogContentText>
      </DialogContent>

      {/**
       * Actions
      */}
      <DialogActions>
        <a href='https://www.npmjs.com/package/react-grid-layout' target='_blank' rel='noreferrer'>react-grid-layout</a>
        <div style={{flexGrow: 1}}></div>
        <Button disabled={ac.isLoading || Boolean(notification)} onClick={handleConfirm()}>Salveaza</Button>
        <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleCancel()}>Renunta</Button>
      </DialogActions>

      {selection.op === 'showHelp' &&
        <Dialog maxWidth='md' open keepMounted
          // disableEscapeKeyDown={ac.isLoading}
          onClose={handleSelectionChange({op: defaultSelection.op})}
        >
          <DialogTitle>Ajutor: Formular</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Typography>Pentru a putea crea un formular acesta va trebui sa aiba urmatoarele informatii:</Typography>
              <Typography>- un nume pentru a putea fi identificat in cadrul aplicatiei</Typography>
              <Typography>- o dimensiune (latime) dintr-o lista de cinci dimensiuni standard</Typography>
              <Typography>- un layout, acesta constand din cateva componente standard pe care le regasiti in partea de sus a editorului.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Prin apasarea oricarui buton corespunzator unei componente, o componenta de tipul ales va fi adaugata la sfarsitul formularului.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Daca doriti sa schimbati ordinea componentelor in formular, apucati de componenta dorita si mutati-o in pozitia dorita.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Daca doriti sa schimbati dimensiunea (latimea) unei componente, apucati de marginea din dreapta si trageti de ea spre stanga sau dreapta pana ajunge la dimensiunea dorita.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Componenta evidentiata cu o margine de alta culoarea este componenta selectata. O componenta se selecteaza in mod automat in momentul in care este adaugata in formular, cand este mutata, redimensionata sau cand se da click pe aceasta.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Componenta activa este cea evidentiata cu o margine mai groasa. Atata timp cat aveti o componenta activa, puteti folosi tastele <abbr>Ctrl+X</abbr> (decupare), <abbr>Ctrl+C</abbr> (copiere), <abbr>Ctrl+V</abbr> (lipire) si <abbr>Delete</abbr> (stergere). Componenta activa este intotdeauna si componenta selectata.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Pentru modificarea proprietatilor componentei selectate dati dublu click pe aceasta, folositi butonul de editare din partea dreapta sus a editorului sau folositi tasta <abbr>Spatiu</abbr> daca acea componenta pe care doriti sa o modificati este cea cea activa.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              <Typography>Pentru stergerea componentei selectate, folositi butonul de stergere din partea dreapta sus a editorului.</Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button variant='text' color='inherit' disabled={ac.isLoading} onClick={handleSelectionChange({op: defaultSelection.op})}>Inchide</Button>
          </DialogActions>
        </Dialog>
      }

      {selection.op === 'editFormElement' && selection.entity.type === 'Text' &&
        <FormElementText
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleFormElementChange}
        />
      }

      {selection.op === 'editFormElement' && selection.entity.type === 'InputText' &&
        <FormElementInputText
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleFormElementChange}
        />
      }

      {selection.op === 'editFormElement' && selection.entity.type === 'InputMultiline' &&
        <FormElementInputMultiline
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleFormElementChange}
        />
      }

      {selection.op === 'editFormElement' && selection.entity.type === 'InputSelect' &&
        <FormElementInputSelect
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleFormElementChange}
        />
      }
    </Dialog>
  );
};

export default Form;