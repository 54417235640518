import React, { useState, useContext, useEffect } from 'react';
import { firstBy } from 'thenby';
import { useTheme } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppContext from '../Hooks/AppContext';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import AddIcon from '@mui/icons-material/Add';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RuleIcon from '@mui/icons-material/Rule';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import TuneIcon from '@mui/icons-material/Tune';
import WarningIcon from '@mui/icons-material/Warning';

/**
 * Static core components
 */
import Highlighted from './Core/Highlighted';
import Navigation from './Core/Navigation';

/**
 * Dynamic dialog components
 */
import Entities from '../Views/Entities';
import Dataset from './Dataset';
import Description from './Core/Description';

const defaultFilter = {
  text: '',
};
const defaultDeps = {};
const defaultSelection = {
  entity: null,
  op: null,
  entities: [],
  anchorEl: null,
};

const Datasets = props => {
  /**
   * Constants
   */

  /**
   * States
   */
  const [isLoaded, setIsLoaded] = useState(false);
  const [filter, setFilter] = useState(defaultFilter);
  const [data, setData] = useState(null);
  const [deps, setDeps] = useState(defaultDeps);
  const [defs, setDefs] = useState(null);
  const [selection, setSelection] = useState(defaultSelection);

  /**
   * Hooks
   */
  const ac = useContext(AppContext);
  const theme = useTheme();

  /**
   * Effects
   */
  useEffect(() => {
    ac.ajax('getDatasets')
      .then(response => {
        if (response.status.ok) {
          setData(response.result.data.datasets);
          setDeps(response.result.dependencies);
          setDefs(response.result.defaultValues);

          setIsLoaded(true);
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Getters
   */
  const getDatasets = (idParent = '') => data.filter(dataset => dataset.idParent === idParent).sort(firstBy('type').thenBy('dataset'));

  /**
   * Handlers
   */
  const handleFilterChange = () => evt => {
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.value,
    });
  };
  const handleFilterTextReset = () => () => {
    setFilter({
      ...filter,
      text: defaultFilter.text,
    });
  };
  const handleSelectionChange = (value = null, showMenu = null) => (evt = null) => {
    if (evt !== null && 'stopPropagation' in evt) evt.stopPropagation();

    setSelection(prevState => ({
      ...prevState,
      ...value,
      anchorEl: showMenu === true ? evt.currentTarget : showMenu === false ? null : selection.anchorEl,
    }));
  };
  const handleReload = (response, newSelection = null) => {
    setData(response.result.data.datasets);
    setDeps(response.result.dependencies);
    setDefs(response.result.defaultValues);

    handleSelectionChange(
      newSelection
        ? newSelection
        : response.result.lastId
          ? {
              ...defaultSelection,
              entity: response.result.data.datasets.find(item => item.id === response.result.lastId),
              entities: [response.result.lastId],
            }
          : selection
    )();
  };

  /**
   * Subcomponents render
   */
  const renderFilter = () => (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          InputProps={{
            endAdornment: filter.text !== defaultFilter.text
              ? <InputAdornment position='end'>
                  <IconButton edge='end' size='small' color='error'
                    onClick={handleFilterTextReset()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              : null,
          }}
          disabled={ac.isLoading || data?.length === 0}
          label='Filtrare rapida'
          placeholder='Filtrare rapida'
          name='text'
          value={filter.text}
          onChange={handleFilterChange()}
        />
      </Grid>
    </Grid>
  );
  const renderTreeView = (idParent = '') => {
    return getDatasets(idParent).map(entity => (
      <TreeItem key={entity.id} itemId={entity.id}
        label={
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {entity.type === 'Dataset'
                ? <RuleFolderIcon fontSize='small' color='action' />
                : <RuleIcon fontSize='small' color='action' />
              }
            </Box>
            <Box sx={{flexGrow: 1}}>
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1}}>
                <Typography
                  variant={entity.type === 'Dataset' ? 'subtitle2' : 'body2'}
                  sx={{
                    color: theme => entity.type === 'Dataset' && getDatasets(entity.id).length === 0 ? theme.palette.warning.main : 'inherit',
                  }}
                >{entity.dataset}</Typography>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                  {entity.isEnabled
                    ? null
                      // <Tooltip title='Acest element este activ'>
                      //   <CheckCircleIcon fontSize='small' color='primary' />
                      // </Tooltip>
                    : <Tooltip title='Acest element nu este activ'>
                        <CancelIcon fontSize='small' color='error' />
                      </Tooltip>
                  }
                  {entity.isDetail &&
                    <Tooltip title={`Acest element reprezinta ${entity.type === 'Dataset' ? 'o colectie cu detaliile' : 'detaliul'} unui serviciu`}>
                      <AltRouteIcon fontSize='small' />
                    </Tooltip>
                  }
                  {entity.isResult &&
                    <Tooltip title={`Acest element reprezinta ${entity.type === 'Dataset' ? 'o colectie cu rezultatele' : 'rezultatul'} unui serviciu`}>
                      <TuneIcon fontSize='small' />
                    </Tooltip>
                  }
                  {entity.isResult && entity.type === 'Value' &&
                    <Tooltip title={`Acest rezultat este ${entity.isGood ? 'bun' : entity.isNormal ? 'normal' : 'rau'}`}>
                      <DoneAllIcon fontSize='small' color={entity.isGood ? 'success' : entity.isNormal ? 'info' : 'warning'} />
                    </Tooltip>
                  }
                  {entity.type === 'Dataset' && getDatasets(entity.id).length === 0 &&
                    <Tooltip title={`Acest set de date nu contine nici o valoare`}>
                      <WarningIcon fontSize='small' color='warning' />
                    </Tooltip>
                  }
                </Box>
              </Box>
              <Description text={entity.description} />
            </Box>
            <IconButton onClick={handleSelectionChange({entity: entity}, true)}>
              <MoreVertIcon fontSize='small' />
            </IconButton>
          </Box>
        }
      >
        {getDatasets(entity.id).length > 0 ? renderTreeView(entity.id) : null}
      </TreeItem>
    ));
  };

  /**
   * Renderer
   */
  if (!isLoaded) {
    return null;
  }

  return (
    <Container maxWidth='sm' fixed>
      <Navigation
        paths={[
          {text: 'Acasa'         , path: '/'           , icon: 'Home'    },
          {text: 'Administrare'  , path: '/Admin'      , icon: 'Settings'},
          {text: 'Nomenclatoare' , path: '/Admin/Lists', icon: 'Toc'     },
          {text: 'Seturi de date', path: null          , icon: 'Dataset' },
        ]}
      />

      {renderFilter()}

      {/* <Entities defaultFilter={defaultFilter} filter={filter} data={data} deps={deps} selection={selection}
        sortBy={['dataset']}
        renderEntity={{
          avatar: entity => <Avatar alt={entity.dataset}>{entity.dataset[0]}</Avatar>,
          icon: null,
          textPrimary: entity => (
            <Highlighted highlight={filter.text} variant='body2' sx={{flexGrow: 1, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{entity.dataset}</Highlighted>
          ),
          textSecondary: null,
          textIcons: [
            // entity => ({text: 'Aceasta tara este definita ca implicita'                , icon: 'Home'    , color: 'primary', when: entity.isDefault           }),
            // entity => ({text: 'Codul de 2 caractere conform ISO 3166-1 este definit'   , icon: 'LooksTwo', color: 'success', when: Boolean(entity.alphaCode2) }),
            // entity => ({text: 'Codul de 2 caractere conform ISO 3166-1 nu este definit', icon: 'LooksTwo', color: 'error'  , when: !Boolean(entity.alphaCode2)}),
            // entity => ({text: 'Codul de 3 caractere conform ISO 3166-1 este definit'   , icon: 'Looks3'  , color: 'success', when: Boolean(entity.alphaCode3) }),
            // entity => ({text: 'Codul de 3 caractere conform ISO 3166-1 nu este definit', icon: 'Looks3'  , color: 'error'  , when: !Boolean(entity.alphaCode3)}),
          ],
        }}
        contextualMenu={[
          entity => ({text: 'Modifica'    , icon: null, action: 'editEntity'                            , when: true              }),
          entity => ({text: 'Valori'      , icon: null, action: `/Admin/Datasets/${selection.entity.id}`, when: true              }),
          entity => ({text: 'Activeaza'   , icon: null, action: 'disableDataset'                        , when: !entity.isDisabled}),
          entity => ({text: 'Dezactiveaza', icon: null, action: 'enableDataset'                         , when: entity.isDisabled }),
        ]}
        newEntity={{
          op: 'editEntity',
          entity: defs.dataset,
        }}
        onChangeSelection={setSelection}
      /> */}

      <SimpleTreeView sx={{mt: 1}}
        slots={{
          expandIcon: ChevronRightIcon,
          collapseIcon: ExpandMoreIcon,
          // endIcon: WarningIcon,
        }}
        // slotProps={{
        //   endIcon: {
        //     color: 'warning',
        //   },
        // }}
      >
        {renderTreeView()}
      </SimpleTreeView>

      <Menu
        anchorEl={selection.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(selection.anchorEl)}
        onClose={handleSelectionChange({}, false)}
      >
        <MenuItem onClick={handleSelectionChange({op: 'editEntity'}, false)} className='link'>Modifica</MenuItem>
        {/* {selection.entity?.type === 'Dataset' && */}
          <MenuItem className='link'
            onClick={handleSelectionChange({
              op: 'editEntity',
              entity: {
                ...defs.dataset,
                idParent: selection.entity?.id,
                type: 'Value',
                isDetail: selection.entity?.isDetail,
                isResult: selection.entity?.isResult,
                isNormal: selection.entity?.isResult,
                parentDataset: selection.entity?.dataset,
              }
            }, false)}
          >Adauga valoare</MenuItem>
        {/* } */}
        {/* {selection.entity?.type === 'Dataset' && */}
          <MenuItem className='link'
            onClick={handleSelectionChange({
              op: 'editEntity',
              entity: {
                ...defs.dataset,
                idParent: selection.entity?.id,
                type: 'Dataset',
                isDetail: selection.entity?.isDetail,
                isResult: selection.entity?.isResult,
                parentDataset: selection.entity?.dataset,
              }
            }, false)}
          >Adauga set de date</MenuItem>
        {/* } */}
        {!selection.entity?.isDisabled &&
          <MenuItem onClick={handleSelectionChange({op: 'disableEntity'}, false)} className='link'>Dezactiveaza</MenuItem>
        }
        {selection.entity?.isDisabled &&
          <MenuItem onClick={handleSelectionChange({op: 'enableEntity'}, false)} className='link'>Activeaza</MenuItem>
        }
      </Menu>

      <Zoom in={true} unmountOnExit
        timeout={{
          enter: theme.transitions.duration.enteringScreen,
          exit: theme.transitions.duration.leavingScreen,
        }}
        style={{
          transitionDelay: `${true ? theme.transitions.duration.leavingScreen : 0}ms`,
        }}
      >
        <Fab color='primary' sx={{position: 'fixed', bottom: 16, right: 16}}
          onClick={handleSelectionChange({
            op: 'editEntity',
            entity: {
              ...defs.dataset,
              type: 'Dataset',
            }
          }, false)}
        >
          <AddIcon />
        </Fab>
      </Zoom>

      {/**
       * Add/edit entity
       */}
      {Boolean(selection.entity) && selection.op === 'editEntity' &&
        <Dataset
          value={selection.entity}
          onCancel={handleSelectionChange({op: defaultSelection.op})}
          onConfirm={handleReload}
        />
      }
    </Container>
  );
};

export default Datasets;